/**
 *  @ngdoc module
 *  @name Calculation Service
 *  @description Application service for Calculation

 */
var app = angular.module('myApp');
app.service('CalculationService', function () {
    //this.RkonDB = [
    //  {
    //      "ID": "1",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "44",
    //      "KAVMAX": "45",
    //      "AVGK": "44.5",
    //      "CENT": "7.6",
    //      "DIAM": "8.4",
    //      "GPOZ": "7.2",
    //      "GBCE": "-0.5",
    //      "GPC1": "7.8",
    //      "GW1": "0.2",
    //      "GCE1": "-0.5",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "2",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "45",
    //      "KAVMAX": "46.25",
    //      "AVGK": "45.62",
    //      "CENT": "7.4",
    //      "DIAM": "8.4",
    //      "GPOZ": "7.2",
    //      "GBCE": "-0.55",
    //      "GPC1": "7.7",
    //      "GW1": "0.2",
    //      "GCE1": "-0.55",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "3",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "46.25",
    //      "KAVMAX": "47.5",
    //      "AVGK": "46.87",
    //      "CENT": "7.2",
    //      "DIAM": "8.4",
    //      "GPOZ": "7.2",
    //      "GBCE": "-0.6",
    //      "GPC1": "7.6",
    //      "GW1": "0.2",
    //      "GCE1": "-0.6",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "4",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "47.5",
    //      "KAVMAX": "49",
    //      "AVGK": "48.25",
    //      "CENT": "7",
    //      "DIAM": "8.4",
    //      "GPOZ": "7",
    //      "GBCE": "-0.65",
    //      "GPC1": "7.5",
    //      "GW1": "0.3",
    //      "GCE1": "-0.65",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "5",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "49",
    //      "KAVMAX": "50.37",
    //      "AVGK": "49.62",
    //      "CENT": "6.8",
    //      "DIAM": "8.4",
    //      "GPOZ": "6.8",
    //      "GBCE": "-0.7",
    //      "GPC1": "7.4",
    //      "GW1": "0.4",
    //      "GCE1": "-0.7",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "6",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "50.37",
    //      "KAVMAX": "51.87",
    //      "AVGK": "51.12",
    //      "CENT": "6.6",
    //      "DIAM": "8.4",
    //      "GPOZ": "6.6",
    //      "GBCE": "-0.75",
    //      "GPC1": "7.3",
    //      "GW1": "0.5",
    //      "GCE1": "-0.75",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "7",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "51.87",
    //      "KAVMAX": "53.62",
    //      "AVGK": "52.75",
    //      "CENT": "6.4",
    //      "DIAM": "8.4",
    //      "GPOZ": "6.4",
    //      "GBCE": "-0.8",
    //      "GPC1": "7.2",
    //      "GW1": "0.6",
    //      "GCE1": "-0.8",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "8",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "53.62",
    //      "KAVMAX": "55.37",
    //      "AVGK": "54.5",
    //      "CENT": "6.2",
    //      "DIAM": "8.4",
    //      "GPOZ": "6.2",
    //      "GBCE": "-0.85",
    //      "GPC1": "7.1",
    //      "GW1": "0.7",
    //      "GCE1": "-0.85",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "9",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "55.37",
    //      "KAVMAX": "57.12",
    //      "AVGK": "56.25",
    //      "CENT": "6",
    //      "DIAM": "8.4",
    //      "GPOZ": "6",
    //      "GBCE": "-0.9",
    //      "GPC1": "7",
    //      "GW1": "0.8",
    //      "GCE1": "-0.9",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "10",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "57.12",
    //      "KAVMAX": "59.37",
    //      "AVGK": "58.25",
    //      "CENT": "5.8",
    //      "DIAM": "8.4",
    //      "GPOZ": "5.8",
    //      "GBCE": "-0.95",
    //      "GPC1": "6.9",
    //      "GW1": "0.9",
    //      "GCE1": "-0.95",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "11",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "59.37",
    //      "KAVMAX": "61.12",
    //      "AVGK": "60.25",
    //      "CENT": "5.6",
    //      "DIAM": "8.4",
    //      "GPOZ": "5.6",
    //      "GBCE": "-1",
    //      "GPC1": "6.8",
    //      "GW1": "1",
    //      "GCE1": "-1",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "12",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "61.12",
    //      "KAVMAX": "63.87",
    //      "AVGK": "62.5",
    //      "CENT": "5.4",
    //      "DIAM": "8.4",
    //      "GPOZ": "5.4",
    //      "GBCE": "-1",
    //      "GPC1": "6.7",
    //      "GW1": "1.1",
    //      "GCE1": "-1",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "13",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "63.87",
    //      "KAVMAX": "65.87",
    //      "AVGK": "64.87",
    //      "CENT": "5.2",
    //      "DIAM": "8.4",
    //      "GPOZ": "5.2",
    //      "GBCE": "-1.1",
    //      "GPC1": "6.6",
    //      "GW1": "1.2",
    //      "GCE1": "-1.1",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "14",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK84",
    //      "KAVMIN": "65.87",
    //      "KAVMAX": "69.12",
    //      "AVGK": "67.5",
    //      "CENT": "5",
    //      "DIAM": "8.4",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.5",
    //      "GW1": "1.3",
    //      "GCE1": "-1.2",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "15",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "39",
    //      "KAVMAX": "41",
    //      "AVGK": "40",
    //      "CENT": "8.4",
    //      "DIAM": "9.2",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "8.6",
    //      "GW1": "0.2",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.9",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "16",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "40",
    //      "KAVMAX": "42",
    //      "AVGK": "41",
    //      "CENT": "8.2",
    //      "DIAM": "9.2",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "8.4",
    //      "GW1": "0.2",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.7",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "17",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "41",
    //      "KAVMAX": "43",
    //      "AVGK": "42",
    //      "CENT": "8",
    //      "DIAM": "9.2",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "8.2",
    //      "GW1": "0.2",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.5",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "18",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "42",
    //      "KAVMAX": "44",
    //      "AVGK": "43",
    //      "CENT": "7.8",
    //      "DIAM": "9.2",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "8",
    //      "GW1": "0.2",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.3",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "19",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "44",
    //      "KAVMAX": "45",
    //      "AVGK": "44.5",
    //      "CENT": "7.6",
    //      "DIAM": "9.2",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "7.8",
    //      "GW1": "0.2",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "20",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "45",
    //      "KAVMAX": "46.25",
    //      "AVGK": "45.62",
    //      "CENT": "7.4",
    //      "DIAM": "9.2",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.55",
    //      "GPC1": "7.7",
    //      "GW1": "0.2",
    //      "GCE1": "-0.55",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "21",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "46.25",
    //      "KAVMAX": "47.5",
    //      "AVGK": "46.87",
    //      "CENT": "7.2",
    //      "DIAM": "9.2",
    //      "GPOZ": "7.2",
    //      "GBCE": "-0.6",
    //      "GPC1": "7.6",
    //      "GW1": "0.3",
    //      "GCE1": "-0.6",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "22",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "47.5",
    //      "KAVMAX": "49",
    //      "AVGK": "48.25",
    //      "CENT": "7",
    //      "DIAM": "9.2",
    //      "GPOZ": "7",
    //      "GBCE": "-0.65",
    //      "GPC1": "7.5",
    //      "GW1": "0.4",
    //      "GCE1": "-0.65",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "23",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "49",
    //      "KAVMAX": "50.37",
    //      "AVGK": "49.62",
    //      "CENT": "6.8",
    //      "DIAM": "9.2",
    //      "GPOZ": "6.8",
    //      "GBCE": "-0.7",
    //      "GPC1": "7.4",
    //      "GW1": "0.5",
    //      "GCE1": "-0.7",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "24",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "50.37",
    //      "KAVMAX": "51.87",
    //      "AVGK": "51.12",
    //      "CENT": "6.6",
    //      "DIAM": "9.2",
    //      "GPOZ": "6.6",
    //      "GBCE": "-0.75",
    //      "GPC1": "7.3",
    //      "GW1": "0.6",
    //      "GCE1": "-0.75",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "25",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "51.87",
    //      "KAVMAX": "53.62",
    //      "AVGK": "52.75",
    //      "CENT": "6.4",
    //      "DIAM": "9.2",
    //      "GPOZ": "6.4",
    //      "GBCE": "-0.8",
    //      "GPC1": "7.2",
    //      "GW1": "0.7",
    //      "GCE1": "-0.8",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "26",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "53.62",
    //      "KAVMAX": "55.37",
    //      "AVGK": "54.5",
    //      "CENT": "6.2",
    //      "DIAM": "9.2",
    //      "GPOZ": "6.2",
    //      "GBCE": "-0.85",
    //      "GPC1": "7.1",
    //      "GW1": "0.8",
    //      "GCE1": "-0.85",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "27",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "55.37",
    //      "KAVMAX": "57.12",
    //      "AVGK": "56.25",
    //      "CENT": "6",
    //      "DIAM": "9.2",
    //      "GPOZ": "6",
    //      "GBCE": "-0.9",
    //      "GPC1": "7",
    //      "GW1": "0.9",
    //      "GCE1": "-0.9",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "28",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "57.12",
    //      "KAVMAX": "59.37",
    //      "AVGK": "58.25",
    //      "CENT": "5.8",
    //      "DIAM": "9.2",
    //      "GPOZ": "5.8",
    //      "GBCE": "-0.95",
    //      "GPC1": "6.9",
    //      "GW1": "1",
    //      "GCE1": "-0.95",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "29",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "59.37",
    //      "KAVMAX": "61.12",
    //      "AVGK": "60.25",
    //      "CENT": "5.6",
    //      "DIAM": "9.2",
    //      "GPOZ": "5.6",
    //      "GBCE": "-1",
    //      "GPC1": "6.8",
    //      "GW1": "1.1",
    //      "GCE1": "-1",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "30",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "61.12",
    //      "KAVMAX": "63.87",
    //      "AVGK": "62.5",
    //      "CENT": "5.4",
    //      "DIAM": "9.2",
    //      "GPOZ": "5.4",
    //      "GBCE": "-1",
    //      "GPC1": "6.7",
    //      "GW1": "1.2",
    //      "GCE1": "-1",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "31",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "63.87",
    //      "KAVMAX": "65.87",
    //      "AVGK": "64.87",
    //      "CENT": "5.2",
    //      "DIAM": "9.2",
    //      "GPOZ": "5.2",
    //      "GBCE": "-1.1",
    //      "GPC1": "6.6",
    //      "GW1": "1.3",
    //      "GCE1": "-1.1",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "32",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "65.87",
    //      "KAVMAX": "69.12",
    //      "AVGK": "67.5",
    //      "CENT": "5",
    //      "DIAM": "9.2",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.5",
    //      "GW1": "1.4",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "33",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "71",
    //      "KAVMAX": "73",
    //      "AVGK": "72.12",
    //      "CENT": "4.68",
    //      "DIAM": "9.2",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.5",
    //      "GW1": "1.4",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "34",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "73.01",
    //      "KAVMAX": "75.12",
    //      "AVGK": "74.18",
    //      "CENT": "4.55",
    //      "DIAM": "9.2",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.45",
    //      "GW1": "1.4",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "35",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "75.13",
    //      "KAVMAX": "77.12",
    //      "AVGK": "76.19",
    //      "CENT": "4.43",
    //      "DIAM": "9.2",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.4",
    //      "GW1": "1.4",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "36",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "77.13",
    //      "KAVMAX": "79.12",
    //      "AVGK": "78.13",
    //      "CENT": "4.32",
    //      "DIAM": "9.2",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.35",
    //      "GW1": "1.4",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "37",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "79.13",
    //      "KAVMAX": "81.12",
    //      "AVGK": "80.17",
    //      "CENT": "4.21",
    //      "DIAM": "9.2",
    //      "GPOZ": "5",
    //      "GBCE": "-1.3",
    //      "GPC1": "6.3",
    //      "GW1": "1.4",
    //      "GCE1": "-1.3",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "38",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "81.13",
    //      "KAVMAX": "83.25",
    //      "AVGK": "82.32",
    //      "CENT": "4.1",
    //      "DIAM": "9.2",
    //      "GPOZ": "5",
    //      "GBCE": "-1.3",
    //      "GPC1": "6.25",
    //      "GW1": "1.4",
    //      "GCE1": "-1.3",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "39",
    //      "DESIGN": "ABC",
    //      "STYLE": "AK92",
    //      "KAVMIN": "83.26",
    //      "KAVMAX": "87",
    //      "AVGK": "84.16",
    //      "CENT": "4.01",
    //      "DIAM": "9.2",
    //      "GPOZ": "5",
    //      "GBCE": "-1.3",
    //      "GPC1": "6.2",
    //      "GW1": "1.4",
    //      "GCE1": "-1.3",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "40",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "43.5",
    //      "KAVMAX": "44.5",
    //      "AVGK": "44",
    //      "CENT": "7.67",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "7.9",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "41",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "44.5",
    //      "KAVMAX": "45.5",
    //      "AVGK": "45",
    //      "CENT": "7.5",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "7.8",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "42",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "45.5",
    //      "KAVMAX": "46.5",
    //      "AVGK": "46",
    //      "CENT": "7.34",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "7.7",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "43",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "46.5",
    //      "KAVMAX": "47.5",
    //      "AVGK": "47",
    //      "CENT": "7.18",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.55",
    //      "GPC1": "7.6",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "44",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "47.5",
    //      "KAVMAX": "48.5",
    //      "AVGK": "48",
    //      "CENT": "7.03",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.6",
    //      "GPC1": "7.5",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "45",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "48.5",
    //      "KAVMAX": "49.5",
    //      "AVGK": "49",
    //      "CENT": "6.89",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.65",
    //      "GPC1": "7.4",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "46",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "49.5",
    //      "KAVMAX": "50.5",
    //      "AVGK": "50",
    //      "CENT": "6.75",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.7",
    //      "GPC1": "7.3",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "47",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "50.5",
    //      "KAVMAX": "51.5",
    //      "AVGK": "51",
    //      "CENT": "6.62",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.75",
    //      "GPC1": "7.2",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "48",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "51.5",
    //      "KAVMAX": "52.5",
    //      "AVGK": "52",
    //      "CENT": "6.49",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.8",
    //      "GPC1": "7.1",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "49",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "52.5",
    //      "KAVMAX": "53.5",
    //      "AVGK": "53",
    //      "CENT": "6.37",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.85",
    //      "GPC1": "7",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "50",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "53.5",
    //      "KAVMAX": "54.5",
    //      "AVGK": "54",
    //      "CENT": "6.25",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.9",
    //      "GPC1": "6.9",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4",
    //      "GPC4": "0",
    //      "GW4": "0"
    //  },
    //  {
    //      "ID": "51",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "54.5",
    //      "KAVMAX": "55.5",
    //      "AVGK": "55",
    //      "CENT": "6.14",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.9",
    //      "GPC1": "6.8",
    //      "GW1": "0",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4"
    //  },
    //  {
    //      "ID": "52",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "55.5",
    //      "KAVMAX": "56.5",
    //      "AVGK": "56",
    //      "CENT": "6.03",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.9",
    //      "GPC1": "6.7",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4"
    //  },
    //  {
    //      "ID": "53",
    //      "DESIGN": "ABC",
    //      "STYLE": "GLOB",
    //      "KAVMIN": "56.5",
    //      "KAVMAX": "57.5",
    //      "AVGK": "57",
    //      "CENT": "5.92",
    //      "DIAM": "9.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.9",
    //      "GPC1": "6.6",
    //      "GW1": "0.8",
    //      "GCE1": "-0.99",
    //      "GPC2": "10.5",
    //      "GW2": "0.4"
    //  },
    //  {
    //      "ID": "54",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "44",
    //      "KAVMAX": "45",
    //      "AVGK": "44.5",
    //      "CENT": "7.6",
    //      "DIAM": "10.4",
    //      "GPOZ": "8",
    //      "GBCE": "-0.5",
    //      "GPC1": "7.8",
    //      "GW1": "0.5",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "55",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "45",
    //      "KAVMAX": "46.25",
    //      "AVGK": "45.62",
    //      "CENT": "7.4",
    //      "DIAM": "10.4",
    //      "GPOZ": "8",
    //      "GBCE": "-0.55",
    //      "GPC1": "7.7",
    //      "GW1": "0.5",
    //      "GCE1": "-0.55",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "56",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "46.25",
    //      "KAVMAX": "47.5",
    //      "AVGK": "46.87",
    //      "CENT": "7.2",
    //      "DIAM": "10.4",
    //      "GPOZ": "7.8",
    //      "GBCE": "-0.6",
    //      "GPC1": "7.6",
    //      "GW1": "0.6",
    //      "GCE1": "-0.6",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "57",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "47.5",
    //      "KAVMAX": "49",
    //      "AVGK": "48.25",
    //      "CENT": "7",
    //      "DIAM": "10.4",
    //      "GPOZ": "7.6",
    //      "GBCE": "-0.65",
    //      "GPC1": "7.5",
    //      "GW1": "0.7",
    //      "GCE1": "-0.65",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "58",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "49",
    //      "KAVMAX": "50.37",
    //      "AVGK": "49.62",
    //      "CENT": "6.8",
    //      "DIAM": "10.4",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.7",
    //      "GPC1": "7.4",
    //      "GW1": "0.8",
    //      "GCE1": "-0.7",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "59",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "50.37",
    //      "KAVMAX": "51.87",
    //      "AVGK": "51.12",
    //      "CENT": "6.6",
    //      "DIAM": "10.4",
    //      "GPOZ": "7.2",
    //      "GBCE": "-0.75",
    //      "GPC1": "7.3",
    //      "GW1": "0.9",
    //      "GCE1": "-0.75",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "60",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "51.87",
    //      "KAVMAX": "53.62",
    //      "AVGK": "52.75",
    //      "CENT": "6.4",
    //      "DIAM": "10.4",
    //      "GPOZ": "7",
    //      "GBCE": "-0.8",
    //      "GPC1": "7.2",
    //      "GW1": "1",
    //      "GCE1": "-0.8",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "61",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "53.62",
    //      "KAVMAX": "55.37",
    //      "AVGK": "54.5",
    //      "CENT": "6.2",
    //      "DIAM": "10.4",
    //      "GPOZ": "6.8",
    //      "GBCE": "-0.85",
    //      "GPC1": "7.1",
    //      "GW1": "1.1",
    //      "GCE1": "-0.85",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "62",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "55.37",
    //      "KAVMAX": "57.12",
    //      "AVGK": "56.25",
    //      "CENT": "6",
    //      "DIAM": "10.4",
    //      "GPOZ": "6.6",
    //      "GBCE": "-0.9",
    //      "GPC1": "7",
    //      "GW1": "1.2",
    //      "GCE1": "-0.9",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "63",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "57.12",
    //      "KAVMAX": "59.37",
    //      "AVGK": "58.25",
    //      "CENT": "5.8",
    //      "DIAM": "10.4",
    //      "GPOZ": "6.4",
    //      "GBCE": "-0.95",
    //      "GPC1": "6.9",
    //      "GW1": "1.3",
    //      "GCE1": "-0.95",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "64",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "59.37",
    //      "KAVMAX": "61.12",
    //      "AVGK": "60.25",
    //      "CENT": "5.6",
    //      "DIAM": "10.4",
    //      "GPOZ": "6.2",
    //      "GBCE": "-1",
    //      "GPC1": "6.8",
    //      "GW1": "1.4",
    //      "GCE1": "-1",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "65",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "61.12",
    //      "KAVMAX": "63.87",
    //      "AVGK": "62.5",
    //      "CENT": "5.4",
    //      "DIAM": "10.4",
    //      "GPOZ": "6",
    //      "GBCE": "-1",
    //      "GPC1": "6.7",
    //      "GW1": "1.5",
    //      "GCE1": "-1",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "66",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "63.87",
    //      "KAVMAX": "65.87",
    //      "AVGK": "64.87",
    //      "CENT": "5.2",
    //      "DIAM": "10.4",
    //      "GPOZ": "5.8",
    //      "GBCE": "-1.1",
    //      "GPC1": "6.6",
    //      "GW1": "1.6",
    //      "GCE1": "-1.1",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "67",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "65.87",
    //      "KAVMAX": "69.12",
    //      "AVGK": "67.5",
    //      "CENT": "5",
    //      "DIAM": "10.4",
    //      "GPOZ": "5.6",
    //      "GBCE": "-1.1",
    //      "GPC1": "6.5",
    //      "GW1": "1.7",
    //      "GCE1": "-1.1",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "68",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "69.12",
    //      "KAVMAX": "70.5",
    //      "AVGK": "69.75",
    //      "CENT": "4.8",
    //      "DIAM": "10.4",
    //      "GPOZ": "5.6",
    //      "GBCE": "-1.1",
    //      "GPC1": "6.4",
    //      "GW1": "1.7",
    //      "GCE1": "-1.1",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "69",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "70.5",
    //      "KAVMAX": "72",
    //      "AVGK": "71.25",
    //      "CENT": "4.7",
    //      "DIAM": "10.4",
    //      "GPOZ": "5.4",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.2",
    //      "GW1": "1.8",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "70",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "72",
    //      "KAVMAX": "73.5",
    //      "AVGK": "72.75",
    //      "CENT": "4.6",
    //      "DIAM": "10.4",
    //      "GPOZ": "5.4",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.2",
    //      "GW1": "1.8",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "71",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "73.5",
    //      "KAVMAX": "75",
    //      "AVGK": "74.25",
    //      "CENT": "4.5",
    //      "DIAM": "10.4",
    //      "GPOZ": "5.2",
    //      "GBCE": "-1.2",
    //      "GPC1": "6",
    //      "GW1": "1.9",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "72",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "75",
    //      "KAVMAX": "79",
    //      "AVGK": "77",
    //      "CENT": "4.4",
    //      "DIAM": "10.4",
    //      "GPOZ": "5.2",
    //      "GBCE": "-1.2",
    //      "GPC1": "6",
    //      "GW1": "1.9",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "73",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK104",
    //      "KAVMIN": "79",
    //      "KAVMAX": "85",
    //      "AVGK": "82",
    //      "CENT": "4.1",
    //      "DIAM": "10.4",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6",
    //      "GW1": "2",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.6",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "74",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "44",
    //      "KAVMAX": "45",
    //      "AVGK": "44.5",
    //      "CENT": "7.6",
    //      "DIAM": "8.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "7.8",
    //      "GW1": "0.3",
    //      "GCE1": "-0.5",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "75",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "45",
    //      "KAVMAX": "46.25",
    //      "AVGK": "45.62",
    //      "CENT": "7.4",
    //      "DIAM": "8.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.55",
    //      "GPC1": "7.7",
    //      "GW1": "0.3",
    //      "GCE1": "-0.55",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "76",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "46.25",
    //      "KAVMAX": "47.5",
    //      "AVGK": "46.87",
    //      "CENT": "7.2",
    //      "DIAM": "8.8",
    //      "GPOZ": "7.2",
    //      "GBCE": "-0.6",
    //      "GPC1": "7.6",
    //      "GW1": "0.4",
    //      "GCE1": "-0.6",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "77",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "47.5",
    //      "KAVMAX": "49",
    //      "AVGK": "48.25",
    //      "CENT": "7",
    //      "DIAM": "8.8",
    //      "GPOZ": "7",
    //      "GBCE": "-0.65",
    //      "GPC1": "7.5",
    //      "GW1": "0.5",
    //      "GCE1": "-0.65",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "78",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "49",
    //      "KAVMAX": "50.37",
    //      "AVGK": "49.62",
    //      "CENT": "6.8",
    //      "DIAM": "8.8",
    //      "GPOZ": "6.8",
    //      "GBCE": "-0.7",
    //      "GPC1": "7.4",
    //      "GW1": "0.6",
    //      "GCE1": "-0.7",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "79",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "50.37",
    //      "KAVMAX": "51.87",
    //      "AVGK": "51.12",
    //      "CENT": "6.6",
    //      "DIAM": "8.8",
    //      "GPOZ": "6.6",
    //      "GBCE": "-0.75",
    //      "GPC1": "7.3",
    //      "GW1": "0.7",
    //      "GCE1": "-0.75",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "80",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "51.87",
    //      "KAVMAX": "53.62",
    //      "AVGK": "52.75",
    //      "CENT": "6.4",
    //      "DIAM": "8.8",
    //      "GPOZ": "6.4",
    //      "GBCE": "-0.8",
    //      "GPC1": "7.2",
    //      "GW1": "0.8",
    //      "GCE1": "-0.8",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "81",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "53.62",
    //      "KAVMAX": "55.37",
    //      "AVGK": "54.5",
    //      "CENT": "6.2",
    //      "DIAM": "8.8",
    //      "GPOZ": "6.2",
    //      "GBCE": "-0.85",
    //      "GPC1": "7.1",
    //      "GW1": "0.9",
    //      "GCE1": "-0.85",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "82",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "55.37",
    //      "KAVMAX": "57.12",
    //      "AVGK": "56.25",
    //      "CENT": "6",
    //      "DIAM": "8.8",
    //      "GPOZ": "6",
    //      "GBCE": "-0.9",
    //      "GPC1": "7",
    //      "GW1": "1",
    //      "GCE1": "-0.9",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "83",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "57.12",
    //      "KAVMAX": "59.37",
    //      "AVGK": "58.25",
    //      "CENT": "5.8",
    //      "DIAM": "8.8",
    //      "GPOZ": "5.8",
    //      "GBCE": "-0.95",
    //      "GPC1": "6.9",
    //      "GW1": "1.1",
    //      "GCE1": "-0.95",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "84",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "59.37",
    //      "KAVMAX": "61.12",
    //      "AVGK": "60.25",
    //      "CENT": "5.6",
    //      "DIAM": "8.8",
    //      "GPOZ": "5.6",
    //      "GBCE": "-1",
    //      "GPC1": "6.8",
    //      "GW1": "1.2",
    //      "GCE1": "-1",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "85",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "61.12",
    //      "KAVMAX": "63.87",
    //      "AVGK": "62.5",
    //      "CENT": "5.4",
    //      "DIAM": "8.8",
    //      "GPOZ": "5.4",
    //      "GBCE": "-1",
    //      "GPC1": "6.7",
    //      "GW1": "1.3",
    //      "GCE1": "-1",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "86",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "63.87",
    //      "KAVMAX": "65.87",
    //      "AVGK": "64.87",
    //      "CENT": "5.2",
    //      "DIAM": "8.8",
    //      "GPOZ": "5.2",
    //      "GBCE": "-1.1",
    //      "GPC1": "6.6",
    //      "GW1": "1.4",
    //      "GCE1": "-1.1",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "87",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK88",
    //      "KAVMIN": "65.87",
    //      "KAVMAX": "69.12",
    //      "AVGK": "67.5",
    //      "CENT": "5",
    //      "DIAM": "8.8",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.5",
    //      "GW1": "1.5",
    //      "GCE1": "-1.2",
    //      "GPC2": "9.4",
    //      "GW2": "0.4",
    //      "GPC3": "0",
    //      "GW3": "0"
    //  },
    //  {
    //      "ID": "88",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "39",
    //      "KAVMAX": "41",
    //      "AVGK": "40",
    //      "CENT": "8.4",
    //      "DIAM": "9.6",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "8.6",
    //      "GW1": "0.3",
    //      "GCE1": "-0.5",
    //      "GPC2": "9",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "89",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "40",
    //      "KAVMAX": "42",
    //      "AVGK": "41",
    //      "CENT": "8.2",
    //      "DIAM": "9.6",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "8.4",
    //      "GW1": "0.3",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.8",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "90",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "41",
    //      "KAVMAX": "43",
    //      "AVGK": "42",
    //      "CENT": "8",
    //      "DIAM": "9.6",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "8.2",
    //      "GW1": "0.3",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.6",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "91",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "42",
    //      "KAVMAX": "44",
    //      "AVGK": "43",
    //      "CENT": "7.8",
    //      "DIAM": "9.6",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "8",
    //      "GW1": "0.3",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.4",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "92",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "44",
    //      "KAVMAX": "45",
    //      "AVGK": "44.5",
    //      "CENT": "7.6",
    //      "DIAM": "9.6",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "7.8",
    //      "GW1": "0.3",
    //      "GCE1": "-0.5",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "93",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "45",
    //      "KAVMAX": "46.25",
    //      "AVGK": "45.62",
    //      "CENT": "7.4",
    //      "DIAM": "9.6",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.55",
    //      "GPC1": "7.7",
    //      "GW1": "0.3",
    //      "GCE1": "-0.55",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "94",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "46.25",
    //      "KAVMAX": "47.5",
    //      "AVGK": "46.87",
    //      "CENT": "7.2",
    //      "DIAM": "9.6",
    //      "GPOZ": "7.2",
    //      "GBCE": "-0.6",
    //      "GPC1": "7.6",
    //      "GW1": "0.4",
    //      "GCE1": "-0.6",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "95",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "47.5",
    //      "KAVMAX": "49",
    //      "AVGK": "48.25",
    //      "CENT": "7",
    //      "DIAM": "9.6",
    //      "GPOZ": "7",
    //      "GBCE": "-0.65",
    //      "GPC1": "7.5",
    //      "GW1": "0.5",
    //      "GCE1": "-0.65",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "96",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "49",
    //      "KAVMAX": "50.37",
    //      "AVGK": "49.62",
    //      "CENT": "6.8",
    //      "DIAM": "9.6",
    //      "GPOZ": "6.8",
    //      "GBCE": "-0.7",
    //      "GPC1": "7.4",
    //      "GW1": "0.6",
    //      "GCE1": "-0.7",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "97",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "50.37",
    //      "KAVMAX": "51.87",
    //      "AVGK": "51.12",
    //      "CENT": "6.6",
    //      "DIAM": "9.6",
    //      "GPOZ": "6.6",
    //      "GBCE": "-0.75",
    //      "GPC1": "7.3",
    //      "GW1": "0.7",
    //      "GCE1": "-0.75",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "98",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "51.87",
    //      "KAVMAX": "53.62",
    //      "AVGK": "52.75",
    //      "CENT": "6.4",
    //      "DIAM": "9.6",
    //      "GPOZ": "6.4",
    //      "GBCE": "-0.8",
    //      "GPC1": "7.2",
    //      "GW1": "0.8",
    //      "GCE1": "-0.8",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "99",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "53.62",
    //      "KAVMAX": "55.37",
    //      "AVGK": "54.5",
    //      "CENT": "6.2",
    //      "DIAM": "9.6",
    //      "GPOZ": "6.2",
    //      "GBCE": "-0.85",
    //      "GPC1": "7.1",
    //      "GW1": "0.9",
    //      "GCE1": "-0.85",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "100",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "55.37",
    //      "KAVMAX": "57.12",
    //      "AVGK": "56.25",
    //      "CENT": "6",
    //      "DIAM": "9.6",
    //      "GPOZ": "6",
    //      "GBCE": "-0.9",
    //      "GPC1": "7",
    //      "GW1": "1",
    //      "GCE1": "-0.9",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "101",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "57.12",
    //      "KAVMAX": "59.37",
    //      "AVGK": "58.25",
    //      "CENT": "5.8",
    //      "DIAM": "9.6",
    //      "GPOZ": "5.8",
    //      "GBCE": "-0.95",
    //      "GPC1": "6.9",
    //      "GW1": "1.1",
    //      "GCE1": "-0.95",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "102",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "59.37",
    //      "KAVMAX": "61.12",
    //      "AVGK": "60.25",
    //      "CENT": "5.6",
    //      "DIAM": "9.6",
    //      "GPOZ": "5.6",
    //      "GBCE": "-1",
    //      "GPC1": "6.8",
    //      "GW1": "1.2",
    //      "GCE1": "-1",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "103",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "61.12",
    //      "KAVMAX": "63.87",
    //      "AVGK": "62.5",
    //      "CENT": "5.4",
    //      "DIAM": "9.6",
    //      "GPOZ": "5.4",
    //      "GBCE": "-1",
    //      "GPC1": "6.7",
    //      "GW1": "1.3",
    //      "GCE1": "-1",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "104",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "63.87",
    //      "KAVMAX": "65.87",
    //      "AVGK": "64.87",
    //      "CENT": "5.2",
    //      "DIAM": "9.6",
    //      "GPOZ": "5.2",
    //      "GBCE": "-1.1",
    //      "GPC1": "6.6",
    //      "GW1": "1.4",
    //      "GCE1": "-1.1",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "105",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "65.87",
    //      "KAVMAX": "69.12",
    //      "AVGK": "67.5",
    //      "CENT": "5",
    //      "DIAM": "9.6",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.5",
    //      "GW1": "1.5",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "106",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "71",
    //      "KAVMAX": "73",
    //      "AVGK": "72.12",
    //      "CENT": "4.68",
    //      "DIAM": "9.6",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.5",
    //      "GW1": "1.5",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "107",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "73.01",
    //      "KAVMAX": "75.12",
    //      "AVGK": "74.18",
    //      "CENT": "4.55",
    //      "DIAM": "9.6",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.45",
    //      "GW1": "1.5",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "108",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "75.13",
    //      "KAVMAX": "77.12",
    //      "AVGK": "76.19",
    //      "CENT": "4.43",
    //      "DIAM": "9.6",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.4",
    //      "GW1": "1.5",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "109",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "77.13",
    //      "KAVMAX": "79.12",
    //      "AVGK": "78.13",
    //      "CENT": "4.32",
    //      "DIAM": "9.6",
    //      "GPOZ": "5",
    //      "GBCE": "-1.2",
    //      "GPC1": "6.35",
    //      "GW1": "1.5",
    //      "GCE1": "-1.2",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "110",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "79.13",
    //      "KAVMAX": "81.12",
    //      "AVGK": "80.17",
    //      "CENT": "4.21",
    //      "DIAM": "9.6",
    //      "GPOZ": "5",
    //      "GBCE": "-1.3",
    //      "GPC1": "6.3",
    //      "GW1": "1.5",
    //      "GCE1": "-1.3",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "111",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "81.13",
    //      "KAVMAX": "83.25",
    //      "AVGK": "82.32",
    //      "CENT": "4.1",
    //      "DIAM": "9.6",
    //      "GPOZ": "5",
    //      "GBCE": "-1.3",
    //      "GPC1": "6.25",
    //      "GW1": "1.5",
    //      "GCE1": "-1.3",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "112",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IK96",
    //      "KAVMIN": "83.26",
    //      "KAVMAX": "87",
    //      "AVGK": "84.16",
    //      "CENT": "4.01",
    //      "DIAM": "9.6",
    //      "GPOZ": "5",
    //      "GBCE": "-1.3",
    //      "GPC1": "6.2",
    //      "GW1": "1.5",
    //      "GCE1": "-1.3",
    //      "GPC2": "8.2",
    //      "GW2": "0.4",
    //      "GCE2": "-0.6",
    //      "GPC3": "9.8",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "113",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "44",
    //      "KAVMAX": "45",
    //      "AVGK": "44.5",
    //      "CENT": "7.6",
    //      "DIAM": "10.8",
    //      "GPOZ": "8.4",
    //      "GBCE": "-0.25",
    //      "GPC1": "7.8",
    //      "GW1": "0.5",
    //      "GCE1": "-0.25",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "114",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "45",
    //      "KAVMAX": "46.25",
    //      "AVGK": "45.62",
    //      "CENT": "7.4",
    //      "DIAM": "10.8",
    //      "GPOZ": "8.4",
    //      "GBCE": "-0.28",
    //      "GPC1": "7.7",
    //      "GW1": "0.5",
    //      "GCE1": "-0.28",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "115",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "46.25",
    //      "KAVMAX": "47.5",
    //      "AVGK": "46.87",
    //      "CENT": "7.2",
    //      "DIAM": "10.8",
    //      "GPOZ": "8.2",
    //      "GBCE": "-0.3",
    //      "GPC1": "7.6",
    //      "GW1": "0.6",
    //      "GCE1": "-0.3",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "116",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "47.5",
    //      "KAVMAX": "49",
    //      "AVGK": "48.25",
    //      "CENT": "7",
    //      "DIAM": "10.8",
    //      "GPOZ": "8",
    //      "GBCE": "-0.33",
    //      "GPC1": "7.5",
    //      "GW1": "0.7",
    //      "GCE1": "-0.33",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "117",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "49",
    //      "KAVMAX": "50.37",
    //      "AVGK": "49.62",
    //      "CENT": "6.8",
    //      "DIAM": "10.8",
    //      "GPOZ": "7.8",
    //      "GBCE": "-0.35",
    //      "GPC1": "7.4",
    //      "GW1": "0.8",
    //      "GCE1": "-0.35",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "118",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "50.37",
    //      "KAVMAX": "51.87",
    //      "AVGK": "51.12",
    //      "CENT": "6.6",
    //      "DIAM": "10.8",
    //      "GPOZ": "7.6",
    //      "GBCE": "-0.38",
    //      "GPC1": "7.3",
    //      "GW1": "0.9",
    //      "GCE1": "-0.38",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "119",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "51.87",
    //      "KAVMAX": "53.62",
    //      "AVGK": "52.75",
    //      "CENT": "6.4",
    //      "DIAM": "10.8",
    //      "GPOZ": "7.4",
    //      "GBCE": "-0.4",
    //      "GPC1": "7.2",
    //      "GW1": "1",
    //      "GCE1": "-0.4",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "120",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "53.62",
    //      "KAVMAX": "55.37",
    //      "AVGK": "54.5",
    //      "CENT": "6.2",
    //      "DIAM": "10.8",
    //      "GPOZ": "7.2",
    //      "GBCE": "-0.43",
    //      "GPC1": "7.1",
    //      "GW1": "1.1",
    //      "GCE1": "-0.43",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "121",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "55.37",
    //      "KAVMAX": "57.12",
    //      "AVGK": "56.25",
    //      "CENT": "6",
    //      "DIAM": "10.8",
    //      "GPOZ": "7",
    //      "GBCE": "-0.45",
    //      "GPC1": "7",
    //      "GW1": "1.2",
    //      "GCE1": "-0.45",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "122",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "57.12",
    //      "KAVMAX": "59.37",
    //      "AVGK": "58.25",
    //      "CENT": "5.8",
    //      "DIAM": "10.8",
    //      "GPOZ": "6.8",
    //      "GBCE": "-0.48",
    //      "GPC1": "6.9",
    //      "GW1": "1.3",
    //      "GCE1": "-0.48",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "123",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "59.37",
    //      "KAVMAX": "61.12",
    //      "AVGK": "60.25",
    //      "CENT": "5.6",
    //      "DIAM": "10.8",
    //      "GPOZ": "6.6",
    //      "GBCE": "-0.5",
    //      "GPC1": "6.8",
    //      "GW1": "1.4",
    //      "GCE1": "-0.5",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "124",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "61.12",
    //      "KAVMAX": "63.87",
    //      "AVGK": "62.5",
    //      "CENT": "5.4",
    //      "DIAM": "10.8",
    //      "GPOZ": "6.4",
    //      "GBCE": "-0.5",
    //      "GPC1": "6.7",
    //      "GW1": "1.5",
    //      "GCE1": "-0.5",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "125",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "63.87",
    //      "KAVMAX": "65.87",
    //      "AVGK": "64.87",
    //      "CENT": "5.2",
    //      "DIAM": "10.8",
    //      "GPOZ": "6.2",
    //      "GBCE": "-0.55",
    //      "GPC1": "6.6",
    //      "GW1": "1.6",
    //      "GCE1": "-0.55",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "126",
    //      "DESIGN": "I-Kone",
    //      "STYLE": "IKGLOB",
    //      "KAVMIN": "65.87",
    //      "KAVMAX": "69.12",
    //      "AVGK": "67.5",
    //      "CENT": "5",
    //      "DIAM": "10.8",
    //      "GPOZ": "6",
    //      "GBCE": "-0.6",
    //      "GPC1": "6.5",
    //      "GW1": "1.7",
    //      "GCE1": "-0.6",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "-0.6",
    //      "GPC3": "10.6",
    //      "GW3": "0.4"
    //  },
    //  {
    //      "ID": "127",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "44",
    //      "CENT": "7.67",
    //      "DIAM": "7.5",
    //      "GPOZ": "6",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "128",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "45",
    //      "CENT": "7.5",
    //      "DIAM": "7.5",
    //      "GPOZ": "6",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "129",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "46",
    //      "CENT": "7.34",
    //      "DIAM": "7.5",
    //      "GPOZ": "6",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "130",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "47",
    //      "CENT": "7.18",
    //      "DIAM": "7.5",
    //      "GPOZ": "7",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "131",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "49",
    //      "CENT": "6.89",
    //      "DIAM": "8.5",
    //      "GPOZ": "7",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "132",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "51",
    //      "CENT": "6.62",
    //      "DIAM": "8.5",
    //      "GPOZ": "7",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "133",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "53",
    //      "CENT": "6.37",
    //      "DIAM": "8.5",
    //      "GPOZ": "8",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "134",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "55",
    //      "CENT": "6.14",
    //      "DIAM": "9.5",
    //      "GPOZ": "8",
    //      "GBCE": "0",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "135",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "57",
    //      "CENT": "5.92",
    //      "DIAM": "9.5",
    //      "GPOZ": "8",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "136",
    //      "DESIGN": "ABC",
    //      "STYLE": "SOPER",
    //      "AVGK": "59",
    //      "CENT": "5.72",
    //      "DIAM": "9.5",
    //      "GPOZ": "8",
    //      "GPC1": "7.5",
    //      "GW1": "0.25",
    //      "GPC2": "8.44",
    //      "GW2": "0.1",
    //      "GPC3": "9.12",
    //      "GW3": "0.2",
    //      "GPC4": "13",
    //      "GW4": "0.2"
    //  },
    //  {
    //      "ID": "137",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "49.5",
    //      "KAVMAX": "50.5",
    //      "AVGK": "50",
    //      "CENT": "6.75",
    //      "DIAM": "8.6",
    //      "GPOZ": "6",
    //      "GBCE": "0",
    //      "GPC1": "7.25",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "8.25",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.75",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.75",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "138",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "49.5",
    //      "KAVMAX": "50.5",
    //      "AVGK": "50",
    //      "CENT": "6.75",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "7.25",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "8.25",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.75",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.75",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "139",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "49.5",
    //      "KAVMAX": "50.5",
    //      "AVGK": "50",
    //      "CENT": "6.75",
    //      "DIAM": "9.6",
    //      "GPOZ": "7",
    //      "GBCE": "0",
    //      "GPC1": "7.25",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "8.25",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.75",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.75",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "140",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "50.5",
    //      "KAVMAX": "51.5",
    //      "AVGK": "51",
    //      "CENT": "6.62",
    //      "DIAM": "8.6",
    //      "GPOZ": "6",
    //      "GBCE": "0",
    //      "GPC1": "7.1",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.6",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.6",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "141",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "50.5",
    //      "KAVMAX": "51.5",
    //      "AVGK": "51",
    //      "CENT": "6.62",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "7.1",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.6",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.6",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "142",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "50.5",
    //      "KAVMAX": "51.5",
    //      "AVGK": "51",
    //      "CENT": "6.62",
    //      "DIAM": "9.6",
    //      "GPOZ": "7",
    //      "GBCE": "0",
    //      "GPC1": "7.1",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "8.1",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.6",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.6",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "143",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "51.5",
    //      "KAVMAX": "52.5",
    //      "AVGK": "52",
    //      "CENT": "6.49",
    //      "DIAM": "8.6",
    //      "GPOZ": "6",
    //      "GBCE": "0",
    //      "GPC1": "7",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.5",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.5",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "144",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "51.5",
    //      "KAVMAX": "52.5",
    //      "AVGK": "52",
    //      "CENT": "6.49",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "7",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.5",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.5",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "145",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "51.5",
    //      "KAVMAX": "52.5",
    //      "AVGK": "52",
    //      "CENT": "6.49",
    //      "DIAM": "9.6",
    //      "GPOZ": "7",
    //      "GBCE": "0",
    //      "GPC1": "7",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "8",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.5",
    //      "GW3": "0.3",
    //      "GPC4": "11.5",
    //      "GW4": "0.4"
    //  },
    //  {
    //      "ID": "146",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "52.5",
    //      "KAVMAX": "53.5",
    //      "AVGK": "53",
    //      "CENT": "6.37",
    //      "DIAM": "8.6",
    //      "GPOZ": "6",
    //      "GBCE": "0",
    //      "GPC1": "6.85",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.85",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.35",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.35",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "147",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "52.5",
    //      "KAVMAX": "53.5",
    //      "AVGK": "53",
    //      "CENT": "6.37",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "6.85",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.85",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.35",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.35",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "148",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "52.5",
    //      "KAVMAX": "53.5",
    //      "AVGK": "53",
    //      "CENT": "6.37",
    //      "DIAM": "9.6",
    //      "GPOZ": "7",
    //      "GBCE": "0",
    //      "GPC1": "6.85",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.85",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.35",
    //      "GW3": "0.3",
    //      "GPC4": "11.35",
    //      "GW4": "0.4"
    //  },
    //  {
    //      "ID": "149",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "53.5",
    //      "KAVMAX": "54.5",
    //      "AVGK": "54",
    //      "CENT": "6.25",
    //      "DIAM": "8.6",
    //      "GPOZ": "6",
    //      "GBCE": "0",
    //      "GPC1": "6.75",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.75",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.25",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.25",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "150",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "53.5",
    //      "KAVMAX": "54.5",
    //      "AVGK": "54",
    //      "CENT": "6.25",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "6.75",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.75",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.25",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.25",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "151",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "53.5",
    //      "KAVMAX": "54.5",
    //      "AVGK": "54",
    //      "CENT": "6.25",
    //      "DIAM": "9.6",
    //      "GPOZ": "7",
    //      "GBCE": "0",
    //      "GPC1": "6.75",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.75",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.25",
    //      "GW3": "0.3",
    //      "GPC4": "11.25",
    //      "GW4": "0.4"
    //  },
    //  {
    //      "ID": "152",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "54.5",
    //      "KAVMAX": "55.5",
    //      "AVGK": "55",
    //      "CENT": "6.14",
    //      "DIAM": "8.6",
    //      "GPOZ": "6",
    //      "GBCE": "0",
    //      "GPC1": "6.65",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.65",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.15",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.15",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "153",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "54.5",
    //      "KAVMAX": "55.5",
    //      "AVGK": "55",
    //      "CENT": "6.14",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "6.65",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.65",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.15",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11.15",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "154",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "54.5",
    //      "KAVMAX": "55.5",
    //      "AVGK": "55",
    //      "CENT": "6.14",
    //      "DIAM": "9.6",
    //      "GPOZ": "7",
    //      "GBCE": "0",
    //      "GPC1": "6.65",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.65",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9.15",
    //      "GW3": "0.3",
    //      "GPC4": "11.15",
    //      "GW4": "0.4"
    //  },
    //  {
    //      "ID": "155",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "55.5",
    //      "KAVMAX": "56.5",
    //      "AVGK": "56",
    //      "CENT": "6.03",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "6.5",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.5",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "9",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "11",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "156",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "56.5",
    //      "KAVMAX": "57.5",
    //      "AVGK": "57",
    //      "CENT": "5.92",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "6.4",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.4",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "8.9",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "10.9",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "157",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "57.5",
    //      "KAVMAX": "58.5",
    //      "AVGK": "58",
    //      "CENT": "5.82",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "6.3",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.3",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "8.8",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "10.8",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "158",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "58.5",
    //      "KAVMAX": "59.5",
    //      "AVGK": "59",
    //      "CENT": "5.72",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "6.2",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.2",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "8.7",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "10.7",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  },
    //  {
    //      "ID": "159",
    //      "DESIGN": "ABC",
    //      "STYLE": "STD",
    //      "KAVMIN": "59.5",
    //      "KAVMAX": "60.5",
    //      "AVGK": "60",
    //      "CENT": "5.63",
    //      "DIAM": "9.1",
    //      "GPOZ": "6.5",
    //      "GBCE": "0",
    //      "GPC1": "6.1",
    //      "GW1": "0.3",
    //      "GCE1": "0",
    //      "GPC2": "7.1",
    //      "GW2": "0.3",
    //      "GCE2": "0",
    //      "GPC3": "8.6",
    //      "GW3": "0.3",
    //      "GCE3": "0",
    //      "GPC4": "10.6",
    //      "GW4": "0.4",
    //      "GCE4": "0",
    //      "GCE5": "0"
    //  }
    //];

    this.RkonDesn = [
        {
            "Id": "1",
            "STYLE": "AK84",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "8.4",
            "GPOZ": "7.2",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "2",
            "STYLE": "AK84",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "8.4",
            "GPOZ": "7.2",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.2",
            "GCE1": "-0.55",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "3",
            "STYLE": "AK84",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "8.4",
            "GPOZ": "7.2",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.2",
            "GCE1": "-0.6",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "4",
            "STYLE": "AK84",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "8.4",
            "GPOZ": "7",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.3",
            "GCE1": "-0.65",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "5",
            "STYLE": "AK84",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "8.4",
            "GPOZ": "6.8",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.4",
            "GCE1": "-0.7",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "6",
            "STYLE": "AK84",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "8.4",
            "GPOZ": "6.6",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.5",
            "GCE1": "-0.75",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "7",
            "STYLE": "AK84",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "8.4",
            "GPOZ": "6.4",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "0.6",
            "GCE1": "-0.8",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "8",
            "STYLE": "AK84",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "8.4",
            "GPOZ": "6.2",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "0.7",
            "GCE1": "-0.85",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "9",
            "STYLE": "AK84",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "8.4",
            "GPOZ": "6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "0.8",
            "GCE1": "-0.9",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "10",
            "STYLE": "AK84",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "8.4",
            "GPOZ": "5.8",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "0.9",
            "GCE1": "-0.95",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "11",
            "STYLE": "AK84",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "8.4",
            "GPOZ": "5.6",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1",
            "GCE1": "-1",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "12",
            "STYLE": "AK84",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "8.4",
            "GPOZ": "5.4",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.1",
            "GCE1": "-1",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "13",
            "STYLE": "AK84",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "8.4",
            "GPOZ": "5.2",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.2",
            "GCE1": "-1.1",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "14",
            "STYLE": "AK84",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "8.4",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.3",
            "GCE1": "-1.2",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "15",
            "STYLE": "AK92",
            "KAVMIN": "39",
            "KAVMAX": "41",
            "AVGK": "40",
            "CENT": "8.4",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.6",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.9",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "16",
            "STYLE": "AK92",
            "KAVMIN": "40",
            "KAVMAX": "42",
            "AVGK": "41",
            "CENT": "8.2",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.4",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.7",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "17",
            "STYLE": "AK92",
            "KAVMIN": "41",
            "KAVMAX": "43",
            "AVGK": "42",
            "CENT": "8",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.2",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.5",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "18",
            "STYLE": "AK92",
            "KAVMIN": "42",
            "KAVMAX": "44",
            "AVGK": "43",
            "CENT": "7.8",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.3",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "19",
            "STYLE": "AK92",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "20",
            "STYLE": "AK92",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.2",
            "GCE1": "-0.55",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "21",
            "STYLE": "AK92",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "9.2",
            "GPOZ": "7.2",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.3",
            "GCE1": "-0.6",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "22",
            "STYLE": "AK92",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "9.2",
            "GPOZ": "7",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.4",
            "GCE1": "-0.65",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "23",
            "STYLE": "AK92",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "9.2",
            "GPOZ": "6.8",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.5",
            "GCE1": "-0.7",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "24",
            "STYLE": "AK92",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "9.2",
            "GPOZ": "6.6",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.6",
            "GCE1": "-0.75",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "25",
            "STYLE": "AK92",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "9.2",
            "GPOZ": "6.4",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "0.7",
            "GCE1": "-0.8",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "26",
            "STYLE": "AK92",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "9.2",
            "GPOZ": "6.2",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "0.8",
            "GCE1": "-0.85",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "27",
            "STYLE": "AK92",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "9.2",
            "GPOZ": "6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "0.9",
            "GCE1": "-0.9",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "28",
            "STYLE": "AK92",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "9.2",
            "GPOZ": "5.8",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "1",
            "GCE1": "-0.95",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "29",
            "STYLE": "AK92",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "9.2",
            "GPOZ": "5.6",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1.1",
            "GCE1": "-1",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "30",
            "STYLE": "AK92",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "9.2",
            "GPOZ": "5.4",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.2",
            "GCE1": "-1",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "31",
            "STYLE": "AK92",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "9.2",
            "GPOZ": "5.2",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.3",
            "GCE1": "-1.1",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "32",
            "STYLE": "AK92",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "33",
            "STYLE": "AK92",
            "KAVMIN": "71",
            "KAVMAX": "73",
            "AVGK": "72.12",
            "CENT": "4.68",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "34",
            "STYLE": "AK92",
            "KAVMIN": "73.01",
            "KAVMAX": "75.12",
            "AVGK": "74.18",
            "CENT": "4.55",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.45",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "35",
            "STYLE": "AK92",
            "KAVMIN": "75.13",
            "KAVMAX": "77.12",
            "AVGK": "76.19",
            "CENT": "4.43",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.4",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "36",
            "STYLE": "AK92",
            "KAVMIN": "77.13",
            "KAVMAX": "79.12",
            "AVGK": "78.13",
            "CENT": "4.32",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.35",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "37",
            "STYLE": "AK92",
            "KAVMIN": "79.13",
            "KAVMAX": "81.12",
            "AVGK": "80.17",
            "CENT": "4.21",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.3",
            "GW1": "1.4",
            "GCE1": "-1.3",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "38",
            "STYLE": "AK92",
            "KAVMIN": "81.13",
            "KAVMAX": "83.25",
            "AVGK": "82.32",
            "CENT": "4.1",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.25",
            "GW1": "1.4",
            "GCE1": "-1.3",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "39",
            "STYLE": "AK92",
            "KAVMIN": "83.26",
            "KAVMAX": "87",
            "AVGK": "84.16",
            "CENT": "4.01",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.2",
            "GW1": "1.4",
            "GCE1": "-1.3",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "Id": "40",
            "STYLE": "GLOB",
            "KAVMIN": "43.5",
            "KAVMAX": "44.5",
            "AVGK": "44",
            "CENT": "7.67",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.9",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "41",
            "STYLE": "GLOB",
            "KAVMIN": "44.5",
            "KAVMAX": "45.5",
            "AVGK": "45",
            "CENT": "7.5",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "42",
            "STYLE": "GLOB",
            "KAVMIN": "45.5",
            "KAVMAX": "46.5",
            "AVGK": "46",
            "CENT": "7.34",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.7",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "43",
            "STYLE": "GLOB",
            "KAVMIN": "46.5",
            "KAVMAX": "47.5",
            "AVGK": "47",
            "CENT": "7.18",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.55",
            "GPC1": "7.6",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "44",
            "STYLE": "GLOB",
            "KAVMIN": "47.5",
            "KAVMAX": "48.5",
            "AVGK": "48",
            "CENT": "7.03",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.6",
            "GPC1": "7.5",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "45",
            "STYLE": "GLOB",
            "KAVMIN": "48.5",
            "KAVMAX": "49.5",
            "AVGK": "49",
            "CENT": "6.89",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.65",
            "GPC1": "7.4",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "46",
            "STYLE": "GLOB",
            "KAVMIN": "49.5",
            "KAVMAX": "50.5",
            "AVGK": "50",
            "CENT": "6.75",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.7",
            "GPC1": "7.3",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "47",
            "STYLE": "GLOB",
            "KAVMIN": "50.5",
            "KAVMAX": "51.5",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.75",
            "GPC1": "7.2",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "48",
            "STYLE": "GLOB",
            "KAVMIN": "51.5",
            "KAVMAX": "52.5",
            "AVGK": "52",
            "CENT": "6.49",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.8",
            "GPC1": "7.1",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "49",
            "STYLE": "GLOB",
            "KAVMIN": "52.5",
            "KAVMAX": "53.5",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.85",
            "GPC1": "7",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "50",
            "STYLE": "GLOB",
            "KAVMIN": "53.5",
            "KAVMAX": "54.5",
            "AVGK": "54",
            "CENT": "6.25",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.9",
            "GPC1": "6.9",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "Id": "51",
            "STYLE": "GLOB",
            "KAVMIN": "54.5",
            "KAVMAX": "55.5",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.9",
            "GPC1": "6.8",
            "GW1": "0",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4"
        },
        {
            "Id": "52",
            "STYLE": "GLOB",
            "KAVMIN": "55.5",
            "KAVMAX": "56.5",
            "AVGK": "56",
            "CENT": "6.03",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.9",
            "GPC1": "6.7",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4"
        },
        {
            "Id": "53",
            "STYLE": "GLOB",
            "KAVMIN": "56.5",
            "KAVMAX": "57.5",
            "AVGK": "57",
            "CENT": "5.92",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.9",
            "GPC1": "6.6",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4"
        },
        {
            "Id": "54",
            "STYLE": "IK104",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "10.4",
            "GPOZ": "8",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.5",
            "GCE1": "-0.5",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "55",
            "STYLE": "IK104",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "10.4",
            "GPOZ": "8",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.5",
            "GCE1": "-0.55",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "56",
            "STYLE": "IK104",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "10.4",
            "GPOZ": "7.8",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.6",
            "GCE1": "-0.6",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "57",
            "STYLE": "IK104",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "10.4",
            "GPOZ": "7.6",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.7",
            "GCE1": "-0.65",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "58",
            "STYLE": "IK104",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "10.4",
            "GPOZ": "7.4",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.8",
            "GCE1": "-0.7",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "59",
            "STYLE": "IK104",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "10.4",
            "GPOZ": "7.2",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.9",
            "GCE1": "-0.75",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "60",
            "STYLE": "IK104",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "10.4",
            "GPOZ": "7",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "1",
            "GCE1": "-0.8",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "61",
            "STYLE": "IK104",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "10.4",
            "GPOZ": "6.8",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "1.1",
            "GCE1": "-0.85",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "62",
            "STYLE": "IK104",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "10.4",
            "GPOZ": "6.6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "1.2",
            "GCE1": "-0.9",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "63",
            "STYLE": "IK104",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "10.4",
            "GPOZ": "6.4",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "1.3",
            "GCE1": "-0.95",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "64",
            "STYLE": "IK104",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "10.4",
            "GPOZ": "6.2",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1.4",
            "GCE1": "-1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "65",
            "STYLE": "IK104",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "10.4",
            "GPOZ": "6",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.5",
            "GCE1": "-1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "66",
            "STYLE": "IK104",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "10.4",
            "GPOZ": "5.8",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.6",
            "GCE1": "-1.1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "67",
            "STYLE": "IK104",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "10.4",
            "GPOZ": "5.6",
            "GBCE": "-1.1",
            "GPC1": "6.5",
            "GW1": "1.7",
            "GCE1": "-1.1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "68",
            "STYLE": "IK104",
            "KAVMIN": "69.12",
            "KAVMAX": "70.5",
            "AVGK": "69.75",
            "CENT": "4.8",
            "DIAM": "10.4",
            "GPOZ": "5.6",
            "GBCE": "-1.1",
            "GPC1": "6.4",
            "GW1": "1.7",
            "GCE1": "-1.1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "69",
            "STYLE": "IK104",
            "KAVMIN": "70.5",
            "KAVMAX": "72",
            "AVGK": "71.25",
            "CENT": "4.7",
            "DIAM": "10.4",
            "GPOZ": "5.4",
            "GBCE": "-1.2",
            "GPC1": "6.2",
            "GW1": "1.8",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "70",
            "STYLE": "IK104",
            "KAVMIN": "72",
            "KAVMAX": "73.5",
            "AVGK": "72.75",
            "CENT": "4.6",
            "DIAM": "10.4",
            "GPOZ": "5.4",
            "GBCE": "-1.2",
            "GPC1": "6.2",
            "GW1": "1.8",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "71",
            "STYLE": "IK104",
            "KAVMIN": "73.5",
            "KAVMAX": "75",
            "AVGK": "74.25",
            "CENT": "4.5",
            "DIAM": "10.4",
            "GPOZ": "5.2",
            "GBCE": "-1.2",
            "GPC1": "6",
            "GW1": "1.9",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "72",
            "STYLE": "IK104",
            "KAVMIN": "75",
            "KAVMAX": "79",
            "AVGK": "77",
            "CENT": "4.4",
            "DIAM": "10.4",
            "GPOZ": "5.2",
            "GBCE": "-1.2",
            "GPC1": "6",
            "GW1": "1.9",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "73",
            "STYLE": "IK104",
            "KAVMIN": "79",
            "KAVMAX": "85",
            "AVGK": "82",
            "CENT": "4.1",
            "DIAM": "10.4",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6",
            "GW1": "2",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "74",
            "STYLE": "IK88",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "8.8",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "75",
            "STYLE": "IK88",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "8.8",
            "GPOZ": "7.4",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.3",
            "GCE1": "-0.55",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "76",
            "STYLE": "IK88",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "8.8",
            "GPOZ": "7.2",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.4",
            "GCE1": "-0.6",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "77",
            "STYLE": "IK88",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "8.8",
            "GPOZ": "7",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.5",
            "GCE1": "-0.65",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "78",
            "STYLE": "IK88",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "8.8",
            "GPOZ": "6.8",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.6",
            "GCE1": "-0.7",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "79",
            "STYLE": "IK88",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "8.8",
            "GPOZ": "6.6",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.7",
            "GCE1": "-0.75",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "80",
            "STYLE": "IK88",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "8.8",
            "GPOZ": "6.4",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "0.8",
            "GCE1": "-0.8",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "81",
            "STYLE": "IK88",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "8.8",
            "GPOZ": "6.2",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "0.9",
            "GCE1": "-0.85",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "82",
            "STYLE": "IK88",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "8.8",
            "GPOZ": "6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "1",
            "GCE1": "-0.9",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "83",
            "STYLE": "IK88",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "8.8",
            "GPOZ": "5.8",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "1.1",
            "GCE1": "-0.95",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "84",
            "STYLE": "IK88",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "8.8",
            "GPOZ": "5.6",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1.2",
            "GCE1": "-1",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "85",
            "STYLE": "IK88",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "8.8",
            "GPOZ": "5.4",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.3",
            "GCE1": "-1",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "86",
            "STYLE": "IK88",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "8.8",
            "GPOZ": "5.2",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.4",
            "GCE1": "-1.1",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "87",
            "STYLE": "IK88",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "8.8",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "Id": "88",
            "STYLE": "IK96",
            "KAVMIN": "39",
            "KAVMAX": "41",
            "AVGK": "40",
            "CENT": "8.4",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.6",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "9",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "89",
            "STYLE": "IK96",
            "KAVMIN": "40",
            "KAVMAX": "42",
            "AVGK": "41",
            "CENT": "8.2",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.4",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "8.8",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "90",
            "STYLE": "IK96",
            "KAVMIN": "41",
            "KAVMAX": "43",
            "AVGK": "42",
            "CENT": "8",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.2",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "8.6",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "91",
            "STYLE": "IK96",
            "KAVMIN": "42",
            "KAVMAX": "44",
            "AVGK": "43",
            "CENT": "7.8",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "8.4",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "92",
            "STYLE": "IK96",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "93",
            "STYLE": "IK96",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.3",
            "GCE1": "-0.55",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "94",
            "STYLE": "IK96",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "9.6",
            "GPOZ": "7.2",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.4",
            "GCE1": "-0.6",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "95",
            "STYLE": "IK96",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.5",
            "GCE1": "-0.65",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "96",
            "STYLE": "IK96",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "9.6",
            "GPOZ": "6.8",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.6",
            "GCE1": "-0.7",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "97",
            "STYLE": "IK96",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "9.6",
            "GPOZ": "6.6",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.7",
            "GCE1": "-0.75",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "98",
            "STYLE": "IK96",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "9.6",
            "GPOZ": "6.4",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "0.8",
            "GCE1": "-0.8",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "99",
            "STYLE": "IK96",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "9.6",
            "GPOZ": "6.2",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "0.9",
            "GCE1": "-0.85",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "100",
            "STYLE": "IK96",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "9.6",
            "GPOZ": "6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "1",
            "GCE1": "-0.9",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "101",
            "STYLE": "IK96",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "9.6",
            "GPOZ": "5.8",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "1.1",
            "GCE1": "-0.95",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "102",
            "STYLE": "IK96",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "9.6",
            "GPOZ": "5.6",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1.2",
            "GCE1": "-1",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "103",
            "STYLE": "IK96",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "9.6",
            "GPOZ": "5.4",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.3",
            "GCE1": "-1",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "104",
            "STYLE": "IK96",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "9.6",
            "GPOZ": "5.2",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.4",
            "GCE1": "-1.1",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "105",
            "STYLE": "IK96",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "106",
            "STYLE": "IK96",
            "KAVMIN": "71",
            "KAVMAX": "73",
            "AVGK": "72.12",
            "CENT": "4.68",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "107",
            "STYLE": "IK96",
            "KAVMIN": "73.01",
            "KAVMAX": "75.12",
            "AVGK": "74.18",
            "CENT": "4.55",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.45",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "108",
            "STYLE": "IK96",
            "KAVMIN": "75.13",
            "KAVMAX": "77.12",
            "AVGK": "76.19",
            "CENT": "4.43",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.4",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "109",
            "STYLE": "IK96",
            "KAVMIN": "77.13",
            "KAVMAX": "79.12",
            "AVGK": "78.13",
            "CENT": "4.32",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.35",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "110",
            "STYLE": "IK96",
            "KAVMIN": "79.13",
            "KAVMAX": "81.12",
            "AVGK": "80.17",
            "CENT": "4.21",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.3",
            "GW1": "1.5",
            "GCE1": "-1.3",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "111",
            "STYLE": "IK96",
            "KAVMIN": "81.13",
            "KAVMAX": "83.25",
            "AVGK": "82.32",
            "CENT": "4.1",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.25",
            "GW1": "1.5",
            "GCE1": "-1.3",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "112",
            "STYLE": "IK96",
            "KAVMIN": "83.26",
            "KAVMAX": "87",
            "AVGK": "84.16",
            "CENT": "4.01",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.2",
            "GW1": "1.5",
            "GCE1": "-1.3",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "Id": "113",
            "STYLE": "IKGLOB",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "10.8",
            "GPOZ": "8.4",
            "GBCE": "-0.25",
            "GPC1": "7.8",
            "GW1": "0.5",
            "GCE1": "-0.25",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "114",
            "STYLE": "IKGLOB",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "10.8",
            "GPOZ": "8.4",
            "GBCE": "-0.28",
            "GPC1": "7.7",
            "GW1": "0.5",
            "GCE1": "-0.28",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "115",
            "STYLE": "IKGLOB",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "10.8",
            "GPOZ": "8.2",
            "GBCE": "-0.3",
            "GPC1": "7.6",
            "GW1": "0.6",
            "GCE1": "-0.3",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "116",
            "STYLE": "IKGLOB",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "10.8",
            "GPOZ": "8",
            "GBCE": "-0.33",
            "GPC1": "7.5",
            "GW1": "0.7",
            "GCE1": "-0.33",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "117",
            "STYLE": "IKGLOB",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "10.8",
            "GPOZ": "7.8",
            "GBCE": "-0.35",
            "GPC1": "7.4",
            "GW1": "0.8",
            "GCE1": "-0.35",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "118",
            "STYLE": "IKGLOB",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "10.8",
            "GPOZ": "7.6",
            "GBCE": "-0.38",
            "GPC1": "7.3",
            "GW1": "0.9",
            "GCE1": "-0.38",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "119",
            "STYLE": "IKGLOB",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "10.8",
            "GPOZ": "7.4",
            "GBCE": "-0.4",
            "GPC1": "7.2",
            "GW1": "1",
            "GCE1": "-0.4",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "120",
            "STYLE": "IKGLOB",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "10.8",
            "GPOZ": "7.2",
            "GBCE": "-0.43",
            "GPC1": "7.1",
            "GW1": "1.1",
            "GCE1": "-0.43",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "121",
            "STYLE": "IKGLOB",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "10.8",
            "GPOZ": "7",
            "GBCE": "-0.45",
            "GPC1": "7",
            "GW1": "1.2",
            "GCE1": "-0.45",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "122",
            "STYLE": "IKGLOB",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "10.8",
            "GPOZ": "6.8",
            "GBCE": "-0.48",
            "GPC1": "6.9",
            "GW1": "1.3",
            "GCE1": "-0.48",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "123",
            "STYLE": "IKGLOB",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "10.8",
            "GPOZ": "6.6",
            "GBCE": "-0.5",
            "GPC1": "6.8",
            "GW1": "1.4",
            "GCE1": "-0.5",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "124",
            "STYLE": "IKGLOB",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "10.8",
            "GPOZ": "6.4",
            "GBCE": "-0.5",
            "GPC1": "6.7",
            "GW1": "1.5",
            "GCE1": "-0.5",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "125",
            "STYLE": "IKGLOB",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "10.8",
            "GPOZ": "6.2",
            "GBCE": "-0.55",
            "GPC1": "6.6",
            "GW1": "1.6",
            "GCE1": "-0.55",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "126",
            "STYLE": "IKGLOB",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "10.8",
            "GPOZ": "6",
            "GBCE": "-0.6",
            "GPC1": "6.5",
            "GW1": "1.7",
            "GCE1": "-0.6",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "Id": "127",
            "STYLE": "SOPER",
            "AVGK": "44",
            "CENT": "7.67",
            "DIAM": "7.5",
            "GPOZ": "6",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "128",
            "STYLE": "SOPER",
            "AVGK": "45",
            "CENT": "7.5",
            "DIAM": "7.5",
            "GPOZ": "6",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "129",
            "STYLE": "SOPER",
            "AVGK": "46",
            "CENT": "7.34",
            "DIAM": "7.5",
            "GPOZ": "6",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "130",
            "STYLE": "SOPER",
            "AVGK": "47",
            "CENT": "7.18",
            "DIAM": "7.5",
            "GPOZ": "7",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "131",
            "STYLE": "SOPER",
            "AVGK": "49",
            "CENT": "6.89",
            "DIAM": "8.5",
            "GPOZ": "7",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "132",
            "STYLE": "SOPER",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "8.5",
            "GPOZ": "7",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "133",
            "STYLE": "SOPER",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "8.5",
            "GPOZ": "8",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "134",
            "STYLE": "SOPER",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "9.5",
            "GPOZ": "8",
            "GBCE": "0",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "135",
            "STYLE": "SOPER",
            "AVGK": "57",
            "CENT": "5.92",
            "DIAM": "9.5",
            "GPOZ": "8",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "136",
            "STYLE": "SOPER",
            "AVGK": "59",
            "CENT": "5.72",
            "DIAM": "9.5",
            "GPOZ": "8",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "Id": "137",
            "STYLE": "STD",
            "KAVMIN": "49.5",
            "KAVMAX": "50.5",
            "AVGK": "50",
            "CENT": "6.75",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "7.25",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.25",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.75",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.75",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "138",
            "STYLE": "STD",
            "KAVMIN": "49.5",
            "KAVMAX": "50.5",
            "AVGK": "50",
            "CENT": "6.75",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "7.25",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.25",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.75",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.75",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "139",
            "STYLE": "STD",
            "KAVMIN": "49.5",
            "KAVMAX": "50.5",
            "AVGK": "50",
            "CENT": "6.75",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "7.25",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.25",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.75",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.75",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "140",
            "STYLE": "STD",
            "KAVMIN": "50.5",
            "KAVMAX": "51.5",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "7.1",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.6",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.6",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "141",
            "STYLE": "STD",
            "KAVMIN": "50.5",
            "KAVMAX": "51.5",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "7.1",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.6",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.6",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "142",
            "STYLE": "STD",
            "KAVMIN": "50.5",
            "KAVMAX": "51.5",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "7.1",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.6",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.6",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "143",
            "STYLE": "STD",
            "KAVMIN": "51.5",
            "KAVMAX": "52.5",
            "AVGK": "52",
            "CENT": "6.49",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "7",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.5",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.5",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "144",
            "STYLE": "STD",
            "KAVMIN": "51.5",
            "KAVMAX": "52.5",
            "AVGK": "52",
            "CENT": "6.49",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "7",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.5",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.5",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "145",
            "STYLE": "STD",
            "KAVMIN": "51.5",
            "KAVMAX": "52.5",
            "AVGK": "52",
            "CENT": "6.49",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "7",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.5",
            "GW3": "0.3",
            "GPC4": "11.5",
            "GW4": "0.4"
        },
        {
            "Id": "146",
            "STYLE": "STD",
            "KAVMIN": "52.5",
            "KAVMAX": "53.5",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "6.85",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.85",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.35",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.35",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "147",
            "STYLE": "STD",
            "KAVMIN": "52.5",
            "KAVMAX": "53.5",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.85",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.85",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.35",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.35",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "148",
            "STYLE": "STD",
            "KAVMIN": "52.5",
            "KAVMAX": "53.5",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "6.85",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.85",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.35",
            "GW3": "0.3",
            "GPC4": "11.35",
            "GW4": "0.4"
        },
        {
            "Id": "149",
            "STYLE": "STD",
            "KAVMIN": "53.5",
            "KAVMAX": "54.5",
            "AVGK": "54",
            "CENT": "6.25",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "6.75",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.75",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.25",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.25",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "150",
            "STYLE": "STD",
            "KAVMIN": "53.5",
            "KAVMAX": "54.5",
            "AVGK": "54",
            "CENT": "6.25",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.75",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.75",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.25",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.25",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "151",
            "STYLE": "STD",
            "KAVMIN": "53.5",
            "KAVMAX": "54.5",
            "AVGK": "54",
            "CENT": "6.25",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "6.75",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.75",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.25",
            "GW3": "0.3",
            "GPC4": "11.25",
            "GW4": "0.4"
        },
        {
            "Id": "152",
            "STYLE": "STD",
            "KAVMIN": "54.5",
            "KAVMAX": "55.5",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "6.65",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.65",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.15",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.15",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "153",
            "STYLE": "STD",
            "KAVMIN": "54.5",
            "KAVMAX": "55.5",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.65",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.65",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.15",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.15",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "154",
            "STYLE": "STD",
            "KAVMIN": "54.5",
            "KAVMAX": "55.5",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "6.65",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.65",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.15",
            "GW3": "0.3",
            "GPC4": "11.15",
            "GW4": "0.4"
        },
        {
            "Id": "155",
            "STYLE": "STD",
            "KAVMIN": "55.5",
            "KAVMAX": "56.5",
            "AVGK": "56",
            "CENT": "6.03",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.5",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.5",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "156",
            "STYLE": "STD",
            "KAVMIN": "56.5",
            "KAVMAX": "57.5",
            "AVGK": "57",
            "CENT": "5.92",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.4",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.4",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "8.9",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "10.9",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "157",
            "STYLE": "STD",
            "KAVMIN": "57.5",
            "KAVMAX": "58.5",
            "AVGK": "58",
            "CENT": "5.82",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.3",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.3",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "8.8",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "10.8",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "158",
            "STYLE": "STD",
            "KAVMIN": "58.5",
            "KAVMAX": "59.5",
            "AVGK": "59",
            "CENT": "5.72",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.2",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.2",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "8.7",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "10.7",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "Id": "159",
            "STYLE": "STD",
            "KAVMIN": "59.5",
            "KAVMAX": "60.5",
            "AVGK": "60",
            "CENT": "5.63",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.1",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.1",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "8.6",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "10.6",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        }
    ];


    this.RkonDesn = [
        {
            "ID": "1",
            "STYLE": "AK84",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "8.4",
            "GPOZ": "7.2",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "2",
            "STYLE": "AK84",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "8.4",
            "GPOZ": "7.2",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.2",
            "GCE1": "-0.55",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "3",
            "STYLE": "AK84",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "8.4",
            "GPOZ": "7.2",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.2",
            "GCE1": "-0.6",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "4",
            "STYLE": "AK84",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "8.4",
            "GPOZ": "7",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.3",
            "GCE1": "-0.65",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "5",
            "STYLE": "AK84",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "8.4",
            "GPOZ": "6.8",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.4",
            "GCE1": "-0.7",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "6",
            "STYLE": "AK84",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "8.4",
            "GPOZ": "6.6",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.5",
            "GCE1": "-0.75",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "7",
            "STYLE": "AK84",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "8.4",
            "GPOZ": "6.4",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "0.6",
            "GCE1": "-0.8",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "8",
            "STYLE": "AK84",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "8.4",
            "GPOZ": "6.2",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "0.7",
            "GCE1": "-0.85",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "9",
            "STYLE": "AK84",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "8.4",
            "GPOZ": "6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "0.8",
            "GCE1": "-0.9",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "10",
            "STYLE": "AK84",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "8.4",
            "GPOZ": "5.8",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "0.9",
            "GCE1": "-0.95",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "11",
            "STYLE": "AK84",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "8.4",
            "GPOZ": "5.6",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1",
            "GCE1": "-1",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "12",
            "STYLE": "AK84",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "8.4",
            "GPOZ": "5.4",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.1",
            "GCE1": "-1",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "13",
            "STYLE": "AK84",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "8.4",
            "GPOZ": "5.2",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.2",
            "GCE1": "-1.1",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "14",
            "STYLE": "AK84",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "8.4",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.3",
            "GCE1": "-1.2",
            "GPC2": "9",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "15",
            "STYLE": "AK92",
            "KAVMIN": "39",
            "KAVMAX": "41",
            "AVGK": "40",
            "CENT": "8.4",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.6",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.9",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "16",
            "STYLE": "AK92",
            "KAVMIN": "40",
            "KAVMAX": "42",
            "AVGK": "41",
            "CENT": "8.2",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.4",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.7",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "17",
            "STYLE": "AK92",
            "KAVMIN": "41",
            "KAVMAX": "43",
            "AVGK": "42",
            "CENT": "8",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.2",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.5",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "18",
            "STYLE": "AK92",
            "KAVMIN": "42",
            "KAVMAX": "44",
            "AVGK": "43",
            "CENT": "7.8",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.3",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "19",
            "STYLE": "AK92",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.2",
            "GCE1": "-0.5",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "20",
            "STYLE": "AK92",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "9.2",
            "GPOZ": "7.4",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.2",
            "GCE1": "-0.55",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "21",
            "STYLE": "AK92",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "9.2",
            "GPOZ": "7.2",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.3",
            "GCE1": "-0.6",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "22",
            "STYLE": "AK92",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "9.2",
            "GPOZ": "7",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.4",
            "GCE1": "-0.65",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "23",
            "STYLE": "AK92",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "9.2",
            "GPOZ": "6.8",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.5",
            "GCE1": "-0.7",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "24",
            "STYLE": "AK92",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "9.2",
            "GPOZ": "6.6",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.6",
            "GCE1": "-0.75",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "25",
            "STYLE": "AK92",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "9.2",
            "GPOZ": "6.4",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "0.7",
            "GCE1": "-0.8",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "26",
            "STYLE": "AK92",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "9.2",
            "GPOZ": "6.2",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "0.8",
            "GCE1": "-0.85",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "27",
            "STYLE": "AK92",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "9.2",
            "GPOZ": "6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "0.9",
            "GCE1": "-0.9",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "28",
            "STYLE": "AK92",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "9.2",
            "GPOZ": "5.8",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "1",
            "GCE1": "-0.95",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "29",
            "STYLE": "AK92",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "9.2",
            "GPOZ": "5.6",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1.1",
            "GCE1": "-1",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "30",
            "STYLE": "AK92",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "9.2",
            "GPOZ": "5.4",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.2",
            "GCE1": "-1",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "31",
            "STYLE": "AK92",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "9.2",
            "GPOZ": "5.2",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.3",
            "GCE1": "-1.1",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "32",
            "STYLE": "AK92",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "33",
            "STYLE": "AK92",
            "KAVMIN": "71",
            "KAVMAX": "73",
            "AVGK": "72.12",
            "CENT": "4.68",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "34",
            "STYLE": "AK92",
            "KAVMIN": "73.01",
            "KAVMAX": "75.12",
            "AVGK": "74.18",
            "CENT": "4.55",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.45",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "35",
            "STYLE": "AK92",
            "KAVMIN": "75.13",
            "KAVMAX": "77.12",
            "AVGK": "76.19",
            "CENT": "4.43",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.4",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "36",
            "STYLE": "AK92",
            "KAVMIN": "77.13",
            "KAVMAX": "79.12",
            "AVGK": "78.13",
            "CENT": "4.32",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.35",
            "GW1": "1.4",
            "GCE1": "-1.2",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "37",
            "STYLE": "AK92",
            "KAVMIN": "79.13",
            "KAVMAX": "81.12",
            "AVGK": "80.17",
            "CENT": "4.21",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.3",
            "GW1": "1.4",
            "GCE1": "-1.3",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "38",
            "STYLE": "AK92",
            "KAVMIN": "81.13",
            "KAVMAX": "83.25",
            "AVGK": "82.32",
            "CENT": "4.1",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.25",
            "GW1": "1.4",
            "GCE1": "-1.3",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "39",
            "STYLE": "AK92",
            "KAVMIN": "83.26",
            "KAVMAX": "87",
            "AVGK": "84.16",
            "CENT": "4.01",
            "DIAM": "9.2",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.2",
            "GW1": "1.4",
            "GCE1": "-1.3",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "9.6",
            "GW3": "0.4"
        },
        {
            "ID": "40",
            "STYLE": "GLOB",
            "KAVMIN": "43.5",
            "KAVMAX": "44.5",
            "AVGK": "44",
            "CENT": "7.67",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.9",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "41",
            "STYLE": "GLOB",
            "KAVMIN": "44.5",
            "KAVMAX": "45.5",
            "AVGK": "45",
            "CENT": "7.5",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "42",
            "STYLE": "GLOB",
            "KAVMIN": "45.5",
            "KAVMAX": "46.5",
            "AVGK": "46",
            "CENT": "7.34",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.7",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "43",
            "STYLE": "GLOB",
            "KAVMIN": "46.5",
            "KAVMAX": "47.5",
            "AVGK": "47",
            "CENT": "7.18",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.55",
            "GPC1": "7.6",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "44",
            "STYLE": "GLOB",
            "KAVMIN": "47.5",
            "KAVMAX": "48.5",
            "AVGK": "48",
            "CENT": "7.03",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.6",
            "GPC1": "7.5",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "45",
            "STYLE": "GLOB",
            "KAVMIN": "48.5",
            "KAVMAX": "49.5",
            "AVGK": "49",
            "CENT": "6.89",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.65",
            "GPC1": "7.4",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "46",
            "STYLE": "GLOB",
            "KAVMIN": "49.5",
            "KAVMAX": "50.5",
            "AVGK": "50",
            "CENT": "6.75",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.7",
            "GPC1": "7.3",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "47",
            "STYLE": "GLOB",
            "KAVMIN": "50.5",
            "KAVMAX": "51.5",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.75",
            "GPC1": "7.2",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "48",
            "STYLE": "GLOB",
            "KAVMIN": "51.5",
            "KAVMAX": "52.5",
            "AVGK": "52",
            "CENT": "6.49",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.8",
            "GPC1": "7.1",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "49",
            "STYLE": "GLOB",
            "KAVMIN": "52.5",
            "KAVMAX": "53.5",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.85",
            "GPC1": "7",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "50",
            "STYLE": "GLOB",
            "KAVMIN": "53.5",
            "KAVMAX": "54.5",
            "AVGK": "54",
            "CENT": "6.25",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.9",
            "GPC1": "6.9",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4",
            "GPC4": "0",
            "GW4": "0"
        },
        {
            "ID": "51",
            "STYLE": "GLOB",
            "KAVMIN": "54.5",
            "KAVMAX": "55.5",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.9",
            "GPC1": "6.8",
            "GW1": "0",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4"
        },
        {
            "ID": "52",
            "STYLE": "GLOB",
            "KAVMIN": "55.5",
            "KAVMAX": "56.5",
            "AVGK": "56",
            "CENT": "6.03",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.9",
            "GPC1": "6.7",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4"
        },
        {
            "ID": "53",
            "STYLE": "GLOB",
            "KAVMIN": "56.5",
            "KAVMAX": "57.5",
            "AVGK": "57",
            "CENT": "5.92",
            "DIAM": "9.8",
            "GPOZ": "7.4",
            "GBCE": "-0.9",
            "GPC1": "6.6",
            "GW1": "0.8",
            "GCE1": "-0.99",
            "GPC2": "10.5",
            "GW2": "0.4"
        },
        {
            "ID": "54",
            "STYLE": "IK104",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "10.4",
            "GPOZ": "8",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.5",
            "GCE1": "-0.5",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "55",
            "STYLE": "IK104",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "10.4",
            "GPOZ": "8",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.5",
            "GCE1": "-0.55",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "56",
            "STYLE": "IK104",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "10.4",
            "GPOZ": "7.8",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.6",
            "GCE1": "-0.6",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "57",
            "STYLE": "IK104",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "10.4",
            "GPOZ": "7.6",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.7",
            "GCE1": "-0.65",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "58",
            "STYLE": "IK104",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "10.4",
            "GPOZ": "7.4",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.8",
            "GCE1": "-0.7",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "59",
            "STYLE": "IK104",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "10.4",
            "GPOZ": "7.2",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.9",
            "GCE1": "-0.75",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "60",
            "STYLE": "IK104",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "10.4",
            "GPOZ": "7",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "1",
            "GCE1": "-0.8",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "61",
            "STYLE": "IK104",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "10.4",
            "GPOZ": "6.8",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "1.1",
            "GCE1": "-0.85",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "62",
            "STYLE": "IK104",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "10.4",
            "GPOZ": "6.6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "1.2",
            "GCE1": "-0.9",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "63",
            "STYLE": "IK104",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "10.4",
            "GPOZ": "6.4",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "1.3",
            "GCE1": "-0.95",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "64",
            "STYLE": "IK104",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "10.4",
            "GPOZ": "6.2",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1.4",
            "GCE1": "-1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "65",
            "STYLE": "IK104",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "10.4",
            "GPOZ": "6",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.5",
            "GCE1": "-1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "66",
            "STYLE": "IK104",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "10.4",
            "GPOZ": "5.8",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.6",
            "GCE1": "-1.1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "67",
            "STYLE": "IK104",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "10.4",
            "GPOZ": "5.6",
            "GBCE": "-1.1",
            "GPC1": "6.5",
            "GW1": "1.7",
            "GCE1": "-1.1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "68",
            "STYLE": "IK104",
            "KAVMIN": "69.12",
            "KAVMAX": "70.5",
            "AVGK": "69.75",
            "CENT": "4.8",
            "DIAM": "10.4",
            "GPOZ": "5.6",
            "GBCE": "-1.1",
            "GPC1": "6.4",
            "GW1": "1.7",
            "GCE1": "-1.1",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "69",
            "STYLE": "IK104",
            "KAVMIN": "70.5",
            "KAVMAX": "72",
            "AVGK": "71.25",
            "CENT": "4.7",
            "DIAM": "10.4",
            "GPOZ": "5.4",
            "GBCE": "-1.2",
            "GPC1": "6.2",
            "GW1": "1.8",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "70",
            "STYLE": "IK104",
            "KAVMIN": "72",
            "KAVMAX": "73.5",
            "AVGK": "72.75",
            "CENT": "4.6",
            "DIAM": "10.4",
            "GPOZ": "5.4",
            "GBCE": "-1.2",
            "GPC1": "6.2",
            "GW1": "1.8",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "71",
            "STYLE": "IK104",
            "KAVMIN": "73.5",
            "KAVMAX": "75",
            "AVGK": "74.25",
            "CENT": "4.5",
            "DIAM": "10.4",
            "GPOZ": "5.2",
            "GBCE": "-1.2",
            "GPC1": "6",
            "GW1": "1.9",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "72",
            "STYLE": "IK104",
            "KAVMIN": "75",
            "KAVMAX": "79",
            "AVGK": "77",
            "CENT": "4.4",
            "DIAM": "10.4",
            "GPOZ": "5.2",
            "GBCE": "-1.2",
            "GPC1": "6",
            "GW1": "1.9",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "73",
            "STYLE": "IK104",
            "KAVMIN": "79",
            "KAVMAX": "85",
            "AVGK": "82",
            "CENT": "4.1",
            "DIAM": "10.4",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6",
            "GW1": "2",
            "GCE1": "-1.2",
            "GPC2": "8.6",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "74",
            "STYLE": "IK88",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "8.8",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "75",
            "STYLE": "IK88",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "8.8",
            "GPOZ": "7.4",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.3",
            "GCE1": "-0.55",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "76",
            "STYLE": "IK88",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "8.8",
            "GPOZ": "7.2",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.4",
            "GCE1": "-0.6",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "77",
            "STYLE": "IK88",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "8.8",
            "GPOZ": "7",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.5",
            "GCE1": "-0.65",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "78",
            "STYLE": "IK88",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "8.8",
            "GPOZ": "6.8",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.6",
            "GCE1": "-0.7",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "79",
            "STYLE": "IK88",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "8.8",
            "GPOZ": "6.6",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.7",
            "GCE1": "-0.75",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "80",
            "STYLE": "IK88",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "8.8",
            "GPOZ": "6.4",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "0.8",
            "GCE1": "-0.8",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "81",
            "STYLE": "IK88",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "8.8",
            "GPOZ": "6.2",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "0.9",
            "GCE1": "-0.85",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "82",
            "STYLE": "IK88",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "8.8",
            "GPOZ": "6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "1",
            "GCE1": "-0.9",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "83",
            "STYLE": "IK88",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "8.8",
            "GPOZ": "5.8",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "1.1",
            "GCE1": "-0.95",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "84",
            "STYLE": "IK88",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "8.8",
            "GPOZ": "5.6",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1.2",
            "GCE1": "-1",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "85",
            "STYLE": "IK88",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "8.8",
            "GPOZ": "5.4",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.3",
            "GCE1": "-1",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "86",
            "STYLE": "IK88",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "8.8",
            "GPOZ": "5.2",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.4",
            "GCE1": "-1.1",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "87",
            "STYLE": "IK88",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "8.8",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "9.4",
            "GW2": "0.4",
            "GPC3": "0",
            "GW3": "0"
        },
        {
            "ID": "88",
            "STYLE": "IK96",
            "KAVMIN": "39",
            "KAVMAX": "41",
            "AVGK": "40",
            "CENT": "8.4",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.6",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "9",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "89",
            "STYLE": "IK96",
            "KAVMIN": "40",
            "KAVMAX": "42",
            "AVGK": "41",
            "CENT": "8.2",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.4",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "8.8",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "90",
            "STYLE": "IK96",
            "KAVMIN": "41",
            "KAVMAX": "43",
            "AVGK": "42",
            "CENT": "8",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8.2",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "8.6",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "91",
            "STYLE": "IK96",
            "KAVMIN": "42",
            "KAVMAX": "44",
            "AVGK": "43",
            "CENT": "7.8",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "8",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "8.4",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "92",
            "STYLE": "IK96",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.5",
            "GPC1": "7.8",
            "GW1": "0.3",
            "GCE1": "-0.5",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "93",
            "STYLE": "IK96",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "9.6",
            "GPOZ": "7.4",
            "GBCE": "-0.55",
            "GPC1": "7.7",
            "GW1": "0.3",
            "GCE1": "-0.55",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "94",
            "STYLE": "IK96",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "9.6",
            "GPOZ": "7.2",
            "GBCE": "-0.6",
            "GPC1": "7.6",
            "GW1": "0.4",
            "GCE1": "-0.6",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "95",
            "STYLE": "IK96",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "-0.65",
            "GPC1": "7.5",
            "GW1": "0.5",
            "GCE1": "-0.65",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "96",
            "STYLE": "IK96",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "9.6",
            "GPOZ": "6.8",
            "GBCE": "-0.7",
            "GPC1": "7.4",
            "GW1": "0.6",
            "GCE1": "-0.7",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "97",
            "STYLE": "IK96",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "9.6",
            "GPOZ": "6.6",
            "GBCE": "-0.75",
            "GPC1": "7.3",
            "GW1": "0.7",
            "GCE1": "-0.75",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "98",
            "STYLE": "IK96",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "9.6",
            "GPOZ": "6.4",
            "GBCE": "-0.8",
            "GPC1": "7.2",
            "GW1": "0.8",
            "GCE1": "-0.8",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "99",
            "STYLE": "IK96",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "9.6",
            "GPOZ": "6.2",
            "GBCE": "-0.85",
            "GPC1": "7.1",
            "GW1": "0.9",
            "GCE1": "-0.85",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "100",
            "STYLE": "IK96",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "9.6",
            "GPOZ": "6",
            "GBCE": "-0.9",
            "GPC1": "7",
            "GW1": "1",
            "GCE1": "-0.9",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "101",
            "STYLE": "IK96",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "9.6",
            "GPOZ": "5.8",
            "GBCE": "-0.95",
            "GPC1": "6.9",
            "GW1": "1.1",
            "GCE1": "-0.95",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "102",
            "STYLE": "IK96",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "9.6",
            "GPOZ": "5.6",
            "GBCE": "-1",
            "GPC1": "6.8",
            "GW1": "1.2",
            "GCE1": "-1",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "103",
            "STYLE": "IK96",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "9.6",
            "GPOZ": "5.4",
            "GBCE": "-1",
            "GPC1": "6.7",
            "GW1": "1.3",
            "GCE1": "-1",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "104",
            "STYLE": "IK96",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "9.6",
            "GPOZ": "5.2",
            "GBCE": "-1.1",
            "GPC1": "6.6",
            "GW1": "1.4",
            "GCE1": "-1.1",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "105",
            "STYLE": "IK96",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "106",
            "STYLE": "IK96",
            "KAVMIN": "71",
            "KAVMAX": "73",
            "AVGK": "72.12",
            "CENT": "4.68",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.5",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "107",
            "STYLE": "IK96",
            "KAVMIN": "73.01",
            "KAVMAX": "75.12",
            "AVGK": "74.18",
            "CENT": "4.55",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.45",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "108",
            "STYLE": "IK96",
            "KAVMIN": "75.13",
            "KAVMAX": "77.12",
            "AVGK": "76.19",
            "CENT": "4.43",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.4",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "109",
            "STYLE": "IK96",
            "KAVMIN": "77.13",
            "KAVMAX": "79.12",
            "AVGK": "78.13",
            "CENT": "4.32",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.2",
            "GPC1": "6.35",
            "GW1": "1.5",
            "GCE1": "-1.2",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "110",
            "STYLE": "IK96",
            "KAVMIN": "79.13",
            "KAVMAX": "81.12",
            "AVGK": "80.17",
            "CENT": "4.21",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.3",
            "GW1": "1.5",
            "GCE1": "-1.3",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "111",
            "STYLE": "IK96",
            "KAVMIN": "81.13",
            "KAVMAX": "83.25",
            "AVGK": "82.32",
            "CENT": "4.1",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.25",
            "GW1": "1.5",
            "GCE1": "-1.3",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "112",
            "STYLE": "IK96",
            "KAVMIN": "83.26",
            "KAVMAX": "87",
            "AVGK": "84.16",
            "CENT": "4.01",
            "DIAM": "9.6",
            "GPOZ": "5",
            "GBCE": "-1.3",
            "GPC1": "6.2",
            "GW1": "1.5",
            "GCE1": "-1.3",
            "GPC2": "8.2",
            "GW2": "0.4",
            "GCE2": "-0.6",
            "GPC3": "9.8",
            "GW3": "0.4"
        },
        {
            "ID": "113",
            "STYLE": "IKGLOB",
            "KAVMIN": "44",
            "KAVMAX": "45",
            "AVGK": "44.5",
            "CENT": "7.6",
            "DIAM": "10.8",
            "GPOZ": "8.4",
            "GBCE": "-0.25",
            "GPC1": "7.8",
            "GW1": "0.5",
            "GCE1": "-0.25",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "114",
            "STYLE": "IKGLOB",
            "KAVMIN": "45",
            "KAVMAX": "46.25",
            "AVGK": "45.62",
            "CENT": "7.4",
            "DIAM": "10.8",
            "GPOZ": "8.4",
            "GBCE": "-0.28",
            "GPC1": "7.7",
            "GW1": "0.5",
            "GCE1": "-0.28",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "115",
            "STYLE": "IKGLOB",
            "KAVMIN": "46.25",
            "KAVMAX": "47.5",
            "AVGK": "46.87",
            "CENT": "7.2",
            "DIAM": "10.8",
            "GPOZ": "8.2",
            "GBCE": "-0.3",
            "GPC1": "7.6",
            "GW1": "0.6",
            "GCE1": "-0.3",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "116",
            "STYLE": "IKGLOB",
            "KAVMIN": "47.5",
            "KAVMAX": "49",
            "AVGK": "48.25",
            "CENT": "7",
            "DIAM": "10.8",
            "GPOZ": "8",
            "GBCE": "-0.33",
            "GPC1": "7.5",
            "GW1": "0.7",
            "GCE1": "-0.33",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "117",
            "STYLE": "IKGLOB",
            "KAVMIN": "49",
            "KAVMAX": "50.37",
            "AVGK": "49.62",
            "CENT": "6.8",
            "DIAM": "10.8",
            "GPOZ": "7.8",
            "GBCE": "-0.35",
            "GPC1": "7.4",
            "GW1": "0.8",
            "GCE1": "-0.35",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "118",
            "STYLE": "IKGLOB",
            "KAVMIN": "50.37",
            "KAVMAX": "51.87",
            "AVGK": "51.12",
            "CENT": "6.6",
            "DIAM": "10.8",
            "GPOZ": "7.6",
            "GBCE": "-0.38",
            "GPC1": "7.3",
            "GW1": "0.9",
            "GCE1": "-0.38",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "119",
            "STYLE": "IKGLOB",
            "KAVMIN": "51.87",
            "KAVMAX": "53.62",
            "AVGK": "52.75",
            "CENT": "6.4",
            "DIAM": "10.8",
            "GPOZ": "7.4",
            "GBCE": "-0.4",
            "GPC1": "7.2",
            "GW1": "1",
            "GCE1": "-0.4",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "120",
            "STYLE": "IKGLOB",
            "KAVMIN": "53.62",
            "KAVMAX": "55.37",
            "AVGK": "54.5",
            "CENT": "6.2",
            "DIAM": "10.8",
            "GPOZ": "7.2",
            "GBCE": "-0.43",
            "GPC1": "7.1",
            "GW1": "1.1",
            "GCE1": "-0.43",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "121",
            "STYLE": "IKGLOB",
            "KAVMIN": "55.37",
            "KAVMAX": "57.12",
            "AVGK": "56.25",
            "CENT": "6",
            "DIAM": "10.8",
            "GPOZ": "7",
            "GBCE": "-0.45",
            "GPC1": "7",
            "GW1": "1.2",
            "GCE1": "-0.45",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "122",
            "STYLE": "IKGLOB",
            "KAVMIN": "57.12",
            "KAVMAX": "59.37",
            "AVGK": "58.25",
            "CENT": "5.8",
            "DIAM": "10.8",
            "GPOZ": "6.8",
            "GBCE": "-0.48",
            "GPC1": "6.9",
            "GW1": "1.3",
            "GCE1": "-0.48",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "123",
            "STYLE": "IKGLOB",
            "KAVMIN": "59.37",
            "KAVMAX": "61.12",
            "AVGK": "60.25",
            "CENT": "5.6",
            "DIAM": "10.8",
            "GPOZ": "6.6",
            "GBCE": "-0.5",
            "GPC1": "6.8",
            "GW1": "1.4",
            "GCE1": "-0.5",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "124",
            "STYLE": "IKGLOB",
            "KAVMIN": "61.12",
            "KAVMAX": "63.87",
            "AVGK": "62.5",
            "CENT": "5.4",
            "DIAM": "10.8",
            "GPOZ": "6.4",
            "GBCE": "-0.5",
            "GPC1": "6.7",
            "GW1": "1.5",
            "GCE1": "-0.5",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "125",
            "STYLE": "IKGLOB",
            "KAVMIN": "63.87",
            "KAVMAX": "65.87",
            "AVGK": "64.87",
            "CENT": "5.2",
            "DIAM": "10.8",
            "GPOZ": "6.2",
            "GBCE": "-0.55",
            "GPC1": "6.6",
            "GW1": "1.6",
            "GCE1": "-0.55",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "126",
            "STYLE": "IKGLOB",
            "KAVMIN": "65.87",
            "KAVMAX": "69.12",
            "AVGK": "67.5",
            "CENT": "5",
            "DIAM": "10.8",
            "GPOZ": "6",
            "GBCE": "-0.6",
            "GPC1": "6.5",
            "GW1": "1.7",
            "GCE1": "-0.6",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "-0.6",
            "GPC3": "10.6",
            "GW3": "0.4"
        },
        {
            "ID": "127",
            "STYLE": "SOPER",
            "AVGK": "44",
            "CENT": "7.67",
            "DIAM": "7.5",
            "GPOZ": "6",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "128",
            "STYLE": "SOPER",
            "AVGK": "45",
            "CENT": "7.5",
            "DIAM": "7.5",
            "GPOZ": "6",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "129",
            "STYLE": "SOPER",
            "AVGK": "46",
            "CENT": "7.34",
            "DIAM": "7.5",
            "GPOZ": "6",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "130",
            "STYLE": "SOPER",
            "AVGK": "47",
            "CENT": "7.18",
            "DIAM": "7.5",
            "GPOZ": "7",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "131",
            "STYLE": "SOPER",
            "AVGK": "49",
            "CENT": "6.89",
            "DIAM": "8.5",
            "GPOZ": "7",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "132",
            "STYLE": "SOPER",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "8.5",
            "GPOZ": "7",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "133",
            "STYLE": "SOPER",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "8.5",
            "GPOZ": "8",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "134",
            "STYLE": "SOPER",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "9.5",
            "GPOZ": "8",
            "GBCE": "0",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "135",
            "STYLE": "SOPER",
            "AVGK": "57",
            "CENT": "5.92",
            "DIAM": "9.5",
            "GPOZ": "8",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "136",
            "STYLE": "SOPER",
            "AVGK": "59",
            "CENT": "5.72",
            "DIAM": "9.5",
            "GPOZ": "8",
            "GPC1": "7.5",
            "GW1": "0.25",
            "GPC2": "8.44",
            "GW2": "0.1",
            "GPC3": "9.12",
            "GW3": "0.2",
            "GPC4": "13",
            "GW4": "0.2"
        },
        {
            "ID": "137",
            "STYLE": "STD",
            "KAVMIN": "49.5",
            "KAVMAX": "50.5",
            "AVGK": "50",
            "CENT": "6.75",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "7.25",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.25",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.75",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.75",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "138",
            "STYLE": "STD",
            "KAVMIN": "49.5",
            "KAVMAX": "50.5",
            "AVGK": "50",
            "CENT": "6.75",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "7.25",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.25",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.75",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.75",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "139",
            "STYLE": "STD",
            "KAVMIN": "49.5",
            "KAVMAX": "50.5",
            "AVGK": "50",
            "CENT": "6.75",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "7.25",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.25",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.75",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.75",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "140",
            "STYLE": "STD",
            "KAVMIN": "50.5",
            "KAVMAX": "51.5",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "7.1",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.6",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.6",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "141",
            "STYLE": "STD",
            "KAVMIN": "50.5",
            "KAVMAX": "51.5",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "7.1",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.6",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.6",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "142",
            "STYLE": "STD",
            "KAVMIN": "50.5",
            "KAVMAX": "51.5",
            "AVGK": "51",
            "CENT": "6.62",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "7.1",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8.1",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.6",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.6",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "143",
            "STYLE": "STD",
            "KAVMIN": "51.5",
            "KAVMAX": "52.5",
            "AVGK": "52",
            "CENT": "6.49",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "7",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.5",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.5",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "144",
            "STYLE": "STD",
            "KAVMIN": "51.5",
            "KAVMAX": "52.5",
            "AVGK": "52",
            "CENT": "6.49",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "7",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.5",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.5",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "145",
            "STYLE": "STD",
            "KAVMIN": "51.5",
            "KAVMAX": "52.5",
            "AVGK": "52",
            "CENT": "6.49",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "7",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "8",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.5",
            "GW3": "0.3",
            "GPC4": "11.5",
            "GW4": "0.4"
        },
        {
            "ID": "146",
            "STYLE": "STD",
            "KAVMIN": "52.5",
            "KAVMAX": "53.5",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "6.85",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.85",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.35",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.35",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "147",
            "STYLE": "STD",
            "KAVMIN": "52.5",
            "KAVMAX": "53.5",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.85",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.85",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.35",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.35",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "148",
            "STYLE": "STD",
            "KAVMIN": "52.5",
            "KAVMAX": "53.5",
            "AVGK": "53",
            "CENT": "6.37",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "6.85",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.85",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.35",
            "GW3": "0.3",
            "GPC4": "11.35",
            "GW4": "0.4"
        },
        {
            "ID": "149",
            "STYLE": "STD",
            "KAVMIN": "53.5",
            "KAVMAX": "54.5",
            "AVGK": "54",
            "CENT": "6.25",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "6.75",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.75",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.25",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.25",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "150",
            "STYLE": "STD",
            "KAVMIN": "53.5",
            "KAVMAX": "54.5",
            "AVGK": "54",
            "CENT": "6.25",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.75",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.75",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.25",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.25",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "151",
            "STYLE": "STD",
            "KAVMIN": "53.5",
            "KAVMAX": "54.5",
            "AVGK": "54",
            "CENT": "6.25",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "6.75",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.75",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.25",
            "GW3": "0.3",
            "GPC4": "11.25",
            "GW4": "0.4"
        },
        {
            "ID": "152",
            "STYLE": "STD",
            "KAVMIN": "54.5",
            "KAVMAX": "55.5",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "8.6",
            "GPOZ": "6",
            "GBCE": "0",
            "GPC1": "6.65",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.65",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.15",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.15",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "153",
            "STYLE": "STD",
            "KAVMIN": "54.5",
            "KAVMAX": "55.5",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.65",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.65",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.15",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11.15",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "154",
            "STYLE": "STD",
            "KAVMIN": "54.5",
            "KAVMAX": "55.5",
            "AVGK": "55",
            "CENT": "6.14",
            "DIAM": "9.6",
            "GPOZ": "7",
            "GBCE": "0",
            "GPC1": "6.65",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.65",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9.15",
            "GW3": "0.3",
            "GPC4": "11.15",
            "GW4": "0.4"
        },
        {
            "ID": "155",
            "STYLE": "STD",
            "KAVMIN": "55.5",
            "KAVMAX": "56.5",
            "AVGK": "56",
            "CENT": "6.03",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.5",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.5",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "9",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "11",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "156",
            "STYLE": "STD",
            "KAVMIN": "56.5",
            "KAVMAX": "57.5",
            "AVGK": "57",
            "CENT": "5.92",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.4",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.4",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "8.9",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "10.9",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "157",
            "STYLE": "STD",
            "KAVMIN": "57.5",
            "KAVMAX": "58.5",
            "AVGK": "58",
            "CENT": "5.82",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.3",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.3",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "8.8",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "10.8",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "158",
            "STYLE": "STD",
            "KAVMIN": "58.5",
            "KAVMAX": "59.5",
            "AVGK": "59",
            "CENT": "5.72",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.2",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.2",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "8.7",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "10.7",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        },
        {
            "ID": "159",
            "STYLE": "STD",
            "KAVMIN": "59.5",
            "KAVMAX": "60.5",
            "AVGK": "60",
            "CENT": "5.63",
            "DIAM": "9.1",
            "GPOZ": "6.5",
            "GBCE": "0",
            "GPC1": "6.1",
            "GW1": "0.3",
            "GCE1": "0",
            "GPC2": "7.1",
            "GW2": "0.3",
            "GCE2": "0",
            "GPC3": "8.6",
            "GW3": "0.3",
            "GCE3": "0",
            "GPC4": "10.6",
            "GW4": "0.4",
            "GCE4": "0",
            "GCE5": "0"
        }
    ];



    this.PowerDB = [
        {
            "SPHPWR": "-40",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.63",
            "JTRTHN": "0.58",
            "S_SAC": "0.5",
            "S_ACM": "-0.5",
            "S_AOZ": "6.6",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.5",
            "R_ACM": "-0.5",
            "R_AOZ": "6.6",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.44",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-39",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.61",
            "JTRTHN": "0.57",
            "S_SAC": "0.5",
            "S_ACM": "-0.5",
            "S_AOZ": "6.6",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.5",
            "R_ACM": "-0.5",
            "R_AOZ": "6.6",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.45",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-38",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.62",
            "JTRTHN": "0.57",
            "S_SAC": "0.5",
            "S_ACM": "-0.5",
            "S_AOZ": "6.7",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.5",
            "R_ACM": "-0.5",
            "R_AOZ": "6.7",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.46",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-37",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.6",
            "JTRTHN": "0.56",
            "S_SAC": "0.5",
            "S_ACM": "-0.5",
            "S_AOZ": "6.7",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.5",
            "R_ACM": "-0.5",
            "R_AOZ": "6.7",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.47",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-36",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.61",
            "JTRTHN": "0.56",
            "S_SAC": "0.49",
            "S_ACM": "-0.49",
            "S_AOZ": "6.8",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.49",
            "R_ACM": "-0.49",
            "R_AOZ": "6.8",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.48",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-35",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.59",
            "JTRTHN": "0.55",
            "S_SAC": "0.49",
            "S_ACM": "-0.49",
            "S_AOZ": "6.8",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.49",
            "R_ACM": "-0.49",
            "R_AOZ": "6.8",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.49",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-34",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.59",
            "JTRTHN": "0.55",
            "S_SAC": "0.48",
            "S_ACM": "-0.48",
            "S_AOZ": "6.9",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.48",
            "R_ACM": "-0.48",
            "R_AOZ": "6.9",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.5",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-33",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.58",
            "JTRTHN": "0.54",
            "S_SAC": "0.48",
            "S_ACM": "-0.48",
            "S_AOZ": "6.9",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.48",
            "R_ACM": "-0.48",
            "R_AOZ": "6.9",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.51",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-32",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.58",
            "JTRTHN": "0.54",
            "S_SAC": "0.47",
            "S_ACM": "-0.47",
            "S_AOZ": "7",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.47",
            "R_ACM": "-0.47",
            "R_AOZ": "7",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.52",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-31",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.56",
            "JTRTHN": "0.52",
            "S_SAC": "0.46",
            "S_ACM": "-0.46",
            "S_AOZ": "7",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.46",
            "R_ACM": "-0.46",
            "R_AOZ": "7",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.53",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-30",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.55",
            "JTRTHN": "0.51",
            "S_SAC": "0.45",
            "S_ACM": "-0.45",
            "S_AOZ": "7",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.45",
            "R_ACM": "-0.45",
            "R_AOZ": "7",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.54",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-29",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.53",
            "JTRTHN": "0.49",
            "S_SAC": "0.45",
            "S_ACM": "-0.45",
            "S_AOZ": "7",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.45",
            "R_ACM": "-0.45",
            "R_AOZ": "7",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.55",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-28",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.52",
            "JTRTHN": "0.48",
            "S_SAC": "0.44",
            "S_ACM": "-0.44",
            "S_AOZ": "7",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.44",
            "R_ACM": "-0.44",
            "R_AOZ": "7",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.56",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-27",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.52",
            "JTRTHN": "0.48",
            "S_SAC": "0.43",
            "S_ACM": "-0.43",
            "S_AOZ": "7.1",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.43",
            "R_ACM": "-0.43",
            "R_AOZ": "7.1",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.57",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-26",
            "R_UR1": "0.25",
            "R_UR2": "0.25",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.5",
            "JTRTHN": "0.46",
            "S_SAC": "0.42",
            "S_ACM": "-0.42",
            "S_AOZ": "7.1",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.42",
            "R_ACM": "-0.42",
            "R_AOZ": "7.1",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.58",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-25",
            "R_UR1": "0.25",
            "R_UR2": "0.3",
            "R_UR3": "0.23",
            "R_JUP": "0.25",
            "JTTHN": "0.49",
            "JTRTHN": "0.45",
            "S_SAC": "0.42",
            "S_ACM": "-0.42",
            "S_AOZ": "7.1",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.42",
            "R_ACM": "-0.42",
            "R_AOZ": "7.1",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.59",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-24",
            "R_UR1": "0.25",
            "R_UR2": "0.3",
            "R_UR3": "0.24",
            "R_JUP": "0.25",
            "JTTHN": "0.47",
            "JTRTHN": "0.43",
            "S_SAC": "0.41",
            "S_ACM": "-0.41",
            "S_AOZ": "7.1",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.41",
            "R_ACM": "-0.41",
            "R_AOZ": "7.1",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.6",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-23",
            "R_UR1": "0.25",
            "R_UR2": "0.3",
            "R_UR3": "0.24",
            "R_JUP": "0.25",
            "JTTHN": "0.45",
            "JTRTHN": "0.42",
            "S_SAC": "0.41",
            "S_ACM": "-0.41",
            "S_AOZ": "7.1",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.41",
            "R_ACM": "-0.41",
            "R_AOZ": "7.1",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.61",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-22",
            "R_UR1": "0.25",
            "R_UR2": "0.3",
            "R_UR3": "0.24",
            "R_JUP": "0.25",
            "JTTHN": "0.45",
            "JTRTHN": "0.41",
            "S_SAC": "0.4",
            "S_ACM": "-0.4",
            "S_AOZ": "7.2",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.4",
            "R_ACM": "-0.4",
            "R_AOZ": "7.2",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.62",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-21",
            "R_UR1": "0.25",
            "R_UR2": "0.3",
            "R_UR3": "0.24",
            "R_JUP": "0.25",
            "JTTHN": "0.43",
            "JTRTHN": "0.4",
            "S_SAC": "0.39",
            "S_ACM": "-0.39",
            "S_AOZ": "7.2",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.39",
            "R_ACM": "-0.39",
            "R_AOZ": "7.2",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.63",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-20",
            "R_UR1": "0.25",
            "R_UR2": "0.3",
            "R_UR3": "0.24",
            "R_JUP": "0.25",
            "JTTHN": "0.42",
            "JTRTHN": "0.38",
            "S_SAC": "0.38",
            "S_ACM": "-0.38",
            "S_AOZ": "7.2",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.38",
            "R_ACM": "-0.38",
            "R_AOZ": "7.2",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.64",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-19",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.24",
            "R_JUP": "0.25",
            "JTTHN": "0.4",
            "JTRTHN": "0.36",
            "S_SAC": "0.37",
            "S_ACM": "-0.37",
            "S_AOZ": "7.2",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.37",
            "R_ACM": "-0.37",
            "R_AOZ": "7.2",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.65",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-18",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.24",
            "R_JUP": "0.25",
            "JTTHN": "0.39",
            "JTRTHN": "0.36",
            "S_SAC": "0.36",
            "S_ACM": "-0.36",
            "S_AOZ": "7.3",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.36",
            "R_ACM": "-0.36",
            "R_AOZ": "7.3",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.65",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-17",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.24",
            "R_JUP": "0.25",
            "JTTHN": "0.38",
            "JTRTHN": "0.34",
            "S_SAC": "0.35",
            "S_ACM": "-0.35",
            "S_AOZ": "7.3",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.35",
            "R_ACM": "-0.35",
            "R_AOZ": "7.3",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.65",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-16",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.24",
            "R_JUP": "0.25",
            "JTTHN": "0.37",
            "JTRTHN": "0.33",
            "S_SAC": "0.34",
            "S_ACM": "-0.34",
            "S_AOZ": "7.4",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.34",
            "R_ACM": "-0.34",
            "R_AOZ": "7.4",
            "R_STD": "0.08",
            "R_THN": "0.04",
            "R_THK": "0.14",
            "S_KON": "0.4",
            "SKON2": "0.66",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-15",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.25",
            "R_JUP": "0.25",
            "JTTHN": "0.35",
            "JTRTHN": "0.33",
            "S_SAC": "0.33",
            "S_ACM": "-0.33",
            "S_AOZ": "7.4",
            "S_STD": "0.1",
            "S_THN": "0.07",
            "S_THK": "0.17",
            "R_SAC": "0.33",
            "R_ACM": "-0.33",
            "R_AOZ": "7.4",
            "R_STD": "0.09",
            "R_THN": "0.05",
            "R_THK": "0.15",
            "S_KON": "0.4",
            "SKON2": "0.66",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-14",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.25",
            "R_JUP": "0.25",
            "JTTHN": "0.34",
            "JTRTHN": "0.32",
            "S_SAC": "0.31",
            "S_ACM": "-0.31",
            "S_AOZ": "7.5",
            "S_STD": "0.11",
            "S_THN": "0.07",
            "S_THK": "0.18",
            "R_SAC": "0.31",
            "R_ACM": "-0.31",
            "R_AOZ": "7.5",
            "R_STD": "0.09",
            "R_THN": "0.05",
            "R_THK": "0.15",
            "S_KON": "0.4",
            "SKON2": "0.66",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-13",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.25",
            "R_JUP": "0.25",
            "JTTHN": "0.32",
            "JTRTHN": "0.31",
            "S_SAC": "0.29",
            "S_ACM": "-0.29",
            "S_AOZ": "7.5",
            "S_STD": "0.11",
            "S_THN": "0.07",
            "S_THK": "0.18",
            "R_SAC": "0.29",
            "R_ACM": "-0.29",
            "R_AOZ": "7.5",
            "R_STD": "0.1",
            "R_THN": "0.06",
            "R_THK": "0.16",
            "S_KON": "0.4",
            "SKON2": "0.67",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-12",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.25",
            "R_JUP": "0.25",
            "JTTHN": "0.32",
            "JTRTHN": "0.3",
            "S_SAC": "0.28",
            "S_ACM": "-0.28",
            "S_AOZ": "7.6",
            "S_STD": "0.12",
            "S_THN": "0.08",
            "S_THK": "0.19",
            "R_SAC": "0.28",
            "R_ACM": "-0.28",
            "R_AOZ": "7.6",
            "R_STD": "0.1",
            "R_THN": "0.06",
            "R_THK": "0.16",
            "S_KON": "0.4",
            "SKON2": "0.67",
            "SKON3": "0.4",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-11",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.25",
            "R_JUP": "0.25",
            "JTTHN": "0.3",
            "JTRTHN": "0.29",
            "S_SAC": "0.26",
            "S_ACM": "-0.26",
            "S_AOZ": "7.6",
            "S_STD": "0.12",
            "S_THN": "0.08",
            "S_THK": "0.19",
            "R_SAC": "0.26",
            "R_ACM": "-0.26",
            "R_AOZ": "7.6",
            "R_STD": "0.11",
            "R_THN": "0.07",
            "R_THK": "0.17",
            "S_KON": "0.41",
            "SKON2": "0.68",
            "SKON3": "0.41",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-10",
            "R_UR1": "0.25",
            "R_UR2": "0.35",
            "R_UR3": "0.25",
            "R_JUP": "0.25",
            "JTTHN": "0.29",
            "JTRTHN": "0.28",
            "S_SAC": "0.25",
            "S_ACM": "-0.25",
            "S_AOZ": "7.7",
            "S_STD": "0.12",
            "S_THN": "0.08",
            "S_THK": "0.19",
            "R_SAC": "0.25",
            "R_ACM": "-0.25",
            "R_AOZ": "7.7",
            "R_STD": "0.11",
            "R_THN": "0.07",
            "R_THK": "0.17",
            "S_KON": "0.41",
            "SKON2": "0.68",
            "SKON3": "0.41",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-9",
            "R_UR1": "0.28",
            "R_UR2": "0.4",
            "R_UR3": "0.25",
            "R_JUP": "0.28",
            "JTTHN": "0.28",
            "JTRTHN": "0.27",
            "S_SAC": "0.23",
            "S_ACM": "-0.23",
            "S_AOZ": "7.7",
            "S_STD": "0.13",
            "S_THN": "0.09",
            "S_THK": "0.2",
            "R_SAC": "0.23",
            "R_ACM": "-0.23",
            "R_AOZ": "7.7",
            "R_STD": "0.12",
            "R_THN": "0.08",
            "R_THK": "0.18",
            "S_KON": "0.42",
            "SKON2": "0.69",
            "SKON3": "0.42",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-8",
            "R_UR1": "0.28",
            "R_UR2": "0.4",
            "R_UR3": "0.25",
            "R_JUP": "0.28",
            "JTTHN": "0.26",
            "JTRTHN": "0.25",
            "S_SAC": "0.22",
            "S_ACM": "-0.22",
            "S_AOZ": "7.8",
            "S_STD": "0.13",
            "S_THN": "0.09",
            "S_THK": "0.2",
            "R_SAC": "0.22",
            "R_ACM": "-0.22",
            "R_AOZ": "7.8",
            "R_STD": "0.12",
            "R_THN": "0.08",
            "R_THK": "0.18",
            "OK": "0.15",
            "S_KON": "0.42",
            "SKON2": "0.69",
            "SKON3": "0.42",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-7",
            "R_UR1": "0.28",
            "R_UR2": "0.4",
            "R_UR3": "0.25",
            "R_JUP": "0.28",
            "JTTHN": "0.25",
            "JTRTHN": "0.24",
            "S_SAC": "0.2",
            "S_ACM": "-0.2",
            "S_AOZ": "7.9",
            "S_STD": "0.13",
            "S_THN": "0.09",
            "S_THK": "0.2",
            "R_SAC": "0.2",
            "R_ACM": "-0.2",
            "R_AOZ": "7.9",
            "R_STD": "0.12",
            "R_THN": "0.08",
            "R_THK": "0.18",
            "OK": "0.15",
            "S_KON": "0.42",
            "SKON2": "0.69",
            "SKON3": "0.42",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-6",
            "R_UR1": "0.28",
            "R_UR2": "0.4",
            "R_UR3": "0.25",
            "R_JUP": "0.28",
            "JTTHN": "0.24",
            "JTRTHN": "0.23",
            "S_SAC": "0.18",
            "S_ACM": "-0.18",
            "S_AOZ": "7.9",
            "S_STD": "0.14",
            "S_THN": "0.1",
            "S_THK": "0.21",
            "R_SAC": "0.18",
            "R_ACM": "-0.18",
            "R_AOZ": "7.9",
            "R_STD": "0.13",
            "R_THN": "0.09",
            "R_THK": "0.19",
            "OK": "0.16",
            "S_KON": "0.43",
            "SKON2": "0.7",
            "SKON3": "0.43",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-5",
            "R_UR1": "0.28",
            "R_UR2": "0.4",
            "R_UR3": "0.25",
            "R_JUP": "0.28",
            "JTTHN": "0.23",
            "JTRTHN": "0.22",
            "S_SAC": "0.17",
            "S_ACM": "-0.17",
            "S_AOZ": "8",
            "S_STD": "0.15",
            "S_THN": "0.11",
            "S_THK": "0.22",
            "R_SAC": "0.17",
            "R_ACM": "-0.17",
            "R_AOZ": "8",
            "R_STD": "0.14",
            "R_THN": "0.1",
            "R_THK": "0.2",
            "OK": "0.17",
            "S_KON": "0.44",
            "SKON2": "0.71",
            "SKON3": "0.44",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-4",
            "R_UR1": "0.3",
            "R_UR2": "0.43",
            "R_UR3": "0.25",
            "R_JUP": "0.3",
            "JTTHN": "0.22",
            "JTRTHN": "0.21",
            "S_SAC": "0.16",
            "S_ACM": "-0.16",
            "S_AOZ": "8",
            "S_STD": "0.16",
            "S_THN": "0.12",
            "S_THK": "0.23",
            "R_SAC": "0.16",
            "R_ACM": "-0.16",
            "R_AOZ": "8",
            "R_STD": "0.15",
            "R_THN": "0.11",
            "R_THK": "0.21",
            "OK": "0.18",
            "S_KON": "0.45",
            "SKON2": "0.72",
            "SKON3": "0.45",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-3",
            "R_UR1": "0.3",
            "R_UR2": "0.43",
            "R_UR3": "0.25",
            "R_JUP": "0.3",
            "JTTHN": "0.22",
            "JTRTHN": "0.2",
            "S_SAC": "0.15",
            "S_ACM": "-0.15",
            "S_AOZ": "8.1",
            "S_STD": "0.18",
            "S_THN": "0.14",
            "S_THK": "0.25",
            "R_SAC": "0.15",
            "R_ACM": "-0.15",
            "R_AOZ": "8.1",
            "R_STD": "0.16",
            "R_THN": "0.12",
            "R_THK": "0.22",
            "OK": "0.19",
            "S_KON": "0.46",
            "SKON2": "0.73",
            "SKON3": "0.46",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-2",
            "R_UR1": "0.3",
            "R_UR2": "0.43",
            "R_UR3": "0.25",
            "R_JUP": "0.3",
            "JTTHN": "0.21",
            "JTRTHN": "0.18",
            "S_SAC": "0.1",
            "S_ACM": "-0.1",
            "S_AOZ": "8.1",
            "S_STD": "0.2",
            "S_THN": "0.16",
            "S_THK": "0.27",
            "R_SAC": "0.1",
            "R_ACM": "-0.1",
            "R_AOZ": "8.1",
            "R_STD": "0.17",
            "R_THN": "0.13",
            "R_THK": "0.23",
            "OK": "0.2",
            "S_KON": "0.47",
            "SKON2": "0.74",
            "SKON3": "0.47",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "-1",
            "R_UR1": "0.3",
            "R_UR2": "0.43",
            "R_UR3": "0.25",
            "R_JUP": "0.3",
            "JTTHN": "0.2",
            "JTRTHN": "0.17",
            "S_SAC": "0.04",
            "S_ACM": "-0.04",
            "S_AOZ": "8.2",
            "S_STD": "0.21",
            "S_THN": "0.17",
            "S_THK": "0.28",
            "R_SAC": "0.04",
            "R_ACM": "-0.04",
            "R_AOZ": "8.2",
            "R_STD": "0.18",
            "R_THN": "0.14",
            "R_THK": "0.24",
            "OK": "0.21",
            "S_KON": "0.48",
            "SKON2": "0.75",
            "SKON3": "0.48",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "0",
            "R_UR1": "0.3",
            "R_UR2": "0.43",
            "R_UR3": "0.25",
            "R_JUP": "0.3",
            "JTTHN": "0.2",
            "JTRTHN": "0.16",
            "S_SAC": "0",
            "S_ACM": "0",
            "S_AOZ": "8.2",
            "S_STD": "0.23",
            "S_THN": "0.19",
            "S_THK": "0.3",
            "R_SAC": "0",
            "R_ACM": "0",
            "R_AOZ": "8.2",
            "R_STD": "0.19",
            "R_THN": "0.15",
            "R_THK": "0.25",
            "OK": "0.22",
            "S_KON": "0.49",
            "SKON2": "0.76",
            "SKON3": "0.49",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "1",
            "R_UR1": "0.31",
            "R_UR2": "0.45",
            "R_UR3": "0.27",
            "R_JUP": "0.31",
            "JTTHN": "0.18",
            "JTRTHN": "0.17",
            "S_SAC": "-0.04",
            "S_ACM": "0.04",
            "S_AOZ": "8.2",
            "S_STD": "0.24",
            "S_THN": "0.2",
            "S_THK": "0.31",
            "R_SAC": "-0.04",
            "R_ACM": "0.04",
            "R_AOZ": "8.2",
            "R_STD": "0.22",
            "R_THN": "0.18",
            "R_THK": "0.28",
            "OK": "0.22",
            "S_KON": "0.5",
            "SKON2": "0.77",
            "SKON3": "0.5",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "2",
            "R_UR1": "0.32",
            "R_UR2": "0.47",
            "R_UR3": "0.27",
            "R_JUP": "0.32",
            "JTTHN": "0.16",
            "JTRTHN": "0.15",
            "S_SAC": "-0.1",
            "S_ACM": "0.1",
            "S_AOZ": "8.1",
            "S_STD": "0.24",
            "S_THN": "0.2",
            "S_THK": "0.31",
            "R_SAC": "-0.1",
            "R_ACM": "0.1",
            "R_AOZ": "8.1",
            "R_STD": "0.23",
            "R_THN": "0.19",
            "R_THK": "0.29",
            "OK": "0.23",
            "S_KON": "0.51",
            "SKON2": "0.78",
            "SKON3": "0.51",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "3",
            "R_UR1": "0.33",
            "R_UR2": "0.49",
            "R_UR3": "0.29",
            "R_JUP": "0.33",
            "JTTHN": "0.14",
            "JTRTHN": "0.14",
            "S_SAC": "-0.15",
            "S_ACM": "0.15",
            "S_AOZ": "8.1",
            "S_STD": "0.24",
            "S_THN": "0.2",
            "S_THK": "0.31",
            "R_SAC": "-0.15",
            "R_ACM": "0.15",
            "R_AOZ": "8.1",
            "R_STD": "0.24",
            "R_THN": "0.2",
            "R_THK": "0.3",
            "OK": "0.24",
            "S_KON": "0.52",
            "SKON2": "0.79",
            "SKON3": "0.52",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "4",
            "R_UR1": "0.34",
            "R_UR2": "0.51",
            "R_UR3": "0.29",
            "R_JUP": "0.34",
            "JTTHN": "0.14",
            "JTRTHN": "0.14",
            "S_SAC": "-0.16",
            "S_ACM": "0.16",
            "S_AOZ": "8",
            "S_STD": "0.26",
            "S_THN": "0.22",
            "S_THK": "0.33",
            "R_SAC": "-0.16",
            "R_ACM": "0.16",
            "R_AOZ": "8",
            "R_STD": "0.26",
            "R_THN": "0.22",
            "R_THK": "0.32",
            "OK": "0.26",
            "S_KON": "0.53",
            "SKON2": "0.8",
            "SKON3": "0.53",
            "S_KSH": "0.39"
        },
        {
            "SPHPWR": "5",
            "R_UR1": "0.35",
            "R_UR2": "0.53",
            "R_UR3": "0.3",
            "R_JUP": "0.35",
            "JTTHN": "0.13",
            "JTRTHN": "0.13",
            "S_SAC": "-0.17",
            "S_ACM": "0.17",
            "S_AOZ": "8",
            "S_STD": "0.28",
            "S_THN": "0.24",
            "S_THK": "0.35",
            "R_SAC": "-0.17",
            "R_ACM": "0.17",
            "R_AOZ": "8",
            "R_STD": "0.27",
            "R_THN": "0.23",
            "R_THK": "0.33",
            "OK": "0.28",
            "S_KON": "0.54",
            "SKON2": "0.81",
            "SKON3": "0.54",
            "S_KSH": "0.4"
        },
        {
            "SPHPWR": "6",
            "R_UR1": "0.42",
            "R_UR2": "0.55",
            "R_UR3": "0.3",
            "R_JUP": "0.42",
            "JTTHN": "0.13",
            "JTRTHN": "0.13",
            "S_SAC": "-0.18",
            "S_ACM": "0.18",
            "S_AOZ": "7.9",
            "S_STD": "0.3",
            "S_THN": "0.26",
            "S_THK": "0.37",
            "R_SAC": "-0.18",
            "R_ACM": "0.18",
            "R_AOZ": "7.9",
            "R_STD": "0.29",
            "R_THN": "0.25",
            "R_THK": "0.35",
            "OK": "0.3",
            "S_KON": "0.55",
            "SKON2": "0.82",
            "SKON3": "0.55",
            "S_KSH": "0.41"
        },
        {
            "SPHPWR": "7",
            "R_UR1": "0.44",
            "R_UR2": "0.57",
            "R_UR3": "0.32",
            "R_JUP": "0.44",
            "JTTHN": "0.13",
            "JTRTHN": "0.11",
            "S_SAC": "-0.2",
            "S_ACM": "0.2",
            "S_AOZ": "7.9",
            "S_STD": "0.32",
            "S_THN": "0.28",
            "S_THK": "0.39",
            "R_SAC": "-0.2",
            "R_ACM": "0.2",
            "R_AOZ": "7.9",
            "R_STD": "0.3",
            "R_THN": "0.26",
            "R_THK": "0.36",
            "OK": "0.32",
            "S_KON": "0.56",
            "SKON2": "0.83",
            "SKON3": "0.56",
            "S_KSH": "0.41"
        },
        {
            "SPHPWR": "8",
            "R_UR1": "0.46",
            "R_UR2": "0.59",
            "R_UR3": "0.32",
            "R_JUP": "0.46",
            "JTTHN": "0.12",
            "JTRTHN": "0.11",
            "S_SAC": "-0.22",
            "S_ACM": "0.22",
            "S_AOZ": "7.8",
            "S_STD": "0.33",
            "S_THN": "0.29",
            "S_THK": "0.4",
            "R_SAC": "-0.22",
            "R_ACM": "0.22",
            "R_AOZ": "7.8",
            "R_STD": "0.31",
            "R_THN": "0.27",
            "R_THK": "0.37",
            "S_KON": "0.57",
            "SKON2": "0.84",
            "SKON3": "0.57",
            "S_KSH": "0.42"
        },
        {
            "SPHPWR": "9",
            "R_UR1": "0.48",
            "R_UR2": "0.61",
            "R_UR3": "0.34",
            "R_JUP": "0.48",
            "JTTHN": "0.12",
            "JTRTHN": "0.1",
            "S_SAC": "-0.23",
            "S_ACM": "0.23",
            "S_AOZ": "7.7",
            "S_STD": "0.34",
            "S_THN": "0.3",
            "S_THK": "0.41",
            "R_SAC": "-0.23",
            "R_ACM": "0.23",
            "R_AOZ": "7.7",
            "R_STD": "0.32",
            "R_THN": "0.28",
            "R_THK": "0.38",
            "S_KON": "0.58",
            "SKON2": "0.85",
            "SKON3": "0.58",
            "S_KSH": "0.43"
        },
        {
            "SPHPWR": "10",
            "R_UR1": "0.5",
            "R_UR2": "0.63",
            "R_UR3": "0.34",
            "R_JUP": "0.5",
            "JTTHN": "0.12",
            "JTRTHN": "0.1",
            "S_SAC": "-0.25",
            "S_ACM": "0.25",
            "S_AOZ": "7.7",
            "S_STD": "0.36",
            "S_THN": "0.32",
            "S_THK": "0.43",
            "R_SAC": "-0.25",
            "R_ACM": "0.25",
            "R_AOZ": "7.7",
            "R_STD": "0.34",
            "R_THN": "0.3",
            "R_THK": "0.4",
            "S_KON": "0.59",
            "SKON2": "0.86",
            "SKON3": "0.59",
            "S_KSH": "0.43"
        },
        {
            "SPHPWR": "11",
            "R_UR1": "0.54",
            "R_UR2": "0.65",
            "R_UR3": "0.36",
            "R_JUP": "0.54",
            "JTTHN": "0.12",
            "JTRTHN": "0.1",
            "S_SAC": "-0.26",
            "S_ACM": "0.26",
            "S_AOZ": "7.6",
            "S_STD": "0.38",
            "S_THN": "0.34",
            "S_THK": "0.45",
            "R_SAC": "-0.26",
            "R_ACM": "0.26",
            "R_AOZ": "7.6",
            "R_STD": "0.35",
            "R_THN": "0.31",
            "R_THK": "0.41",
            "S_KON": "0.6",
            "SKON2": "0.87",
            "SKON3": "0.6",
            "S_KSH": "0.44"
        },
        {
            "SPHPWR": "12",
            "R_UR1": "0.56",
            "R_UR2": "0.67",
            "R_UR3": "0.36",
            "R_JUP": "0.56",
            "JTTHN": "0.12",
            "JTRTHN": "0.1",
            "S_SAC": "-0.28",
            "S_ACM": "0.28",
            "S_AOZ": "7.6",
            "S_STD": "0.4",
            "S_THN": "0.36",
            "S_THK": "0.47",
            "R_SAC": "-0.28",
            "R_ACM": "0.28",
            "R_AOZ": "7.6",
            "R_STD": "0.37",
            "R_THN": "0.33",
            "R_THK": "0.43",
            "S_KON": "0.61",
            "SKON2": "0.88",
            "SKON3": "0.61",
            "S_KSH": "0.44"
        },
        {
            "SPHPWR": "13",
            "R_UR1": "0.58",
            "R_UR2": "0.69",
            "R_UR3": "0.38",
            "R_JUP": "0.58",
            "JTTHN": "0.12",
            "JTRTHN": "0.09",
            "S_SAC": "-0.29",
            "S_ACM": "0.29",
            "S_AOZ": "7.5",
            "S_STD": "0.41",
            "S_THN": "0.37",
            "S_THK": "0.48",
            "R_SAC": "-0.29",
            "R_ACM": "0.29",
            "R_AOZ": "7.5",
            "R_STD": "0.38",
            "R_THN": "0.34",
            "R_THK": "0.44",
            "S_KON": "0.63",
            "SKON2": "0.89",
            "SKON3": "0.63",
            "S_KSH": "0.45"
        },
        {
            "SPHPWR": "14",
            "R_UR1": "0.6",
            "R_UR2": "0.71",
            "R_UR3": "0.38",
            "R_JUP": "0.6",
            "JTTHN": "0.12",
            "JTRTHN": "0.09",
            "S_SAC": "-0.31",
            "S_ACM": "0.31",
            "S_AOZ": "7.5",
            "S_STD": "0.43",
            "S_THN": "0.39",
            "S_THK": "0.5",
            "R_SAC": "-0.31",
            "R_ACM": "0.31",
            "R_AOZ": "7.5",
            "R_STD": "0.4",
            "R_THN": "0.36",
            "R_THK": "0.46",
            "S_KON": "0.65",
            "SKON2": "0.9",
            "SKON3": "0.65",
            "S_KSH": "0.45"
        },
        {
            "SPHPWR": "15",
            "R_UR1": "0.64",
            "R_UR2": "0.73",
            "R_UR3": "0.4",
            "R_JUP": "0.64",
            "JTTHN": "0.12",
            "JTRTHN": "0.09",
            "S_SAC": "-0.33",
            "S_ACM": "0.33",
            "S_AOZ": "7.4",
            "S_STD": "0.43",
            "S_THN": "0.4",
            "S_THK": "0.5",
            "R_SAC": "-0.33",
            "R_ACM": "0.33",
            "R_AOZ": "7.4",
            "R_STD": "0.41",
            "R_THN": "0.37",
            "R_THK": "0.47",
            "S_KON": "0.67",
            "SKON2": "0.91",
            "SKON3": "0.67",
            "S_KSH": "0.46"
        },
        {
            "SPHPWR": "16",
            "R_UR1": "0.66",
            "R_UR2": "0.75",
            "R_UR3": "0.4",
            "R_JUP": "0.66",
            "JTTHN": "0.12",
            "JTRTHN": "0.1",
            "S_SAC": "-0.34",
            "S_ACM": "0.34",
            "S_AOZ": "7.4",
            "S_STD": "0.45",
            "S_THN": "0.42",
            "S_THK": "0.52",
            "R_SAC": "-0.34",
            "R_ACM": "0.34",
            "R_AOZ": "7.4",
            "R_STD": "0.44",
            "R_THN": "0.4",
            "R_THK": "0.5",
            "S_KON": "0.69",
            "SKON2": "0.92",
            "SKON3": "0.69",
            "S_KSH": "0.46"
        },
        {
            "SPHPWR": "17",
            "R_UR1": "0.68",
            "R_UR2": "0.76",
            "R_UR3": "0.42",
            "R_JUP": "0.68",
            "JTTHN": "0.12",
            "JTRTHN": "0.1",
            "S_SAC": "-0.35",
            "S_ACM": "0.35",
            "S_AOZ": "7.3",
            "S_STD": "0.46",
            "S_THN": "0.43",
            "S_THK": "0.53",
            "R_SAC": "-0.35",
            "R_ACM": "0.35",
            "R_AOZ": "7.3",
            "R_STD": "0.45",
            "R_THN": "0.41",
            "R_THK": "0.51",
            "S_KON": "0.71",
            "SKON2": "0.93",
            "SKON3": "0.71",
            "S_KSH": "0.47"
        },
        {
            "SPHPWR": "18",
            "R_UR1": "0.7",
            "R_UR2": "0.78",
            "R_UR3": "0.42",
            "R_JUP": "0.7",
            "JTTHN": "0.12",
            "JTRTHN": "0.1",
            "S_SAC": "-0.36",
            "S_ACM": "0.36",
            "S_AOZ": "7.3",
            "S_STD": "0.48",
            "S_THN": "0.45",
            "S_THK": "0.55",
            "R_SAC": "-0.36",
            "R_ACM": "0.36",
            "R_AOZ": "7.3",
            "R_STD": "0.47",
            "R_THN": "0.43",
            "R_THK": "0.53",
            "S_KON": "0.73",
            "SKON2": "0.94",
            "SKON3": "0.73",
            "S_KSH": "0.47"
        },
        {
            "SPHPWR": "19",
            "R_UR1": "0.73",
            "R_UR2": "0.8",
            "R_UR3": "0.44",
            "R_JUP": "0.73",
            "JTTHN": "0.12",
            "JTRTHN": "0.1",
            "S_SAC": "-0.37",
            "S_ACM": "0.37",
            "S_AOZ": "7.2",
            "S_STD": "0.49",
            "S_THN": "0.46",
            "S_THK": "0.56",
            "R_SAC": "-0.37",
            "R_ACM": "0.37",
            "R_AOZ": "7.2",
            "R_STD": "0.47",
            "R_THN": "0.43",
            "R_THK": "0.53",
            "S_KON": "0.75",
            "SKON2": "0.95",
            "SKON3": "0.75",
            "S_KSH": "0.49"
        },
        {
            "SPHPWR": "20",
            "R_UR1": "0.75",
            "R_UR2": "0.82",
            "R_UR3": "0.44",
            "R_JUP": "0.75",
            "JTTHN": "0.11",
            "JTRTHN": "0.1",
            "S_SAC": "-0.38",
            "S_ACM": "0.38",
            "S_AOZ": "7.2",
            "S_STD": "0.5",
            "S_THN": "0.47",
            "S_THK": "0.57",
            "R_SAC": "-0.38",
            "R_ACM": "0.38",
            "R_AOZ": "7.2",
            "R_STD": "0.49",
            "R_THN": "0.45",
            "R_THK": "0.55",
            "S_KON": "0.77",
            "SKON2": "0.96",
            "SKON3": "0.77",
            "S_KSH": "0.49"
        },
        {
            "SPHPWR": "21",
            "R_UR1": "0.77",
            "R_UR2": "0.84",
            "R_UR3": "0.46",
            "R_JUP": "0.77",
            "JTTHN": "0.11",
            "JTRTHN": "0.1",
            "S_SAC": "-0.39",
            "S_ACM": "0.39",
            "S_AOZ": "7.2",
            "S_STD": "0.52",
            "S_THN": "0.49",
            "S_THK": "0.59",
            "R_SAC": "-0.39",
            "R_ACM": "0.39",
            "R_AOZ": "7.2",
            "R_STD": "0.51",
            "R_THN": "0.47",
            "R_THK": "0.57",
            "S_KON": "0.79",
            "SKON2": "0.97",
            "SKON3": "0.79",
            "S_KSH": "0.51"
        },
        {
            "SPHPWR": "22",
            "R_UR1": "0.79",
            "R_UR2": "0.86",
            "R_UR3": "0.46",
            "R_JUP": "0.79",
            "JTTHN": "0.11",
            "JTRTHN": "0.1",
            "S_SAC": "-0.4",
            "S_ACM": "0.4",
            "S_AOZ": "7.2",
            "S_STD": "0.54",
            "S_THN": "0.51",
            "S_THK": "0.61",
            "R_SAC": "-0.4",
            "R_ACM": "0.4",
            "R_AOZ": "7.2",
            "R_STD": "0.53",
            "R_THN": "0.49",
            "R_THK": "0.59",
            "S_KON": "0.79",
            "SKON2": "0.98",
            "SKON3": "0.79",
            "S_KSH": "0.51"
        },
        {
            "SPHPWR": "23",
            "R_UR1": "0.81",
            "R_UR2": "0.88",
            "R_UR3": "0.48",
            "R_JUP": "0.81",
            "JTTHN": "0.11",
            "JTRTHN": "0.09",
            "S_SAC": "-0.41",
            "S_ACM": "0.41",
            "S_AOZ": "7.1",
            "S_STD": "0.54",
            "S_THN": "0.51",
            "S_THK": "0.61",
            "R_SAC": "-0.41",
            "R_ACM": "0.41",
            "R_AOZ": "7.1",
            "R_STD": "0.53",
            "R_THN": "0.49",
            "R_THK": "0.59",
            "S_KON": "0.8",
            "SKON2": "0.99",
            "SKON3": "0.8",
            "S_KSH": "0.53"
        },
        {
            "SPHPWR": "24",
            "R_UR1": "0.83",
            "R_UR2": "0.9",
            "R_UR3": "0.48",
            "R_JUP": "0.83",
            "JTTHN": "0.11",
            "JTRTHN": "0.09",
            "S_SAC": "-0.41",
            "S_ACM": "0.41",
            "S_AOZ": "7.1",
            "S_STD": "0.56",
            "S_THN": "0.53",
            "S_THK": "0.63",
            "R_SAC": "-0.41",
            "R_ACM": "0.41",
            "R_AOZ": "7.1",
            "R_STD": "0.54",
            "R_THN": "0.5",
            "R_THK": "0.6",
            "S_KON": "0.81",
            "SKON2": "0.99",
            "SKON3": "0.81",
            "S_KSH": "0.53"
        },
        {
            "SPHPWR": "25",
            "R_UR1": "0.85",
            "R_UR2": "0.92",
            "R_UR3": "0.48",
            "R_JUP": "0.85",
            "JTTHN": "0.11",
            "JTRTHN": "0.09",
            "S_SAC": "-0.42",
            "S_ACM": "0.42",
            "S_AOZ": "7.1",
            "S_STD": "0.58",
            "S_THN": "0.55",
            "S_THK": "0.65",
            "R_SAC": "-0.42",
            "R_ACM": "0.42",
            "R_AOZ": "7.1",
            "R_STD": "0.56",
            "R_THN": "0.52",
            "R_THK": "0.62",
            "S_KON": "0.82",
            "SKON2": "0.99",
            "SKON3": "0.82",
            "S_KSH": "0.54"
        },
        {
            "SPHPWR": "26",
            "R_UR1": "0.87",
            "R_UR2": "0.94",
            "R_UR3": "0.5",
            "R_JUP": "0.87",
            "JTTHN": "0.11",
            "JTRTHN": "0.09",
            "S_SAC": "-0.42",
            "S_ACM": "0.42",
            "S_AOZ": "7.1",
            "S_STD": "0.6",
            "S_THN": "0.57",
            "S_THK": "0.67",
            "R_SAC": "-0.42",
            "R_ACM": "0.42",
            "R_AOZ": "7.1",
            "R_STD": "0.58",
            "R_THN": "0.54",
            "R_THK": "0.64",
            "S_KON": "0.83",
            "SKON2": "0.99",
            "SKON3": "0.83",
            "S_KSH": "0.54"
        },
        {
            "SPHPWR": "27",
            "R_UR1": "0.89",
            "R_UR2": "0.96",
            "R_UR3": "0.5",
            "R_JUP": "0.89",
            "JTTHN": "0.11",
            "JTRTHN": "0.08",
            "S_SAC": "-0.43",
            "S_ACM": "0.43",
            "S_AOZ": "7.1",
            "S_STD": "0.62",
            "S_THN": "0.59",
            "S_THK": "0.69",
            "R_SAC": "-0.43",
            "R_ACM": "0.43",
            "R_AOZ": "7.1",
            "R_STD": "0.59",
            "R_THN": "0.55",
            "R_THK": "0.65",
            "S_KON": "0.84",
            "SKON2": "0.99",
            "SKON3": "0.84",
            "S_KSH": "0.55"
        },
        {
            "SPHPWR": "28",
            "R_UR1": "0.91",
            "R_UR2": "0.98",
            "R_UR3": "0.5",
            "R_JUP": "0.91",
            "JTTHN": "0.11",
            "JTRTHN": "0.09",
            "S_SAC": "-0.44",
            "S_ACM": "0.44",
            "S_AOZ": "7",
            "S_STD": "0.62",
            "S_THN": "0.59",
            "S_THK": "0.69",
            "R_SAC": "-0.44",
            "R_ACM": "0.44",
            "R_AOZ": "7",
            "R_STD": "0.6",
            "R_THN": "0.56",
            "R_THK": "0.66",
            "S_KON": "0.85",
            "SKON2": "0.99",
            "SKON3": "0.85",
            "S_KSH": "0.55"
        },
        {
            "SPHPWR": "29",
            "R_UR1": "0.93",
            "R_UR2": "1",
            "R_UR3": "0.5",
            "R_JUP": "0.93",
            "JTTHN": "0.11",
            "JTRTHN": "0.08",
            "S_SAC": "-0.45",
            "S_ACM": "0.45",
            "S_AOZ": "7",
            "S_STD": "0.64",
            "S_THN": "0.61",
            "S_THK": "0.71",
            "R_SAC": "-0.45",
            "R_ACM": "0.45",
            "R_AOZ": "7",
            "R_STD": "0.61",
            "R_THN": "0.57",
            "R_THK": "0.67",
            "S_KON": "0.86",
            "SKON2": "0.99",
            "SKON3": "0.86",
            "S_KSH": "0.57"
        },
        {
            "SPHPWR": "30",
            "R_UR1": "0.95",
            "R_UR2": "1.02",
            "R_UR3": "0.5",
            "R_JUP": "0.95",
            "JTTHN": "0.1",
            "JTRTHN": "0.08",
            "S_SAC": "-0.45",
            "S_ACM": "0.45",
            "S_AOZ": "7",
            "S_STD": "0.65",
            "S_THN": "0.62",
            "S_THK": "0.72",
            "R_SAC": "-0.45",
            "R_ACM": "0.45",
            "R_AOZ": "7",
            "R_STD": "0.63",
            "R_THN": "0.59",
            "R_THK": "0.69",
            "S_KON": "0.87",
            "SKON2": "0.99",
            "SKON3": "0.87",
            "S_KSH": "0.57"
        },
        {
            "SPHPWR": "31",
            "R_UR1": "0.97",
            "R_UR2": "1.04",
            "R_UR3": "0.55",
            "R_JUP": "0.97",
            "JTTHN": "0.1",
            "JTRTHN": "0.07",
            "S_SAC": "-0.46",
            "S_ACM": "0.46",
            "S_AOZ": "7",
            "S_STD": "0.67",
            "S_THN": "0.64",
            "S_THK": "0.74",
            "R_SAC": "-0.46",
            "R_ACM": "0.46",
            "R_AOZ": "7",
            "R_STD": "0.64",
            "R_THN": "0.6",
            "R_THK": "0.7",
            "S_KON": "0.87",
            "SKON2": "0.99",
            "SKON3": "0.87",
            "S_KSH": "0.58"
        },
        {
            "SPHPWR": "32",
            "R_UR1": "0.99",
            "R_UR2": "1.06",
            "R_UR3": "0.55",
            "R_JUP": "0.99",
            "JTTHN": "0.09",
            "JTRTHN": "0.07",
            "S_SAC": "-0.47",
            "S_ACM": "0.47",
            "S_AOZ": "7",
            "S_STD": "0.68",
            "S_THN": "0.65",
            "S_THK": "0.75",
            "R_SAC": "-0.47",
            "R_ACM": "0.47",
            "R_AOZ": "7",
            "R_STD": "0.66",
            "R_THN": "0.62",
            "R_THK": "0.72",
            "S_KON": "0.87",
            "SKON2": "0.99",
            "SKON3": "0.87",
            "S_KSH": "0.58"
        },
        {
            "SPHPWR": "33",
            "R_UR1": "1.01",
            "R_UR2": "1.08",
            "R_UR3": "0.55",
            "R_JUP": "1.01",
            "JTTHN": "0.1",
            "JTRTHN": "0.08",
            "S_SAC": "-0.48",
            "S_ACM": "0.48",
            "S_AOZ": "6.9",
            "S_STD": "0.68",
            "S_THN": "0.65",
            "S_THK": "0.75",
            "R_SAC": "-0.48",
            "R_ACM": "0.48",
            "R_AOZ": "6.9",
            "R_STD": "0.66",
            "R_THN": "0.62",
            "R_THK": "0.72",
            "S_KON": "0.88",
            "SKON2": "0.99",
            "SKON3": "0.88",
            "S_KSH": "0.58"
        },
        {
            "SPHPWR": "34",
            "R_UR1": "1.03",
            "R_UR2": "1.1",
            "R_UR3": "0.55",
            "R_JUP": "1.03",
            "JTTHN": "0.09",
            "JTRTHN": "0.07",
            "S_SAC": "-0.48",
            "S_ACM": "0.48",
            "S_AOZ": "6.9",
            "S_STD": "0.69",
            "S_THN": "0.66",
            "S_THK": "0.76",
            "R_SAC": "-0.48",
            "R_ACM": "0.48",
            "R_AOZ": "6.9",
            "R_STD": "0.67",
            "R_THN": "0.63",
            "R_THK": "0.73",
            "S_KON": "0.88",
            "SKON2": "0.99",
            "SKON3": "0.88",
            "S_KSH": "0.58"
        },
        {
            "SPHPWR": "35",
            "R_UR1": "1.05",
            "R_UR2": "1.12",
            "R_UR3": "0.55",
            "R_JUP": "1.05",
            "JTTHN": "0.09",
            "JTRTHN": "0.08",
            "S_SAC": "-0.49",
            "S_ACM": "0.49",
            "S_AOZ": "6.8",
            "S_STD": "0.69",
            "S_THN": "0.66",
            "S_THK": "0.76",
            "R_SAC": "-0.49",
            "R_ACM": "0.49",
            "R_AOZ": "6.8",
            "R_STD": "0.68",
            "R_THN": "0.64",
            "R_THK": "0.74",
            "S_KON": "0.88",
            "SKON2": "0.99",
            "SKON3": "0.88",
            "S_KSH": "0.58"
        },
        {
            "SPHPWR": "36",
            "R_UR1": "1.07",
            "R_UR2": "1.14",
            "R_UR3": "0.6",
            "R_JUP": "1.07",
            "JTTHN": "0.09",
            "JTRTHN": "0.07",
            "S_SAC": "-0.49",
            "S_ACM": "0.49",
            "S_AOZ": "6.8",
            "S_STD": "0.71",
            "S_THN": "0.68",
            "S_THK": "0.78",
            "R_SAC": "-0.49",
            "R_ACM": "0.49",
            "R_AOZ": "6.8",
            "R_STD": "0.69",
            "R_THN": "0.65",
            "R_THK": "0.75",
            "OK": "0",
            "S_KON": "0.88",
            "SKON2": "0.99",
            "SKON3": "0.88",
            "S_KSH": "0.58"
        },
        {
            "SPHPWR": "37",
            "R_UR1": "1.09",
            "R_UR2": "1.16",
            "R_UR3": "0.6",
            "R_JUP": "1.09",
            "JTTHN": "0.09",
            "JTRTHN": "0.08",
            "S_SAC": "-0.5",
            "S_ACM": "0.5",
            "S_AOZ": "6.7",
            "S_STD": "0.71",
            "S_THN": "0.68",
            "S_THK": "0.78",
            "R_SAC": "-0.5",
            "R_ACM": "0.5",
            "R_AOZ": "6.7",
            "R_STD": "0.69",
            "R_THN": "0.65",
            "R_THK": "0.75",
            "S_KON": "0.89",
            "SKON2": "0.99",
            "SKON3": "0.89",
            "S_KSH": "0.58"
        },
        {
            "SPHPWR": "38",
            "R_UR1": "1.11",
            "R_UR2": "1.18",
            "R_UR3": "0.6",
            "R_JUP": "1.11",
            "JTTHN": "0.09",
            "JTRTHN": "0.07",
            "S_SAC": "-0.5",
            "S_ACM": "0.5",
            "S_AOZ": "6.7",
            "S_STD": "0.72",
            "S_THN": "0.69",
            "S_THK": "0.79",
            "R_SAC": "-0.5",
            "R_ACM": "0.5",
            "R_AOZ": "6.7",
            "R_STD": "0.7",
            "R_THN": "0.66",
            "R_THK": "0.76",
            "S_KON": "0.9",
            "SKON2": "0.99",
            "SKON3": "0.9",
            "S_KSH": "0.58"
        },
        {
            "SPHPWR": "39",
            "R_UR1": "1.13",
            "R_UR2": "1.2",
            "R_UR3": "0.6",
            "R_JUP": "1.13",
            "JTTHN": "0.09",
            "JTRTHN": "0.07",
            "S_SAC": "-0.5",
            "S_ACM": "0.5",
            "S_AOZ": "6.6",
            "S_STD": "0.72",
            "S_THN": "0.69",
            "S_THK": "0.79",
            "R_SAC": "-0.5",
            "R_ACM": "0.5",
            "R_AOZ": "6.6",
            "R_STD": "0.7",
            "R_THN": "0.66",
            "R_THK": "0.76",
            "S_KON": "0.91",
            "SKON2": "0.99",
            "SKON3": "0.91",
            "S_KSH": "0.58"
        },
        {
            "SPHPWR": "40",
            "R_UR1": "1.15",
            "R_UR2": "1.22",
            "R_UR3": "0.6",
            "R_JUP": "1.15",
            "JTTHN": "0.09",
            "JTRTHN": "0.07",
            "S_SAC": "-0.5",
            "S_ACM": "0.5",
            "S_AOZ": "6.5",
            "S_STD": "0.71",
            "S_THN": "0.68",
            "S_THK": "0.78",
            "R_SAC": "-0.5",
            "R_ACM": "0.5",
            "R_AOZ": "6.5",
            "R_STD": "0.69",
            "R_THN": "0.65",
            "R_THK": "0.75",
            "S_KON": "0.92",
            "SKON2": "0.99",
            "SKON3": "0.92",
            "S_KSH": "0.58"
        }
    ];





    this.convertBCD = function (bcmm) {
        return Math.round((337.5 / bcmm) * 100) / 100;
    };
    this.convertBCMM = function (bcd) {
        return Math.round((337.5 / bcd) * 1000) / 1000;
    };
    this.returnA3 = function (a3, d1, d3) {
        var value = Math.floor(((a3 == null ? 0 : a3) + (d1 - d3)) * 100) / 100;
        return value;
    };
    this.precisionRound = function (number, precision) {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    };

    //***  STEP 1  (Convert plus cylider to minus cylinder format) ***
    this.finalValue = function (A1, B1, c1, A2, B2, c2, D1, D3) {
        // //
        var obj = {};
        var degr;
        var G, K, M, GM, ORL, KDL, x, y, Hold_cyl, Hold_sCyl, Sph3, Sph4, Sph5, Cyl3, Cyl4, Cyl5;
        var Axis3, Axis4, Axis5;

        var degr = 57.2957795;
        var VD = 12;

        //var obj = {};
        //if (b2 > 0) {
        //    obj.a2 = (a2 == null ? 0 : a2) + b2;
        //    obj.b2 = -Math.abs(b2);
        //    var tempc2 = c2 == null ? 0 : c2;
        //    obj.c2 = tempc2 + 90;
        //    if (obj.c2 >= 180) {
        //        obj.c2 = obj.c2 - 180;
        //    } 
        //}

        //if( A1 == 0 && B1 == 0 && A2 == 0 && B2 == 0 && D3 == 0 ){

        //}
        //GoTo Finish_Up_Proc
        //Else


        A1 = A1 == null ? 0 : A1;
        B1 = B1 == null ? 0 : B1;
        c1 = c1 == null ? 0 : c1;
        A2 = A2 == null ? 0 : A2;
        B2 = B2 == null ? 0 : B2;
        c2 = c2 == null ? 0 : c2;


        //'***  STEP 1  (Convert plus cylider to minus cylinder format) ***
        if (B2 > 0) {
            A2 = A2 + B2
            B2 = -B2
            c2 = c2 + 90
            if (c2 >= 180) {
                c2 = c2 - 180
            }
        }
        //End If



        //    return obj;
        //};

        ////***  STEP 2  (Vertex sphere and cylinder) ***
        //this.a2Cal = function (a2) {


        //'***  STEP 2  (Vertex sphere and cylinder) ***
        var FSC, XFSC, XSPH, PWR;

        if (c1 == 180) {
            c1 = 0
        }
        if (c2 == 180) {
            c2 = 0
        }

        var FSC = A2 + B2

        // Set PWR
        if (Math.abs(A2) > 4) {
            PWR = A2 / (1 - (VD * (A2 / 1000)))
            XSPH = PWR * 8
            XSPH = this.precisionRound(XSPH, 0)
            XSPH = XSPH / 8
            XSPH = this.precisionRound(XSPH, 2)
            PWR = XSPH
        }
        else {
            PWR = A2
        }

        //End If

        //'Set FSC
        if (Math.abs(FSC) > 4) {
            FSC = FSC / (1 - (VD * (FSC / 1000)))
            XFSC = FSC * 8
            XFSC = this.precisionRound(XFSC, 0)
            XFSC = XFSC / 8
            XFSC = this.precisionRound(XFSC, 2)
            FSC = XFSC
            FSC = FSC - PWR
        }
        else {
            FSC = B2
        }
        //sEnd If


        // Step 3


        Hold_cyl = B1;
        Hold_sCyl = B2;

        if (c1 > c2) {

            Sph3 = A1
            Cyl3 = B1
            Axis3 = c1
            Sph4 = PWR
            Cyl4 = FSC
            Axis4 = c2
        }
        else {
            Sph3 = PWR
            Cyl3 = FSC
            Axis3 = c2
            Sph4 = A1
            Cyl4 = B1
            Axis4 = c1
        }

        // '***  STEP 4  (Cross-cylinder calculation) ***
        var G = (Axis4 - Axis3) / degr;
        var K = (Cyl4 * Math.sin(2 * G));
        var ORL = Cyl3 + (Cyl4 * Math.cos(2 * G));

        if (ORL == 0) {
            KDL = 0;
        } else {
            KDL = K / ORL;
        }
        //End If

        var M = (Math.atan(KDL)) / 2;
        var Axis5 = (M * degr) + Axis3;

        if (Axis5 >= 180) {
            Axis5 = Axis5 - 180
        }
        //End If

        var x = Cyl3 * Math.sin(M) * Math.sin(M);
        var GM = G - M;
        var y = Cyl4 * Math.sin(GM) * Math.sin(GM);
        var Sph5 = Sph3 + Sph4 + x + y;
        var Cyl5 = Cyl3 + Cyl4 - (2 * (x + y));

        //if(Hold_cyl == 0 && Hold_sCyl == 0){
        //    Cyl5 = 0
        //    Axis5 = 0
        //}
        //if (Cyl5 > 0) {
        //    //' adjust to minus cylinder format
        //    Sph5 = Sph5 + Cyl5
        //    Cyl5 = -Cyl5
        //    Axis5 = Axis5 + 90
        //}

        //if( Axis5 >= 180){
        //    Axis5 = Axis5 - 180;
        //}
        //End If


        if (Hold_cyl == 0 && Hold_sCyl == 0) {
            //debugger;
            Cyl5 = 0
            Axis5 = 0
        }
        else if (Cyl5 > 0) {
            // adjust to minus cylinder format
            Sph5 = Sph5 + Cyl5
            Cyl5 = -Cyl5
            Axis5 = Axis5 + 90
            if (Axis5 >= 180) {
                Axis5 = Axis5 - 180
            }
        }



        //' set the final values
        var A3 = this.precisionRound((Math.round((Sph5 * 8), 2) / 8), 2);


        //'adjust for difference in Base Curves
        if (D1 != D3) {
            A3 = A3 + (D1 - D3)
        }

        var B3 = this.precisionRound((Math.round((Cyl5 * 8), 2) / 8), 2)//this.precisionRound

        if (B3 == 0) {
            c3 = 0;
        }
        else {
            c3 = Axis5;
        }
        //End If

        //if (A2 == 0 && B2 == 0 && c2 == 0)
        //{
        //    obj.a3 = this.precisionRound(A1, 2);
        //    obj.b3 = this.precisionRound(B1, 2);
        //    obj.c3 = this.precisionRound(c2, 0);
        //}else{
        obj.a3 = this.precisionRound(A3, 2);
        obj.b3 = this.precisionRound(B3, 2);
        obj.c3 = this.precisionRound(c3, 0);
        //}


        return obj;
    };
    // For Soft lenss
    this.softbc_proc = function (this_dia, this_Kfl)//this_dia As Variant, this_Kfl As Variant
    {
        var Case = this_dia;
        if (Case >= 15) {
            var Case = this_Kfl;
            if (Case > 47) {
                softbc_proc = 8.3
            }
            if (Case > 44) {
                softbc_proc = 8.6
            }
            if (Case > 41) {
                softbc_proc = 8.9

            }
            if (Case > 39) {
                softbc_proc = 9.2
            }
            if (Case <= 39) {

                softbc_proc = 9.5
            }
        }

        /////////////////////////////// Case 2 //////////////////

        if (Case >= 13.8) {
            var Case = this_Kfl;

            if (Case > 47) {
                softbc_proc = 8
            }
            if (Case > 44) {
                softbc_proc = 8.3
            }
            if (Case > 41) {
                softbc_proc = 8.6
            }
            if (Case > 39) {
                softbc_proc = 8.9
            }
            if (Case <= 39) {

                softbc_proc = 9.2
            }
        }

        /////////////////////////////// Case 3 //////////////////   
        if (Case >= 13) {
            var Case = this_Kfl;

            if (Case > 47) {

                softbc_proc = 8.1
            }
            if (Case > 44) {

                softbc_proc = 8.3
            }
            if (Case > 41) {

                softbc_proc = 8.5
            }
            if (Case > 39) {

                softbc_proc = 8.7
            }
            if (Case <= 39) {

                softbc_proc = 8.9
            }
        }

        /////////////////////////////// Case 4 //////////////////
        if (Case >= 12) {
            var Case = this_Kfl;

            if (Case > 47) {
                softbc_proc = 7.4
            }
            if (Case > 44) {
                softbc_proc = 7.6
            }
            if (Case > 41) {
                softbc_proc = 7.8
            }
            if (Case > 39) {
                softbc_proc = 8
            }
            if (Case <= 39) {
                softbc_proc = 8.2
            }
        }

        /////////////////////////////// Case 5 //////////////////
        if (Case < 12) {
            var Case = this_Kfl;
            if (Case > 47) {
                softbc_proc = 7.2
            }
            if (Case > 44) {
                softbc_proc = 7.4
            }
            if (Case > 41) {
                softbc_proc = 7.6
            }
            if (Case > 39) {
                softbc_proc = 7.8
            }
            if (Case <= 39) {
                softbc_proc = 8
            }
        }

        return softbc_proc;
    }
    // For Gp lenss
    this.madDesn_proc = function (this_Kfl, this_Kst, this_dia) {//this_Kst

        var DK;
        DK = Math.abs(this_Kst - this_Kfl);

        var Case = this_dia;
        if (Case > 9.2) {
            var Case = DK;
            if (Case > 2.5)
                madDesn_proc = 0
            if (Case >= 1.62)
                madDesn_proc = -0.25
            if (Case >= 0)
                madDesn_proc = -0.5
        }


        //If this_Kfl > 43 Then
        //MsgBox "WARNING:  K-Flat is too steep." & vbCrLf & "For this diameter K-Flat must be l.t. or equal to 43", vbExclamation
        //End If


        if (Case >= 8.8) {
            var Case = DK;
            if (Case > 2.5)
                madDesn_proc = 0.5
            if (Case >= 1.62)
                madDesn_proc = 0.25
            if (Case >= 0)
                madDesn_proc = 0
        }

        //If this_Kfl < 43 Then
        //MsgBox "WARNING:  K-Flat is too flat." & vbCrLf & "For this diameter K-Flat must be g.t. or equal to 43", vbExclamation
        //End If

        //If this_Kfl > 45.75 Then
        //MsgBox "WARNING:  K-Flat is too steep." & vbCrLf & "For this diameter K-Flat must be l.t. or equal to 45.75", vbExclamation
        //End If

        if (Case < 8.8) {
            var Case = DK;
            if (Case > 2.5)
                madDesn_proc = 1
            if (Case >= 1.62)
                madDesn_proc = 0.75
            if (Case >= 0)
                madDesn_proc = 0.5
        }

        //If this_Kfl < 46 Then
        //MsgBox "WARNING:  K-Flat is too flat." & vbCrLf & "For this diameter K-Flat must be g.t. or equal to 46", vbExclamation
        //End If

    }
    // Rkone_proc .added by shwetha
    this.Rkone_proc = function (this_Bcfl, this_Style) {
        //'gets values from RKonDesn table based on the current values for STYLE and BCFL - compares BCFL to the CENT column in the table
        var Kavg, Kfind, Style_filter;

        Style_filter = !this_Style ? null : this_Style.toUpperCase();
        //Style_filter = "IK104";//IK96
        var strSQL = [];
        //strSQL = "SELECT [GBCE],[GPOZ],[GPC1],[GCE1],[GW1],[GPC2],[GCE2],[GW2],[GPC3],[GCE3],[GW3],[GPC4],[GCE4],[GW4],[AVGK]" _
        //       & " FROM RkonDesn WHERE [CENT] <= " & this_Bcfl & " AND [STYLE] LIKE '" & Style_filter & "*' ORDER BY [AVGK]"

        //'MsgBox strSQL
        if (this.RkonDesn.length > 0) {
            for (var i = 0; i < this.RkonDesn.length; i++) {
                if (this.RkonDesn[i].CENT == this_Bcfl && this.RkonDesn[i].STYLE == Style_filter) {
                    strSQL.push(this.RkonDesn[i]);
                }
            }
        }
        var rstRGPkoneDesn = strSQL;
        var obj = {};
        if (rstRGPkoneDesn.length > 0) {
            //Call save_KONEdesn(rstRGPkoneDesn)
            obj = this.save_KONEdesnForGP(rstRGPkoneDesn[0]);
        }
        else {
            strSQL = [];
            if (this.RkonDesn.length > 0) {
                for (var i = 0; i < this.RkonDesn.length; i++) {
                    if (this.RkonDesn[i].STYLE == Style_filter) {
                        strSQL.push(this.RkonDesn[i]);
                    }
                }
            }
            rstRGPkoneDesn = strSQL;
            //---------to get nearest cent value w.r.t input BCF_filter value done by shwetha---------//
            var closest = [];
            closest = rstRGPkoneDesn.reduce(function (prev, curr) {
                return (Math.abs(curr.CENT - this_Bcfl) < Math.abs(prev.CENT - this_Bcfl) ? curr : prev);
            });
            //-----------------end
            obj = this.save_KONEdesnForGP(closest);
        }
        return obj;
    };
    //end
   
    //RkonBC_proc------commented this code by shwetha.
    //this.RkonBC_proc = function (this_Kfl, this_Kst, this_Style, This_Type, this_Bcfl,this_Design) {
    //    // 'returns Center value
    //    var Kavg ,Kfind,strSQL,Style_filter;

    //    //Dim myDB As DAO.Database
    //    //Dim rstRGPkoneDesn As Recordset
    //    //Set myDB = CurrentDb
    //    if (This_Type = "D") {
    //        Kavg = (this_Kfl + this_Kst) / 2;
    //    }else if (This_Type= "C") {
    //        if(this_Bcfl != 0){
    //            Kavg = 337.5 / this_Bcfl;
    //        }else {
    //            //MsgBox "The BCFL value is zero." & vbCrLf & "You must set the BCFL before using the lens lookup when in the 'C' mode of order entry."
    //        }

    //    }
    //    if ((Kavg - parseInt(Kavg)) > 0.5) {
    //        Kfind = parseInt(Kavg) + 1;
    //    }else {
    //        Kfind = parseInt(Kavg);
    //    }

    //    Style_filter = this_Style;


    //    var sortable = this.RkonDB;

    //    var rKonDb = [];
    //    for (var vehicle in sortable) {
    //        rKonDb.push([vehicle, sortable[vehicle]]);
    //    }
    //    ////
    //    rKonDb.sort(function (a, b) {
    //        return a[1].AVGK - b[1].AVGK;
    //    });


    //    for (var i = 0; i < rKonDb.length; i++) {
    //        //|| ((rKonDb[i].DESIGN == this_Design))
    //        if ((rKonDb[i].KAVMIN <= Kavg && rKonDb[i].KAVMAX > Kavg) ) {
    //            //console.log("Selected One " + JSON.stringify(rKonDb[i]))
    //        }
    //    }



    //};
    //added by shwetha
    this.RkonBC_proc = function (this_Kfl, this_Kst, this_Style, This_Type, this_Bcfl) {
        var Kavg, Kfind, Style_filter;
        this_Kst = this_Kfl;
        var obj = {};
        //If UCase(This_Type) = "D" Then
        Kavg = (this_Kfl + this_Kst) / 2;
        //ElseIf UCase(This_Type) = "C" Then
        //If this_Bcfl <> 0 Then
        //Kavg = 337.5 / this_Bcfl
        //Else
        //MsgBox "The BCFL value is zero." _
        //    & vbCrLf & "You must set the BCFL before using the lens lookup when in the 'C' mode of order entry."
        //GoTo Lookup_failed
        //End If
        //End If    

        Style_filter = !this_Style ? null : this_Style.toUpperCase();
        //Style_filter = "IK104";


        var strSQL = [];

        //strSQL = "SELECT [CENT],[AVGK] FROM RkonDesn WHERE [KAVMIN] <= " & Kavg _
        //       & " AND [KAVMAX] > " & Kavg & " AND [STYLE] LIKE '" & Style_filter & "*' ORDER BY [AVGK],[CENT] "

        //'MsgBox strSQL

        if (this.RkonDesn.length > 0) {
            for (var i = 0; i < this.RkonDesn.length; i++) {
                if (this.RkonDesn[i].STYLE == Style_filter && this.RkonDesn[i].KAVMIN <= Kavg && this.RkonDesn[i].KAVMAX > Kavg) {
                    strSQL.push(this.RkonDesn[i]);
                }
            }
        }
        var rstRGPkoneDesn = strSQL;
        if (rstRGPkoneDesn.length > 0) {
            obj = rstRGPkoneDesn[0].CENT;
        }
        if (!obj) {
            obj = null;
        }
        return obj;
    }
    this.RsvBC_proc = function (this_Kfl, this_Kst) {  //'returns the FR value
        // //
        var DK;

        if (this_Kst != 0) {
            DK = Math.abs(this_Kst - this_Kfl);
        }
        else {
            DK = 0
        }

        var Case = DK;
        if (Case > 2)
            RsvBC_proc = 0.5
        if (Case >= 1.625)
            RsvBC_proc = 0.375
        if (Case >= 1.125)
            RsvBC_proc = 0.25
        if (Case >= 0.625)
            RsvBC_proc = 0.125
        if (Case >= 0)
            RsvBC_proc = 0


        return RsvBC_proc;
    };
    this.RGPPC_proc = function (this_dia, this_Design, rgpTData) {
        ////
        var obj = {};
        //'gets values from RgpDesn table based on the current values for DESIGN and DIA - compares "this_dia" to the DIAM column in the table
        var Dia_incr = this_dia;
        var filterArray = [];
        var filterArray1 = [];
        if (rgpTData.length > 0) {
            for (var i = 0; i < rgpTData.length; i++) {
                if (rgpTData[i].startSize == this_dia && rgpTData[i].designBase == this_Design) {
                    filterArray.push(rgpTData[i]);
                }
            }

        }
        else if (filterArray.length > 0) {
            for (var i = 0; i < rgpTData.length; i++) {
                if (rgpTData[i].designBase.toUpperCase() == this_Design) {
                    filterArray1.push(rgpTData[i]);
                }
            };
        }
        // //
        if (filterArray.length > 0) {
            obj = this.save_RGPdesn(filterArray[0]);
        } else {
            if (filterArray1.length > 0) {
                var max_dia = filterArray1[filterArray1.length - 1].startSize;
                do {
                    Dia_incr = Dia_incr + 0.1;
                    for (var i = 0; filterArray1.length < length; i++) {
                        if (Math.round(Math.floor(filterArray1[i].startSize), 1) == Math.round(Dia_incr, 1)) {
                            obj = this.save_RGPdesn(filterArray1[i]);
                        }
                    }
                } while (Dia_incr <= max_dia);

            }
        }
        return obj;
    };
    this.save_RGPdesn = function (rst1) {

        var obj = {};
        if (rst1.bce != null)
            obj.BCE = rst1.bce;
        else
            obj.BCE = 0;
        if (rst1.poz != null)
            obj.poz = rst1.poz;
        else
            obj.poz = 0
        if (rst1.pc1 != null)
            obj.PC1 = rst1.pc1;
        else
            obj.PC1 = 0;
        if (rst1.e1 != null)
            obj.PE1 = rst1.e1;
        else
            obj.PE1 = 0
        if (rst1.w1 != null)
            obj.W1 = rst1.w1;
        else
            obj.W1 = 0

        if (rst1.pc2 != null)
            obj.PC2 = rst1.pc2;
        else
            obj.PC2 = 0;

        if (rst1.e2 != null)
            obj.PE2 = rst1.e2;
        else
            obj.PE2 = 0
        if (rst1.w2 != null)
            obj.W2 = rst1.w2;
        else
            obj.W2 = 0

        if (rst1.pc3 != null)
            obj.PC3 = rst1.pc3;
        else
            obj.PC3 = 0;

        if (rst1.e3 != null)
            obj.PE3 = rst1.e3;
        else
            obj.PE3 = 0;

        if (rst1.w3 != null)
            obj.W3 = rst1.w3;
        else
            obj.W3 = 0;

        if (rst1.pc4 != null)
            obj.PC4 = rst1.pc4;
        else
            obj.PC4 = 0;

        if (rst1.e4 != null)
            obj.PE4 = rst1.e4;
        else
            obj.PE4 = 0;

        if (rst1.w4 != null)
            obj.W4 = rst1.w4;
        else
            obj.W4 = 0;

        if (rst1.pc5 != null)
            obj.PC5 = rst1.pc5;
        else
            obj.PC5 = 0;

        if (rst1.e5 != null)
            obj.PE5 = rst1.e5;
        else
            obj.PE5 = 0;

        if (rst1.w5 != null)
            obj.W5 = rst1.w5;
        else
            obj.W5 = 0;

        return obj;
    };
    this.RtorBC_proc = function (this_Kfl) {//, this_Kst, this_FM, this_SM
        var obj = {};
        //-----------commented code by shwetha//   
        //if (this_FM == null || this_FM == "") 
        //    FM = 0
        //if (this_FM == null || this_FM == "") 
        //    SM = 0
        //if (FM > 130 ||  FM < 50) {
        //    FR = -0.25
        //    SR = -0.5
        //}
        //if (SM  >= 50 && SM <= 130) {
        //    FR = -0.5
        //    SR = -0.25
        //} 
        //if (this_Kfl + FR != 0) {
        //    BCFL = 337.5 / (this_Kfl + FR);
        //} 
        if (this_Kfl) {
            obj.BCFL = 337.5 / (this_Kfl);
        }
        //if (this_Kfl + SR != 0) {
        //    BCST = 337.5 / (this_Kst + SR)
        //}   
        //-----------commented above code by shwetha//
        return obj;
    };
    this.JUPPC_proc1 = function (this_Desn, this_Bcfl) {
        //Dim BASE As Double
        // debugger
        if (this_Bcfl != null) {
            var Case = this_Desn.toUpperCase();//((this_Desn.toUpperCase()).substr(0, 3));
            // debugger
            /*
            _.contains(["JUP135B", "JUP144", "JUP144B", "JUP144C", "JUP144D", "JUP145B", "JUP156C",
                    "JUP150", "JUP150B", "JUP150C", "JUP150D", "JUP156", "JUP156B", "JUP156D",
                    "JUP160B", "JUP170B", "JUP174", "JUP174B", "JUP174C", "JUP174D", "JUP186C",
                    "JUP180",  "JUP180C", "JUP180D", "JUP186", "JUP186B", "JUP186D"], Case)
                    We are syntasize the code to simple format.
            */
            if (Case.includes("JUPITER - 15") || Case == "JUPITER - 18" || Case == "JUP150B" || Case == "JUP180B" || Case.includes("JUPITER B - 15") || Case.includes("JUPITER B - 18") || Case.includes("JUP156B") || Case.includes("JUPITER B - 13.5") || Case.includes("JUP135B")) {
                //"JUP150B","JUP180B",
                JUPPC_proc1 = this_Bcfl + 0.2;
            } else if (_.contains(["JUP177", "JUP177B", "JUP177C", "JUP177D", "JUP188C", //"ELA15", "ELA155F", making both as one.
                "JUP182", "JUP182B", "JUP182C", "JUP182D", "JUP188", "JUP188B", "JUP188D",
                "JUP202", "JUP202B", "JUP202C", "JUP202D", "NORM155", "JUPITER B - 18.2", "JUPITER B - 18.8", "JUPITER B - 20.2", "JUPITER C - 20.2"], Case)) {
                JUPPC_proc1 = this_Bcfl + 0.4;
            } else if (_.contains(["JUP144K", "JUP150K", "JUP156K", "JUP174K", "JUP180K", "JUP186K", "JUPITER K - 15", "JUPITER K - 18", "JUPITER K - 144", "JUPITER K - 15.6"], Case)) {
                //"JUP150K", "JUP180K",
                BASE = (337.5 / this_Bcfl) - 4;
                JUPPC_proc1 = (337.5 / BASE) + 0.2;
            } else if (_.contains(["JUP144S", "JUP150S", "JUP156S", "JUP174S", "JUP180S", "JUP186S", "JUPITER S - 15", "JUPITER S - 15.6", "JUPITER S - 18"], Case)) {
                //"JUP150S", "JUP180S",
                BASE = (337.5 / this_Bcfl) - 8;
                JUPPC_proc1 = (337.5 / BASE) + 0.2;
            } else if (_.contains(["JUP144R", "JUP150R", "JUP156R", "JUP174R", "JUP180R", "JUP186R", "JUPITER R - 15", "JUPITER R - 18", "JUPITER R - 15.6"], Case)) {
                //"JUP150R", "JUP180R",
                BASE = (337.5 / this_Bcfl) + 4;
                JUPPC_proc1 = (337.5 / BASE) + 0.2;
            } else if (_.contains(["JUP144S", "JUP150F", "JUP156F", "JUP174F", "JUP180F", "JUP186F", "JUPITER F - 15", "JUPITER F - 18", "JUPITER F - 18", "JUPITER F - 15.6"], Case)) {
                //"JUP150F", "JUP180F",
                BASE = (337.5 / this_Bcfl) + 8;
                JUPPC_proc1 = (337.5 / BASE) + 0.2;
            } else if (_.contains(["JUP182K", "JUP188K", "JUP202K", "JUPITER K - 18.2"], Case)) {
                BASE = (337.5 / this_Bcfl) - 4;
                JUPPC_proc1 = (337.5 / BASE) + 0.4;
            } else if (_.contains(["JUP182S", "JUP188S", "JUP202S", "JUPITER S - 18.2"], Case)) {
                BASE = (337.5 / this_Bcfl) - 8;
                JUPPC_proc1 = (337.5 / BASE) + 0.4;
            } else if (_.contains(["JUP182R", "JUP188R", "JUP202R", "EUR180", "EUR180S", "EUR180T", "EUR200", "JUPITER R - 18.2", "JUPITER R - 18.8", "EUROPA - 18", "EUROPA - 20"], Case)) {
                BASE = (337.5 / this_Bcfl) + 4
                JUPPC_proc1 = (337.5 / BASE) + 0.4
            } else if (_.contains(["JUP182F", "JUP188F", "JUP202F", "JUPITER F - 18.2"], Case)) {
                BASE = (337.5 / this_Bcfl) + 8;
                JUPPC_proc1 = (337.5 / BASE) + 0.4;
            } else if (Case.includes("ELA")) {
                JUPPC_proc1 = this_Bcfl + 0.4;
            } else if (Case.includes("EUR")) {
                BASE = (337.5 / this_Bcfl) + 2.15
                JUPPC_proc1 = (337.5 / BASE) + 0.2075
            }

            return JUPPC_proc1;

        }


    };
    this.JUPPC_proc2 = function (this_Desn, this_Bcfl) {
        // debugger
        if (((this_Desn.toUpperCase()).substr(0, 6)) == "JUP177" || ((this_Desn.toUpperCase()).substr(0, 6)) == "JUP188" || ((this_Desn.toUpperCase()).substr(0, 6)) == "JUP202") {
            return PE1 = (((337.5 / this_Bcfl) - 43) / 100) - 1.2;
        }
    };
    this.RgpAOZ_proc = function (this_dia) {
        let AOZM;
        if (this_dia != null) {
            var Case = this_dia;

            if (Case < 7.4) {
                AOZM = -0.9
            } else if (Case < 7.8) {
                AOZM = -0.7
            } else if (Case < 8.2) {
                AOZM = -0.5
            } else if (Case < 8.6) {
                AOZM = -0.2
            } else if (Case < 9) {
                AOZM = -0.1
            } else if (Case < 9.4) {
                AOZM = 0
            } else if (Case < 9.8) {
                AOZM = 0
            } else if (Case < 10.2) {
                AOZM = 0.1
            } else if (Case >= 10.2) {
                AOZM = 0.2;
            }

            return AOZM;
        }

    };
    this.AOZFPOWER = function (sph) {
        //

        var sphValue = sph == null ? 0 : Math.floor(sph);
        var powerDB = this.PowerDB;

        for (var i = 0; i < powerDB.length; i++) {

            if (powerDB[i].SPHPWR == sphValue) {
                //console.log(" powerDB[i] " + JSON.stringify(powerDB[i]));
                return AOZ = powerDB[i].R_AOZ;
            }// else if (powerDB[i].SPHPWR <= sphValue) {
            //    //console.log(" powerDB[i] " + JSON.stringify(powerDB[i]));
            //    return AOZ = powerDB[i].R_AOZ;
            //} 
        }
    };
    //this.rstRGPkoneDesn = function () {

    //};
    //******************** PC1 value formula ***************************************
    //null, eyeCtr.rightEye.productCode, eyeCtr.rightEye.description, eyeCtr.rightEye.bcmM2, eyeCtr.rightEye.bcD2, eyeCtr.rightEye.dia, $scope.regpTData

    // ********************************** added by shwetha for soft lens design***************************************



    this.SkonDB = [
        {
            "Id": "1",
            "DESN": "STD",
            "KAVMIN": "43",
            "KAVMAX": "46.99",
            "CENT": "8.5",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.4",
            "SPC1": "8.6",
            "SW1": "2.85",
            "SPC2": "9.6",
            "SW2": "0.25",
            "SAOZ": "7.9",
            "RECT": "0.52",
            "PWRFIT": "2"
        },
        {
            "Id": "2",
            "DESN": "STD",
            "KAVMIN": "47",
            "KAVMAX": "49.99",
            "CENT": "8.1",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.5",
            "SPC1": "8.6",
            "SW1": "2.85",
            "SPC2": "9.6",
            "SW2": "0.25",
            "SAOZ": "7.8",
            "RECT": "0.5",
            "PWRFIT": "0"
        },
        {
            "Id": "3",
            "DESN": "STD",
            "KAVMIN": "50",
            "KAVMAX": "52.99",
            "CENT": "7.7",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.6",
            "SPC1": "8.6",
            "SW1": "2.85",
            "SPC2": "9.6",
            "SW2": "0.25",
            "SAOZ": "7.7",
            "RECT": "0.48",
            "PWRFIT": "-4"
        },
        {
            "Id": "4",
            "DESN": "STD",
            "KAVMIN": "53",
            "KAVMAX": "55.99",
            "CENT": "7.3",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.7",
            "SPC1": "8.6",
            "SW1": "2.85",
            "SPC2": "9.6",
            "SW2": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.46",
            "PWRFIT": "-8"
        },
        {
            "Id": "5",
            "DESN": "STD",
            "KAVMIN": "56",
            "KAVMAX": "58.99",
            "CENT": "6.9",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.8",
            "SPC1": "8.6",
            "SW1": "2.85",
            "SPC2": "9.6",
            "SW2": "0.25",
            "SAOZ": "7.5",
            "RECT": "0.44",
            "PWRFIT": "-12"
        },
        {
            "Id": "6",
            "DESN": "STD",
            "KAVMIN": "59",
            "KAVMAX": "61.99",
            "CENT": "6.5",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.9",
            "SPC1": "8.6",
            "SW1": "2.85",
            "SPC2": "9.6",
            "SW2": "0.25",
            "SAOZ": "7.4",
            "RECT": "0.42",
            "PWRFIT": "-16"
        },
        {
            "Id": "7",
            "DESN": "STD",
            "KAVMIN": "62",
            "KAVMAX": "64.99",
            "CENT": "6.1",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.95",
            "SPC1": "8.6",
            "SW1": "2.85",
            "SPC2": "9.6",
            "SW2": "0.25",
            "SAOZ": "7.3",
            "RECT": "0.4",
            "PWRFIT": "-20"
        },
        {
            "Id": "8",
            "DESN": "STD",
            "KAVMIN": "65",
            "KAVMAX": "67.99",
            "CENT": "5.7",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-1",
            "SPC1": "8.6",
            "SW1": "2.85",
            "SPC2": "9.6",
            "SW2": "0.25",
            "SAOZ": "7.2",
            "RECT": "0.38",
            "PWRFIT": "-24"
        },
        {
            "Id": "9",
            "DESN": "STD",
            "KAVMIN": "68",
            "KAVMAX": "72",
            "CENT": "5.3",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-1.05",
            "SPC1": "8.6",
            "SW1": "2.85",
            "SPC2": "9.6",
            "SW2": "0.25",
            "SAOZ": "7.1",
            "RECT": "0.36",
            "PWRFIT": "-26"
        },
        {
            "Id": "10",
            "DESN": "GLOBUS",
            "KAVMIN": "43",
            "KAVMAX": "46.99",
            "CENT": "8.1",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.5",
            "SPC1": "8.3",
            "SW1": "0.8",
            "SCE1": "-0.5",
            "SPC2": "8.3",
            "SW2": "1.3",
            "SPC3": "8.6",
            "SW3": "0.75",
            "SPC4": "9.8",
            "SW4": "0.25",
            "SAOZ": "7.9",
            "RECT": "0.52",
            "PWRFIT": "2"
        },
        {
            "Id": "11",
            "DESN": "GLOBUS",
            "KAVMIN": "47",
            "KAVMAX": "49.99",
            "CENT": "7.7",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.5",
            "SPC1": "8",
            "SW1": "0.8",
            "SCE1": "-0.5",
            "SPC2": "8",
            "SW2": "1.3",
            "SPC3": "8.3",
            "SW3": "0.75",
            "SPC4": "9.8",
            "SW4": "0.25",
            "SAOZ": "7.8",
            "RECT": "0.5",
            "PWRFIT": "0"
        },
        {
            "Id": "12",
            "DESN": "GLOBUS",
            "KAVMIN": "50",
            "KAVMAX": "52.99",
            "CENT": "7.3",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.55",
            "SPC1": "7.7",
            "SW1": "0.8",
            "SCE1": "-0.55",
            "SPC2": "8",
            "SW2": "1.3",
            "SPC3": "8.3",
            "SW3": "0.75",
            "SPC4": "9.8",
            "SW4": "0.25",
            "SAOZ": "7.7",
            "RECT": "0.48",
            "PWRFIT": "-4"
        },
        {
            "Id": "13",
            "DESN": "GLOBUS",
            "KAVMIN": "53",
            "KAVMAX": "55.99",
            "CENT": "6.9",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.55",
            "SPC1": "7.3",
            "SW1": "0.8",
            "SCE1": "-0.55",
            "SPC2": "7.8",
            "SW2": "1.3",
            "SPC3": "8.3",
            "SW3": "0.75",
            "SPC4": "9.8",
            "SW4": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.46",
            "PWRFIT": "-8"
        },
        {
            "Id": "14",
            "DESN": "GLOBUS",
            "KAVMIN": "56",
            "KAVMAX": "58.99",
            "CENT": "6.5",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.55",
            "SPC1": "7",
            "SW1": "0.8",
            "SCE1": "-0.55",
            "SPC2": "7.6",
            "SW2": "1.3",
            "SPC3": "8.3",
            "SW3": "0.75",
            "SPC4": "9.8",
            "SW4": "0.25",
            "SAOZ": "7.5",
            "RECT": "0.44",
            "PWRFIT": "-12"
        },
        {
            "Id": "15",
            "DESN": "GLOBUS",
            "KAVMIN": "59",
            "KAVMAX": "61.99",
            "CENT": "6.1",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.6",
            "SPC1": "6.7",
            "SW1": "0.8",
            "SCE1": "-0.6",
            "SPC2": "7.4",
            "SW2": "1.3",
            "SPC3": "8.3",
            "SW3": "0.75",
            "SPC4": "9.8",
            "SW4": "0.25",
            "SAOZ": "7.4",
            "RECT": "0.42",
            "PWRFIT": "-16"
        },
        {
            "Id": "16",
            "DESN": "GLOBUS",
            "KAVMIN": "62",
            "KAVMAX": "64.99",
            "CENT": "5.7",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.7",
            "SPC1": "6.4",
            "SW1": "0.8",
            "SCE1": "-0.7",
            "SPC2": "7.2",
            "SW2": "1.3",
            "SPC3": "8.3",
            "SW3": "0.75",
            "SPC4": "9.8",
            "SW4": "0.25",
            "SAOZ": "7.3",
            "RECT": "0.4",
            "PWRFIT": "-20"
        },
        {
            "Id": "17",
            "DESN": "GLOBUS",
            "KAVMIN": "65",
            "KAVMAX": "67.99",
            "CENT": "5.3",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.8",
            "SPC1": "6.2",
            "SW1": "0.8",
            "SCE1": "-0.75",
            "SPC2": "7",
            "SW2": "1.3",
            "SPC3": "8.3",
            "SW3": "0.75",
            "SPC4": "9.8",
            "SW4": "0.25",
            "SAOZ": "7.2",
            "RECT": "0.38",
            "PWRFIT": "-24"
        },
        {
            "Id": "18",
            "DESN": "GLOBUS",
            "KAVMIN": "68",
            "KAVMAX": "72",
            "CENT": "4.9",
            "DIAM": "14.8",
            "SPOZ": "8.6",
            "SBCE": "-0.8",
            "SPC1": "6",
            "SW1": "0.8",
            "SCE1": "-0.8",
            "SPC2": "7",
            "SW2": "1.3",
            "SPC3": "8",
            "SW3": "0.75",
            "SPC4": "9.8",
            "SW4": "0.25",
            "SAOZ": "7.1",
            "RECT": "0.36",
            "PWRFIT": "-28"
        },
        {
            "Id": "19",
            "DESN": "PELL",
            "KAVMIN": "40",
            "KAVMAX": "43.99",
            "CENT": "9.1",
            "DIAM": "14.8",
            "SPOZ": "7.6",
            "SBCE": "0",
            "SPC1": "8",
            "SW1": "1.45",
            "SPC2": "8.3",
            "SW2": "1.9",
            "SPC3": "9.3",
            "SW3": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.59",
            "PWRFIT": "2"
        },
        {
            "Id": "20",
            "DESN": "PELL",
            "KAVMIN": "44",
            "KAVMAX": "46.99",
            "CENT": "8.7",
            "DIAM": "14.8",
            "SPOZ": "7.6",
            "SBCE": "0",
            "SPC1": "7.6",
            "SW1": "1.45",
            "SPC2": "8.3",
            "SW2": "1.9",
            "SPC3": "9.3",
            "SW3": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.59",
            "PWRFIT": "2"
        },
        {
            "Id": "21",
            "DESN": "PELL",
            "KAVMIN": "47",
            "KAVMAX": "49.99",
            "CENT": "8.3",
            "DIAM": "14.8",
            "SPOZ": "7.6",
            "SBCE": "0",
            "SPC1": "7.3",
            "SW1": "1.45",
            "SPC2": "8.3",
            "SW2": "1.9",
            "SPC3": "9.3",
            "SW3": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.57",
            "PWRFIT": "0"
        },
        {
            "Id": "22",
            "DESN": "PELL",
            "KAVMIN": "50",
            "KAVMAX": "52.99",
            "CENT": "7.9",
            "DIAM": "14.8",
            "SPOZ": "7.6",
            "SBCE": "0",
            "SPC1": "7",
            "SW1": "1.45",
            "SPC2": "8.3",
            "SW2": "1.9",
            "SPC3": "9.3",
            "SW3": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.55",
            "PWRFIT": "-4"
        },
        {
            "Id": "23",
            "DESN": "PELL",
            "KAVMIN": "53",
            "KAVMAX": "55.99",
            "CENT": "7.5",
            "DIAM": "14.8",
            "SPOZ": "7.6",
            "SBCE": "0",
            "SPC1": "6.7",
            "SW1": "1.45",
            "SPC2": "8.3",
            "SW2": "1.9",
            "SPC3": "9.3",
            "SW3": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.53",
            "PWRFIT": "-8"
        },
        {
            "Id": "24",
            "DESN": "PELL",
            "KAVMIN": "56",
            "KAVMAX": "58.99",
            "CENT": "7.1",
            "DIAM": "14.8",
            "SPOZ": "7.6",
            "SBCE": "0",
            "SPC1": "6.5",
            "SW1": "1.45",
            "SPC2": "8.3",
            "SW2": "1.9",
            "SPC3": "9.3",
            "SW3": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.51",
            "PWRFIT": "-12"
        },
        {
            "Id": "25",
            "DESN": "PELL",
            "KAVMIN": "59",
            "KAVMAX": "61.99",
            "CENT": "6.7",
            "DIAM": "14.8",
            "SPOZ": "7.6",
            "SBCE": "0",
            "SPC1": "6.3",
            "SW1": "1.45",
            "SPC2": "8.3",
            "SW2": "1.9",
            "SPC3": "9.3",
            "SW3": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.49",
            "PWRFIT": "-16"
        },
        {
            "Id": "26",
            "DESN": "PELL",
            "KAVMIN": "62",
            "KAVMAX": "64.99",
            "CENT": "6.3",
            "DIAM": "14.8",
            "SPOZ": "7.6",
            "SBCE": "0",
            "SPC1": "6",
            "SW1": "1.45",
            "SPC2": "8.3",
            "SW2": "1.9",
            "SPC3": "9.3",
            "SW3": "0.25",
            "SAOZ": "7.6",
            "RECT": "0.49",
            "PWRFIT": "-16"
        }
    ];







    //added by shwetha
    this.save_KONEdesn = function (rst1) {

        var obj = {};
        if (rst1.SBCE != null && rst1.SBCE != "")
            obj.BCE = rst1.SBCE;
        else
            obj.BCE = 0;
        if (rst1.SPOZ != null && rst1.SPOZ != "")
            obj.POZ = rst1.SPOZ;
        else
            obj.POZ = 0;
        if (rst1.SPC1 != null && rst1.SPC1 != "")
            obj.PC1 = rst1.SPC1;
        else
            obj.PC1 = 0;

        if (rst1.SCE1 != null && rst1.SCE1 != "")
            obj.e1 = rst1.SCE1;
        else
            obj.e1 = 0;

        if (rst1.SW1 != null && rst1.SW1 != "")
            obj.W1 = rst1.SW1;
        else
            obj.W1 = 0

        if (rst1.SPC2 != null && rst1.SPC2 != "")
            obj.PC2 = rst1.SPC2;
        else
            obj.PC2 = 0;

        if (rst1.SCE2 != null && rst1.SCE2 != "")
            obj.e2 = rst1.SCE2;
        else
            obj.e2 = 0
        if (rst1.SW2 != null && rst1.SW2 != "")
            obj.W2 = rst1.SW2;
        else
            obj.W2 = 0

        if (rst1.SPC3 != null && rst1.SPC3 != "")
            obj.PC3 = rst1.SPC3;
        else
            obj.PC3 = 0;

        if (rst1.SCE3 != null && rst1.SCE3 != "")
            obj.e3 = rst1.SCE3;
        else
            obj.e3 = 0;

        if (rst1.SW3 != null && rst1.SW3 != "")
            obj.W3 = rst1.SW3;
        else
            obj.W3 = 0;

        if (rst1.SPC4 != null && rst1.SPC4 != "")
            obj.PC4 = rst1.SPC4;
        else
            obj.PC4 = 0;

        if (rst1.SCE3 != null && rst1.SCE3 != "")
            obj.e4 = rst1.SCE3;
        else
            obj.e4 = 0;

        if (rst1.SW4 != null && rst1.SW4 != "")
            obj.W4 = rst1.SW4;
        else
            obj.W4 = 0;

        obj.PC5 = 0;
        obj.e5 = 0;
        obj.W5 = 0;

        return obj;
    };

    //added by shwetha
    this.save_KONEdesnForGP = function (rst1) {

        var obj = {};
        if (rst1.GBCE != null && rst1.GBCE != "")
            obj.BCE = rst1.GBCE;
        else
            obj.BCE = 0;
        if (rst1.GPOZ != null && rst1.GPOZ != "")
            obj.POZ = rst1.GPOZ;
        else
            obj.POZ = 0;
        if (rst1.GPC1 != null && rst1.GPC1 != "")
            obj.PC1 = rst1.GPC1;
        else
            obj.PC1 = 0;

        if (rst1.GCE1 != null && rst1.GCE1 != "")
            obj.e1 = rst1.GCE1;
        else
            obj.e1 = 0;

        if (rst1.GW1 != null && rst1.GW1 != "")
            obj.W1 = rst1.GW1;
        else
            obj.W1 = 0

        if (rst1.GPC2 != null && rst1.GPC2 != "")
            obj.PC2 = rst1.GPC2;
        else
            obj.PC2 = 0;

        if (rst1.GCE2 != null && rst1.GCE2 != "")
            obj.e2 = rst1.GCE2;
        else
            obj.e2 = 0
        if (rst1.GW2 != null && rst1.GW2 != "")
            obj.W2 = rst1.GW2;
        else
            obj.W2 = 0

        if (rst1.GPC3 != null && rst1.GPC3 != "")
            obj.PC3 = rst1.GPC3;
        else
            obj.PC3 = 0;

        if (rst1.GCE3 != null && rst1.GCE3 != "")
            obj.e3 = rst1.GCE3;
        else
            obj.e3 = 0;

        if (rst1.GW3 != null && rst1.GW3 != "")
            obj.W3 = rst1.GW3;
        else
            obj.W3 = 0;

        if (rst1.GPC4 != null && rst1.GPC4 != "")
            obj.PC4 = rst1.GPC4;
        else
            obj.PC4 = 0;

        if (rst1.GCE3 != null && rst1.GCE3 != "")
            obj.e4 = rst1.GCE3;
        else
            obj.e4 = 0;

        if (rst1.GW4 != null && rst1.GW4 != "")
            obj.W4 = rst1.GW4;
        else
            obj.W4 = 0;

        obj.PC5 = 0;
        obj.e5 = 0;
        obj.W5 = 0;

        return obj;
    };
    //added by shwetha
    this.SKon_proc = function (this_Bcfl, this_Style) {
        // 'gets values from SkonDesn table based on the current values for STYLE and BCFL - compares BCFL to the CENT column in the table
        var Kavg, Kfind, myDB, rstSoftKoneDesn, strSQL, Style_filter, BCFL_filter;

        var obj = {};
        var myDB = this.SkonDB;

        BCFL_filter = parseFloat((this_Bcfl).toFixed(1))//   BCFL_filter = Round(CDbl(this_Bcfl), 1)

        //If UCase(Trim(this_Style)) = "GLOB" Then
        //Style_filter = "GLOBUS"
        //ElseIf UCase(Trim(this_Style)) = "PELL" Then
        //Style_filter = "PELL"
        //Else
        //Style_filter = "STD"
        //End If

        if (this_Style.toUpperCase() == "GLOB") {
            Style_filter = "GLOBUS";
        }
        else if (this_Style.toUpperCase() == "PELL") {
            Style_filter = "PELL";
        }
        else {
            Style_filter = "STD";
        }
        var strSQL = [];
        // strSQL = "SELECT [SBCE],[SPOZ],[SPC1],[SCE1],[SW1],[SPC2],[SCE2],[SW2],[SPC3],[SCE3],[SW3],[SPC4],[SCE4],[SW4],[CENT]" _
        //        & " FROM SkonDesn WHERE [CENT] = " & BCFL_filter & " AND [DESN] = '" & Style_filter & "'"
        if (this.SkonDB.length > 0) {
            for (var i = 0; i < this.SkonDB.length; i++) {
                if (this.SkonDB[i].CENT == BCFL_filter && this.SkonDB[i].DESN == Style_filter) {
                    strSQL.push(this.SkonDB[i]);
                }
            }
        }
        var rstSoftKoneDesn = strSQL;

        if (rstSoftKoneDesn.length == 1) { //If rstSoftKoneDesn.RecordCount = 1 Then
            obj = this.save_KONEdesn(rstSoftKoneDesn[0]);
        }
        else {
            var strSQL = [];
            var diff_2;
            BCFL_filter = BCFL_filter - 0.01;

            //strSQL = "SELECT [SBCE],[SPOZ],[SPC1],[SCE1],[SW1],[SPC2],[SCE2],[SW2],[SPC3],[SCE3],[SW3],[SPC4],[SCE4],[SW4],[CENT],"
            //_& " ABS(ROUND( ( " & vbCrLf & "( 1/(ABS(([CENT]-" & BCFL_filter & "))*ABS(([CENT]-" & BCFL_filter & "))))*" & vbCrLf _
            // & "(ABS(([CENT]-" & BCFL_filter & "))-(3.303786 - (1/ABS(([CENT]-" & BCFL_filter & ")))))*" & vbCrLf _
            // & "13.5),0))" & " FROM SkonDesn WHERE [DESN] = '" & Style_filter & "' ORDER BY 16 DESC"

            if (this.SkonDB.length > 0) {
                for (var i = 0; i < this.SkonDB.length; i++) {
                    if (this.SkonDB[i].DESN == Style_filter) {
                        strSQL.push(this.SkonDB[i]);
                    }
                }
            }
            rstSoftKoneDesn = strSQL;
            //---------to get nearest cent value w.r.t input BCF_filter value done by shwetha---------//
            var closest = [];
            closest = rstSoftKoneDesn.reduce(function (prev, curr) {
                return (Math.abs(curr.CENT - BCFL_filter) < Math.abs(prev.CENT - BCFL_filter) ? curr : prev);
            });
            //-----------------end
            obj = this.save_KONEdesn(closest);
        }
        return obj;
    };

    this.Desncase_prg = function (This_Type, this_PC, this_Desn, this_Bcfl, this_Kfl, this_dia, this_feature, rgpTData, sph, selectedDesignDia, this_Style) {//, this_Bcfl, this_Kst, this_FM, this_SM, this_InCode, this_Desn_ID
        ////
        //   debugger;
        // If UCase(This_Type) = "D" Then           // '  DESIGN mode
        // If UCase(this_PC) = "S" Then            //  '  SOFT lenses
        var reObject = {};
        var this_Kst = 0;
        ////
        //if (This_Type.toUpperCase() == "D") {
        if (this_Desn == null) {
            return false;
        }

        //if (this_PC == "S") -------- design mode
        //{
        //    if (_.contains(["SV", "SVT", "SVA", "SVSH", "SVTHK", "SVTKSH", "SVTNSH", "HIE", "LOE", "COMP", "BIF", "BIFSH"], this_Desn) || this_feature =="1") { // All are Spherical soft lenses"
        //        BCFL = this.softbc_proc(this_dia, this_Kfl);

        //        reObject.POZ = this_dia - 0.5          //these 3 lines are the same as DO SSPHPC_PROC
        //        reObject.PC1 = 9.6
        //        reObject.W1 = 0.25
        //    }
        //    if (_.contains(["TOR", "FRTT", "TORBIF", "TORKOSH", "TORRGSH", "TORSH", "TORTHK"], this_Desn) || this_feature == "2") { // All are Toric soft lenses"

        //        BCFL = this.softbc_proc(this_dia, this_Kfl)

        //        reObject.POZ = 8                    //'  these 5 lines are the same as DO STORPC_PROC
        //        reObject.PC1 = BCFL
        //        reObject.W1 = ((this_dia - POZ) - 0.5) / 2
        //        reObject.PC2 = 9.6
        //        reObject.W2 = 0.25
        //    }
        //    if (this_Desn == "SV-M") {
        //        //MsgBox "The SV-M design is a used exclusively for Marmoset lenses." & vbCrLf & vbCrLf & "All design parameters in Quadrant 3 must be verified and/or entered manually."

        //        reObject.POZ = 6
        //        reObject.PC1 = 4.8
        //        reObject.W1 = 0.25
        //        reObject.PC2 = 0
        //        reObject.W2 = 0
        //        reObject.ax = 0
        //    }


        //}
        if (this_PC == "G") {

            var Case = this_Desn.toUpperCase();
            Case = Case.replace(/[^a-zA-Z ]/g, "");
            if (Case == "MAD") {

                //FR = this.madDesn_proc(this_Kfl, this_Kst, this_dia); // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                BCFL = 337.5 / (this_Kfl + FR)
                reObject.PC1 = BCFL + 0.7
                reObject.PE1 = -0.6
                reObject.W1 = 0.3
                reObject.W2 = 0.3
                reObject.PC2 = madPC_proc(BCFL, this_dia)
                reObject.PC2 = Int(reObject.PC2 * 10) / 10

            }

            if (Case.includes("KONE")) {
                //debugger;
                // ' this forces BCFL to become (temporarily) the CENTer value from the RkonDESN table based on the average Ks and Style params

                //BCFL = this.RkonBC_proc(this_Kfl, 0, 0, This_Type, 0, this_Desn);//this_Kfl, this_Kst, this_Styl, This_Type, 0

                ////' this test will be true when the prior function call returns a NULL value for CENT in the RkonDesn table

                //if (BCFL!= null) {


                //-------access db code added by shwetha------//     
                var bcfl = this.RkonBC_proc(this_Kfl, this_Kst, this_Style, This_Type, 0);
                if (bcfl != null) {
                    //Run "Rkone_proc", bcfl, this_Styl  '  successful_lookup is set here
                    reObject = this.Rkone_proc(bcfl, this_Style);
                }
                BCFL = 337.5 / this_Kfl;
                //----------end-------------//
                //-------------old code commented by shwetha-----------//
                //var sortable = this.RkonDesn;

                //var rKonDb = [];
                //for (var obj in sortable) {
                //    rKonDb.push([obj, sortable[obj]]);
                //}
                //////
                ////rKonDb.sort(function (a, b) {
                ////    return a[1].AVGK - b[1].AVGK;
                ////});

                ////if (rkonDb != null) {

                //for (var i = 0; i < this.RkonDesn.length; i++) {

                //    if (this.RkonDesn[i].CENT <= this_Bcfl) {

                //        if (this.RkonDesn[i].GBCE != null)
                //            BCE = this.RkonDesn[i].GBCE;
                //        else
                //            BCE = 0;

                //        if (this.RkonDesn[i].GPOZ != null)
                //            POZ = this.RkonDesn[i].GPOZ;
                //        else
                //            POZ = 0;

                //        if (this.RkonDesn[i].GPC1 != null)
                //            reObject.PC1 = this.RkonDesn[i].GPC1;
                //        else
                //            reObject.PC1 = 0;

                //        if (this.RkonDesn[i].GCE1 != null)
                //            PE1 = this.RkonDesn[i].GCE1;
                //        else
                //            PE1 = 0;

                //        if (this.RkonDesn[i].GW1 != null)
                //            W1 = this.RkonDesn[i].GW1;
                //        else
                //            W1 = 0;

                //        if (this.RkonDesn[i].GPC2 != null)
                //            PC2 = this.RkonDesn[i].GPC2;
                //        else
                //            PC2 = 0;

                //        if (this.RkonDesn[i].GCE2 != null)
                //            PE2 = this.RkonDesn[i].GCE2;
                //        else
                //            PE2 = 0;

                //        if (this.RkonDesn[i].GW2 != null)
                //            W2 = this.RkonDesn[i].GW2;
                //        else
                //            W2 = 0;

                //        if (this.RkonDesn[i].GPC3 != null)
                //            PC3 = this.RkonDesn[i].GPC3;
                //        else
                //            PC3 = 0;

                //        if (this.RkonDesn[i].GCE3 != null)
                //            PE3 = this.RkonDesn[i].GCE3;
                //        else
                //            PE3 = 0;

                //        if (this.RkonDesn[i].GW3 != null)
                //            W3 = this.RkonDesn[i].GW3;
                //        else
                //            W3 = 0;

                //        if (this.RkonDesn[i].GPC4 != null)
                //            PC4 = this.RkonDesn[i].GPC4;
                //        else
                //            PC4 = 0;

                //        if (this.RkonDesn[i].GCE4 != null)
                //            PE4 = this.RkonDesn[i].GCE4;
                //        else
                //            PE4 = 0;

                //        if (this.RkonDesn[i].GW4 != null)
                //            W4 = this.RkonDesn[i].GW4;
                //        else
                //            W4 = 0;

                //        PC5 = 0;
                //        PE5 = 0;
                //        W5 = 0;
                //    }


                //}
                //}  //'  successful_lookup is set here
                //}

                // Else
                // MsgBox "Unable to find an RGP KONE design based on these K values and this Style"
                // GoTo DESNCASE_prg_Exit
                // End If
                //end
                BCFL = 337.5 / this_Kfl
            }

            if (_.contains(["SV", "SVT", "FRTT", "BIF", "TORBIF"], Case)) {

                //FR = RsvBC_proc(this_Kfl, this_Kst)  // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                BCFL = 337.5 / (this_Kfl);// + FR     //' same as RsvBC_proc - part 2
                //Run "RGPPC_proc", Pub_dia, "SV" 
                reObject.Obj = this.RGPPC_proc(this_dia, Case, rgpTData)// ' lookup RGP design parameters here & successful_lookup is set here
                reObject.PC1 = ((BCFL + reObject.Obj.PC2) / 2).toFixed(3);              // ' same as RsvPC1_proc

            }

            if (_.contains(["BITOR", "BACT"], Case)) {

                var bcfl = this.RtorBC_proc(this_Kfl);//, this_Kst, this_FM, this_SM  //' same as RtorBC_proc  -  sets FR, SR, BCFL, BCST
                BCFL = bcfl.BCFL;
                reObject.Obj = this.RGPPC_proc(this_dia, Case, rgpTData);    //' lookup RGP design parameters here & successful_lookup is set here
                reObject.PC1 = ((BCFL + reObject.Obj.PC2) / 2).toFixed(3);               //' same as RsvPC1_proc

            }

            if (_.contains(["SVA", "HIE", "LOE"], Case)) {

                //FR = this.RsvBC_proc(this_Kfl, this_Kst)  // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                BCFL = 337.5 / (this_Kfl); // + FR       //' same as RsvBC_proc - part 2
                this.RGPPC_proc(this_dia, Case, rgpTData);    //' lookup RGP design parameters here & successful_lookup is set here
                reObject.PC1 = (BCFL + 0.2).toFixed(3);                     //' same as RasphPC1_proc

            }

            if (Case == "XL") {

                //FR = this.RsvBC_proc(this_Kfl, this_Kst);  // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                BCFL = 337.5 / (this_Kfl); //+ FR      //' same as RsvBC_proc - part 2
                this.RGPPC_proc(this_dia, Case, rgpTData);     //' lookup RGP design parameters here & successful_lookup is set here
                reObject.PC1 = (BCFL + 0.2).toFixed(3);                     //' same as RasphPC1_proc
            }
            if (Case == "K-MAX" || Case.includes("KMAX")) {

                //FR = this.RsvBC_proc(this_Kfl, this_Kst); // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                BCFL = 337.5 / (this_Kfl);  //+FR     //' same as RsvBC_proc - part 2 + FR
                this.RGPPC_proc(this_dia, Case, rgpTData);  //' lookup RGP design parameters here & successful_lookup is set here
                reObject.PC1 = BCFL.toFixed(3);                           //' same as RasphPC1_proc
            }

            if (Case == "COMP") {

                //FR = this.RsvBC_proc(this_Kfl, this_Kst)  // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                BCFL = 337.5 / (this_Kfl); //+ FR              //' same as RsvBC_proc - part 2
                this.RGPPC_proc(this_dia, Case, rgpTData);    //' lookup RGP design parameters here & successful_lookup is set here
                reObject.PC1 = (BCFL + 0.2).toFixed(3);                         //' same as RasphPC1_proc

            }

            if (Case == "REVGEO" || Case.includes("REV")) {

                //FR = this.RsvBC_proc(this_Kfl, this_Kst)      // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                // BCFL = 337.5 / (this_Kfl); //+ FR               //' same as RsvBC_proc - part 2
                //reObject.obj = this.RGPPC_proc(this_dia, Case, rgpTData);     //' lookup RGP design parameters here & successful_lookup is set here
                reObject.PC1 = 7.850;                                   //' comes from as RrevPC1_proc
                reObject.W1 = 1;                                      //' comes from as RrevPC1_proc
                reObject.W2 = 0.4;                                    //' comes from as RrevPC1_proc

            }

            if (this_Desn.length > 3) {
                if (((this_Desn.toUpperCase()).substr(0, 4)) == "KMAX") {
                    //FR = this.RsvBC_proc(this_Kfl, this_Kst);     // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                    BCFL = 337.5 / (this_Kfl);   // + FR       //' same as RsvBC_proc - part 2
                    this.RGPPC_proc(this_dia, Case, rgpTData);   //' lookup RGP design parameters here & successful_lookup is set here
                    reObject.PC1 = BCFL.toFixed(3);                              //' same as RasphPC1_proc
                }
            }

            if (this_Desn.length > 2) {
                if (((this_Desn.toUpperCase()).substr(0, 3)) == "JUP") {
                    // FR = this.RsvBC_proc(this_Kfl, this_Kst);     // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                    BCFL = 337.5 / (this_Kfl);  //+ FR         //' same as RsvBC_proc - part 2
                    this.RGPPC_proc(this_dia, Case, rgpTData);   //' lookup RGP design parameters here & successful_lookup is set here
                    reObject.PC1 = (this.JUPPC_proc1(this_Desn, BCFL)).toFixed(3);      //' set the PC1 value here
                    this.JUPPC_proc2(this_Desn, BCFL);      //' possibly override the PE1 & PE2 values here: JUP188;JUP202;JUP177
                }
                else if ((this_Desn.toUpperCase()).substr(0, 3) == "EUR" || (this_Desn.toUpperCase()).substr(0, 3) == "NOR" || (this_Desn.toUpperCase()).substr(0, 3) == "ELA") {

                    //FR = this.RsvBC_proc(this_Kfl, this_Kst)      // we are commenting this method because of this method is returning always 0. Because wer are ignoreing the kst. 
                    reObject.BCFL = (337.5 / (this_Kfl)); // + FR           // ' same as RsvBC_proc - part 2
                    reObject.Obj = this.RGPPC_proc(this_dia, Case, rgpTData);  // ' lookup RGP design parameters here & successful_lookup is set here
                    reObject.PC1 = (this.JUPPC_proc1(this_Desn, reObject.BCFL)).toFixed(3);       // ' set the PC1 value here

                }
            }

            //if (((this_Desn.toUpperCase()).substr(0, 3)) == "JUP" || (this_Desn.toUpperCase()).substr(0, 3) == "EUR" || (this_Desn.toUpperCase()).substr(0, 3) == "NOR" || (this_Desn.toUpperCase()).substr(0, 3) == "ELA")
            //{
            //    AOZ = this.AOZFPOWER(sph);
            //    reObject.AOZ = AOZ;

            //} else {
            //    AOZ = this.AOZFPOWER(sph);
            //    AOZM = this.RgpAOZ_proc(this_dia);    //' set the RGP AOZM variable
            //    reObject.AOZ = (AOZM + parseFloat(AOZ)).toFixed(3);
            //}


            //If Left(this_design, 3) = "JUP" Or Left(this_design, 3) = "ELA" Then
            if (((this_Desn.toUpperCase()).substr(0, 3)) == "JUP" || (this_Desn.toUpperCase()).substr(0, 3) == "ELA") {
                AOZ = 8.2;
                reObject.AOZ = AOZ;
                //SAC = rstPowerValues(4);
                //ACM = rstPowerValues(5);
            }
            //ElseIf Left(this_design, 3) = "EUR" Then
            else if (((this_Desn.toUpperCase()).substr(0, 3)) == "EUR") {
                //If InStr(1, this_design, "18") > 0 Or InStr(1, this_design, "20") > 0 Then
                if (selectedDesignDia >= 18) {
                    // If PWR <= 0 Then //sph= pwr                 
                    if (sph <= 0) {
                        AOZ = 9;
                        reObject.AOZ = AOZ;
                    }
                    else {
                        AOZ = 8.5;
                        reObject.AOZ = AOZ;
                    }
                    //SAC = rstPowerValues(4)
                    //ACM = rstPowerValues(5)
                }
                else if (selectedDesignDia >= 15 && selectedDesignDia < 18) {
                    //If PWR <= 0 Then
                    if (sph <= 0) {
                        AOZ = 8.5;
                        reObject.AOZ = AOZ;
                    }
                    else {
                        AOZ = 8;
                        reObject.AOZ = AOZ;
                    }
                    // SAC = rstPowerValues(4)
                    //ACM = rstPowerValues(5)
                }
                else {
                    AOZ = this.AOZFPOWER(sph);
                    reObject.AOZ = AOZ;
                    //SAC = rstPowerValues(4)
                    //ACM = rstPowerValues(5)
                }
            }

            else {
                AOZ = this.AOZFPOWER(sph);
                AOZM = this.RgpAOZ_proc(selectedDesignDia);
                reObject.AOZ = parseFloat(AOZ) + parseFloat(AOZM);
                // reObject.AOZ = AOZ;
            }

        }



        //if (this_PC == "O")
        // {  //'  Ortho-K lenses
        //this.OkDesign(This_Type.toString(), CDbl(this_dia));
        // }
        // }

        //if (This_Type.toUpperCase() == "C") {
        //    if (this_PC == "S") {
        //        var Case = this_Desn.toUpperCase();
        //        if (_.contains(["SV", "SVT", "SVA", "SVSH", "SVTHK", "SVTKSH", "SVTNSH", "HIE", "LOE", "COMP", "BIF", "BIFSH"], Case))
        //        { // ' Spherical soft lenses
        //            POZ = this_dia - 0.5         // '  these 3 lines are the same as DO SSPHPC_PROC
        //            PC1 = 9.6
        //            W1 = 0.25
        //        }
        //    }
        //}


        //---------------------------------CONTACT RX mode--------------------//
        // if (This_Type.toUpperCase() == "C") {//---------------C means Contact mode---//
        if (this_PC.toUpperCase() == "S") {//--------- S means soft lens mode---//
            if (this_Kfl != undefined && this_Kfl != null && this_Kfl != "") {
                this_Desn = _.first(this_Desn.split("-")).trim();
                if (_.contains(["SV", "SVT", "SVA", "SVSH", "SVTHK", "SVTKSH", "SVTNSH", "HIE", "LOE", "COMP", "BIF", "BIFSH"], this_Desn)) { // All are Spherical soft lenses"
                    //  BCFL = this.softbc_proc(this_dia, this_Kfl);

                    reObject.POZ = this_dia - 0.5          //these 3 lines are the same as DO SSPHPC_PROC
                    reObject.PC1 = 9.6
                    reObject.W1 = 0.25
                }
                if (_.contains(["TOR", "FRTT", "TORBIF", "TORKOSH", "TORRGSH", "TORSH", "TORTHK"], this_Desn)) { // All are Toric soft lenses"

                    // BCFL = this.softbc_proc(this_dia, this_Kfl)
                    // BCFL = Math.round(this_Bcfl, 1);
                    reObject.POZ = 8                    //'  these 5 lines are the same as DO STORPC_PROC
                    reObject.PC1 = this_Bcfl;
                    reObject.W1 = ((this_dia - reObject.POZ) - 0.5) / 2
                    reObject.PC2 = 9.6
                    reObject.W2 = 0.25
                }
                if (_.contains(["REVGEO", "TORREV", "REVGSH"], this_Desn)) { // All are Toric soft lenses"

                    //BCFL = this.softbc_proc(this_dia, this_Kfl)

                    reObject.POZ = 8;                   //'  these 5 lines are the same as DO STORPC_PROC
                    reObject.PC1 = 8.6;
                    reObject.W1 = ((this_dia - reObject.POZ) - 0.5) / 2;
                    reObject.PC2 = 9.6;
                    reObject.W2 = 0.25;
                }

                if (_.contains(["KONE", "TORKON", "KONESH", "KONSIH"], this_Desn)) { //  Kone soft lenses"

                    // " this test will be true when the BCFL is within a pre-defined range and STYLE is proper for a KONE lens//
                    //  BCFL = this.softbc_proc(this_dia, this_Kfl);
                    BCFL = Math.round(this_Bcfl, 1);

                    if (!isNaN(BCFL) && BCFL >= 4 && BCFL <= 11) {
                        //Run "SKon_proc", bcfl, this_Styl  '  successful_lookup is set here
                        if (this_Style) {
                            reObject = this.SKon_proc(this_Bcfl, this_Style);
                        }
                    }
                    else {
                        reObject.message = "For soft KONE lenses, BC(mm) should be between 4.00 and 11.00";
                    }
                }

                if (this_Desn == "SV-M") {
                    reObject.POZ = 6;
                    reObject.PC1 = 4.8;
                    reObject.W1 = 0.25;
                    reObject.PC2 = 0;
                    reObject.W2 = 0;
                    reObject.ax = 0;
                }



                AOZ = this.AOZFPOWER(sph);
                reObject.AOZ = AOZ
                // SAC = rstPowerValues(1)
                // ACM = rstPowerValues(2)

            }
        }

        //---------------------end--------------------------------------------//
        return reObject;
    }


    /**
     * separate method for AOZ calculation written by priyanka
     * @param {any} sagObj
     */

    this.CalculateAOZ = function (this_PC, this_Desn, sph, selectedDesignDia) {
        var reObject = {};
        if (this_PC == "G") {
            if (((this_Desn.toUpperCase()).substr(0, 3)) == "JUP" || (this_Desn.toUpperCase()).substr(0, 3) == "ELA") {
                AOZ = 8.2;
                reObject.AOZ = AOZ;
            }
            //ElseIf Left(this_design, 3) = "EUR" Then
            else if (((this_Desn.toUpperCase()).substr(0, 3)) == "EUR") {
                //If InStr(1, this_design, "18") > 0 Or InStr(1, this_design, "20") > 0 Then
                if (selectedDesignDia >= 18) {
                    // If PWR <= 0 Then //sph= pwr                 
                    if (sph <= 0) {
                        AOZ = 9;
                        reObject.AOZ = AOZ;
                    }
                    else {
                        AOZ = 8.5;
                        reObject.AOZ = AOZ;
                    }
                }
                else if (selectedDesignDia >= 15 && selectedDesignDia < 18) {
                    //If PWR <= 0 Then
                    if (sph <= 0) {
                        AOZ = 8.5;
                        reObject.AOZ = AOZ;
                    }
                    else {
                        AOZ = 8;
                        reObject.AOZ = AOZ;
                    }

                }
                else {
                    AOZ = this.AOZFPOWER(sph);
                    reObject.AOZ = AOZ;

                }
            }

            else {
                //if (((this_Desn.toUpperCase()).substr(0, 3)) == "LAT")
                //{
                //    AOZ = this.AOZFPOWER(sph);
                //    reObject.AOZ = AOZ;
                //}else {
                // for corneal lenses add AOZM
                AOZ = this.AOZFPOWER(sph);
                // get AOZM
                AOZM = this.RgpAOZ_proc(selectedDesignDia);
                reObject.AOZ = parseFloat(AOZ) + parseFloat(AOZM);
                // }

            }

        }

        if (this_PC.toUpperCase() == "S") {
            AOZ = this.AOZFPOWER(sph);
            reObject.AOZ = AOZ


        }

        return reObject;

    }

    //******************** SAG value formula ***************************************
    // This method will return the YSAG value.
    this.Sag_proc = function (sagObj) {
        //debugger;
        var SSAG = 0;
        var YSAG = 0;
        if (sagObj.EVLU == 1) { sagObj.EVLU = 1.00001 };
        if (sagObj.EVLU == -1) { sagObj.EVLU = -1.00001 }
        var p;// = parseFloat(1 - (sagObj.EVLU * sagObj.EVLU));//.toFixed(3);
        var evlu = parseFloat(sagObj.EVLU * sagObj.EVLU);
        var radx = parseFloat(sagObj.RADX * sagObj.RADX);//.toFixed(3);

        var ddx = parseFloat(sagObj.DDX * sagObj.DDX);//.toFixed(3);

        if (sagObj.EVLU < 0) {
            p = (1 - evlu);//.toFixed(3);
            var condition = (radx - p * ddx / 4);
            //condition = -1;
            if (condition < 0) {
                //MsgBox Can't take the square root of a negative number! Wait...
                //  return "SAG Error � Flatten PC radius (PCx) where x is the Zone of the offending PC.";
            }
            else
                SSAG = (parseFloat(sagObj.RADX) - Math.sqrt(radx - p * ddx / 4)) / p;
        }
        else if (sagObj.EVLU == 0) {
            p = (1 - evlu);
            if ((radx - ddx / 4) < 0) {
                //MsgBox Can't take the square root of a negative number! Wait...
                //  return "SAG Error � Flatten PC radius (PCx) where x is the Zone of the offending PC.";
            }
            else
                SSAG = sagObj.RADX - (Math.sqrt(radx - ddx / 4));
        }
        else if (sagObj.EVLU > 0) {
            p = (1 + evlu);
            if ((radx - p * ddx / 4) < 0) {
                //MsgBox Can't take the square root of a negative number! Wait...
                // return "SAG Error � Flatten PC radius (PCx) where x is the Zone of the offending PC.";
            }
            else
                SSAG = (parseFloat(sagObj.RADX) - (Math.sqrt(radx - p * ddx / 4))) / p;
        }

        return YSAG = YSAG + SSAG;
        //return YSAG;
    };

    // This method will return the XSAG value.
    this.SagPiece_proc = function (sagObj) {
        //debugger
        var XSAG = 0, TSAG = 0;
        var radx = sagObj.RADX * sagObj.RADX;
        var ddx2 = sagObj.DDX2 * sagObj.DDX2;
        if (sagObj.EVLU < 0) {
            var p = parseFloat(1 - (sagObj.EVLU * sagObj.EVLU));//.toFixed(3);
            TSAG = (sagObj.RADX - (Math.sqrt(radx - p * ddx2 / 4))) / p;
        }
        else if (sagObj.EVLU == 0) {
            p = 1 - (sagObj.EVLU * sagObj.EVLU);
            TSAG = sagObj.RADX - (Math.sqrt(radx - ddx2 / 4));
        }
        else if (sagObj.EVLU > 0) {
            p = parseFloat(1 + (sagObj.EVLU * sagObj.EVLU));//.toFixed(3);
            TSAG = (sagObj.RADX - (Math.sqrt(radx - p * ddx2 / 4))) / p;
        }
        return XSAG = XSAG + TSAG;
    };

    //Main method to return SAG value on Selected Eye.
    this.SubSagTotal_proc = function (sagObj) {
        // debugger
        //var sagObj = {};
        var XSAG = 0; var XSAG1 = 0; var XSAG2 = 0; var XSAG3 = 0; var XSAG4 = 0;
        var YSAG = 0; var YSAG1 = 0; var YSAG2 = 0; var YSAG3 = 0; var YSAG4 = 0;
        var EVLU = 0;


        sagObj.BCE = 0;
        sagObj.RADX = sagObj.bcmm;
        sagObj.DDX = sagObj.oz;
        sagObj.EVLU = parseFloat(sagObj.poze);

        var TempFinalSag = 0;

        YSAG = this.Sag_proc(sagObj);
        // console.log('YSAG==' + JSON.stringify(typeof(YSAG)));
        //   if (typeof(YSAG)=="number") {
        var finalSag = 0;
        if (sagObj.pc1 > 0) {
            sagObj.RADX = sagObj.pc1;
            sagObj.DDX = (sagObj.oz) + (2 * sagObj.w1);
            sagObj.EVLU = sagObj.e1;// evlu,ddx2,radx
            YSAG1 = YSAG + this.Sag_proc(sagObj); // adding old value with modified value.
            sagObj.DDX2 = sagObj.oz;
            XSAG1 = this.SagPiece_proc(sagObj);
            TempFinalSag = YSAG1 - XSAG1;
        }
        if (sagObj.pc2 > 0) {
            sagObj.RADX = sagObj.pc2;
            sagObj.DDX = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2);
            sagObj.EVLU = sagObj.e2;//ecc
            YSAG2 = YSAG1 + this.Sag_proc(sagObj);
            sagObj.DDX2 = sagObj.oz + (2 * sagObj.w1);
            XSAG2 = XSAG1 + this.SagPiece_proc(sagObj);
            TempFinalSag = YSAG2 - XSAG2;
        }
        if (sagObj.pc3 > 0) {
            sagObj.RADX = sagObj.pc3;
            sagObj.DDX = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2) + (2 * sagObj.w3);
            sagObj.EVLU = sagObj.e3;//ecc
            YSAG3 = YSAG2 + this.Sag_proc(sagObj);
            sagObj.DDX2 = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2);
            XSAG3 = XSAG2 + this.SagPiece_proc(sagObj);
            TempFinalSag = YSAG3 - XSAG3;
        }
        if (sagObj.pc4 > 0) {
            sagObj.RADX = sagObj.pc4;
            sagObj.DDX = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2) + (2 * sagObj.w3) + (2 * sagObj.w4);
            sagObj.EVLU = sagObj.e4;//ecc
            YSAG4 = YSAG3 + this.Sag_proc(sagObj);
            sagObj.DDX2 = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2) + (2 * sagObj.w3);
            XSAG4 = XSAG3 + this.SagPiece_proc(sagObj);
            TempFinalSag = YSAG4 - XSAG4;

        }
        //XSAG = XSAG1 + XSAG2 + XSAG3 + XSAG4;
        //YSAG = YSAG1 + YSAG2 + YSAG3 + YSAG4;
        //finalSag = YSAG - XSAG;
        finalSag = parseFloat(TempFinalSag).toFixed(3);
        return finalSag;
        //  } else {
        //      return YSAG;
        //  }

    };


    // TPC calculation methods added by priyanka

    // bt defaults db
    this.BT_DefaultsDB = [
        {
            "Design": "ELA15",
            "Diameter": "16.8",
            "PC1": "0",
            "PC2": "100",
            "PC3": "112.5",
            "PC4": "120",
            "PC5": "0"
        },
        {
            "Design": "EUR16",
            "Diameter": "16.8",
            "PC1": "0",
            "PC2": "100",
            "PC3": "112.5",
            "PC4": "120",
            "PC5": "0"
        },
        {
            "Design": "EUR18",
            "Diameter": "20.8",
            "PC1": "0",
            "PC2": "112.5",
            "PC3": "120",
            "PC4": "127.5",
            "PC5": "0"
        },
        {
            "Design": "EUR20",
            "Diameter": "24.8",
            "PC1": "0",
            "PC2": "112.5",
            "PC3": "120",
            "PC4": "127.5",
            "PC5": "0"
        },
        {
            "Design": "JUP13",
            "Diameter": "16.8",
            "PC1": "0",
            "PC2": "100",
            "PC3": "112.5",
            "PC4": "120",
            "PC5": "0"
        },
        {
            "Design": "JUP14",
            "Diameter": "16.8",
            "PC1": "0",
            "PC2": "100",
            "PC3": "112.5",
            "PC4": "120",
            "PC5": "0"
        },
        {
            "Design": "JUP15",
            "Diameter": "16.8",
            "PC1": "0",
            "PC2": "100",
            "PC3": "112.5",
            "PC4": "120",
            "PC5": "0"
        },
        {
            "Design": "JUP16",
            "Diameter": "16.8",
            "PC1": "0",
            "PC2": "100",
            "PC3": "112.5",
            "PC4": "120",
            "PC5": "0"
        },
        {
            "Design": "JUP17",
            "Diameter": "16.8",
            "PC1": "0",
            "PC2": "100",
            "PC3": "112.5",
            "PC4": "120",
            "PC5": "0"
        },
        {
            "Design": "JUP18",
            "Diameter": "20.8",
            "PC1": "0",
            "PC2": "112.5",
            "PC3": "120",
            "PC4": "127.5",
            "PC5": "0"
        },
        {
            "Design": "JUP20",
            "Diameter": "24.8",
            "PC1": "0",
            "PC2": "112.5",
            "PC3": "120",
            "PC4": "127.5",
            "PC5": "0"
        }
    ];


    this.SubSagTotal_procTpc = function (sagObj) {

        //var sagObj = {};
        var XSAG = 0; var XSAG1 = 0; var XSAG2 = 0; var XSAG3 = 0; var XSAG4 = 0;
        var YSAG = 0; var YSAG1 = 0; var YSAG2 = 0; var YSAG3 = 0; var YSAG4 = 0;
        var EVLU = 0;


        sagObj.BCE = 0;
        sagObj.RADX = sagObj.bcmm;
        sagObj.DDX = sagObj.oz;
        sagObj.EVLU = parseFloat(sagObj.poze);

        var TempFinalSag = 0;
        var finalSag = {};
        finalSag.YSAG = this.Sag_proc(sagObj);
        finalSag.XSAG = 0;


        if (sagObj.pc1 > 0) {
            sagObj.RADX = sagObj.pc1;
            sagObj.DDX = (sagObj.oz) + (2 * sagObj.w1);
            sagObj.EVLU = sagObj.e1;// evlu,ddx2,radx
            finalSag.YSAG = finalSag.YSAG + this.Sag_proc(sagObj); // adding old value with modified value.
            sagObj.DDX2 = sagObj.oz;
            finalSag.XSAG = this.SagPiece_proc(sagObj);
            //TempFinalSag = YSAG - XSAG;
        }
        if (sagObj.pc2 > 0) {
            sagObj.RADX = sagObj.pc2;
            sagObj.DDX = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2);
            sagObj.EVLU = sagObj.e2;//ecc
            finalSag.YSAG = finalSag.YSAG + this.Sag_proc(sagObj);
            sagObj.DDX2 = sagObj.oz + (2 * sagObj.w1);
            finalSag.XSAG = finalSag.XSAG + this.SagPiece_proc(sagObj);
            // TempFinalSag = YSAG - XSAG;
        }
        if (sagObj.pc3 > 0) {
            sagObj.RADX = sagObj.pc3;
            sagObj.DDX = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2) + (2 * sagObj.w3);
            sagObj.EVLU = sagObj.e3;//ecc
            finalSag.YSAG = finalSag.YSAG + this.Sag_proc(sagObj);
            sagObj.DDX2 = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2);
            finalSag.XSAG = finalSag.XSAG + this.SagPiece_proc(sagObj);
            // TempFinalSag = YSAG - XSAG;
        }
        if (sagObj.pc4 > 0) {
            sagObj.RADX = sagObj.pc4;
            sagObj.DDX = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2) + (2 * sagObj.w3) + (2 * sagObj.w4);
            sagObj.EVLU = sagObj.e4;//ecc
            finalSag.YSAG = finalSag.YSAG + this.Sag_proc(sagObj);
            sagObj.DDX2 = sagObj.oz + (2 * sagObj.w1) + (2 * sagObj.w2) + (2 * sagObj.w3);
            finalSag.XSAG = finalSag.XSAG + this.SagPiece_proc(sagObj);
            //TempFinalSag = YSAG - XSAG;

        }


        return finalSag;
    };

    this.calculateSagTPC = function (tpcObj) {

        if (tpcObj.dia >= 18) {
            var obj = {};
            obj.labelTpc2 = tpcObj.tpc2;
            obj.actualTpc2 = (obj.labelTpc2 * 112.5) / 100;
            obj.tpc3 = (obj.actualTpc2 * 120.0) / 112.5;
            obj.tpc4 = (obj.tpc3 * 127.5) / 120;
            // console.log('1' + JSON.stringify(obj));
            return obj;
        }
        else if (tpcObj.dia < 18) {
            var obj = {};
            obj.labelTpc2 = tpcObj.tpc2;
            obj.actualTpc2 = obj.labelTpc2;
            obj.tpc3 = (obj.actualTpc2 * 112.5) / 100;
            obj.tpc4 = (obj.tpc3 * 120.0) / 112.5;
            // console.log('2' + JSON.stringify(obj));
            return obj;
        }


    }

    this.SubR_toric_setVals = function (mainObject) {
        // this code will call (5) outside subroutines/functions (see separate documents)
        //1.)	Sub getSAGpieces()
        //2.)	Function get_dZ_microns()
        //3.)	Sub set_default_dZ()
        //4.)	Sub Store4_proc()
        //5.)	Sub Compute_dR()

        if (mainObject.Me_toric == 0 || mainObject.Me_toric == null) {
            //  ' (3) dSag values
            mainObject.Me_FC3 = null
            mainObject.Me_FD3 = null
            mainObject.Me_Fe3 = null

            //' (3) dR values
            mainObject.Me_FC4 = null
            mainObject.Me_FD4 = null
            mainObject.Me_Fe4 = null

            //' Back Toric Axis
            mainObject.Me_Btor = null

            mainObject.Me_toric = null
        }
        else {
            var prior_Zval;
            var thisDeltaZ;
            prior_Zval = mainObject.Orig_BT_val == null ? 0 : mainObject.Orig_BT_val;

            if (prior_Zval == 0);
            //' fall through
            else {
                thisDeltaZ = mainObject.Me_toric - prior_Zval;
            }



            if (prior_Zval == 0) {

                mainObject.IsSmap = false;
                mainObject.isReDo = true;
                mainObject.Other_parm = null;
                //  mainObject.statusFlag = null;
                //this.getSAGpieces("R", False, true, '', "BackToric")
                var getSagPieces = this.getSAGpieces(mainObject);
                mainObject = getSagPieces;
            }

            else {
                //  this.get_dZ_microns("R", False)
                mainObject.IsSmap = false;
                mainObject.isReDo = false;
                mainObject.Other_parm = null;
                mainObject.statusFlag = null;
                var getDzMicrons = this.get_dZ_microns(mainObject);
                mainObject = getDzMicrons;
            }

            // these temp values not used anywhere so commenting 

            //tempVar0 = BTz0;
            //tempVar1 = BTz1;
            //tempVar2 = BTz2;
            //tempVar3 = getSagPieces.BTz3;
            //tempVar4 = getSagPieces.BTz4;

            //tempVar10 = getSagPieces.z0;
            //tempVar11 = getSagPieces.z1;
            //tempVar12 = getSagPieces.z2;
            //tempVar13 = getSagPieces.z3;
            //tempVar14 = getSagPieces.z4;

            //  ' these 'dZx' values are the same values that are found in the calculator column 'dZ' when going from dR to dZ
            mainObject.dZ0 = 0;
            if (prior_Zval == 0);

            else {
                //tempVar21 = dZ1
                //tempVar22 = dZ2
                //tempVar23 = dZ3
                //tempVar24 = dZ4

            }
            if (Math.abs(thisDeltaZ) > 25) {
                //  var defaultdzObj = this.set_default_dZ("R", Me_FC3, Me_dia, Me_Desn, thisDeltaZ);
                mainObject.deltaZ = thisDeltaZ;
                mainObject.Other_parm = null;
                mainObject.statusFlag = null;
                mainObject.this_BT = mainObject.Me_FC3;
                var defaultdzObj = this.set_default_dZ(mainObject);
                mainObject = defaultdzObj;
            }

            else {
                mainObject.deltaZ = null;
                mainObject.Other_parm = null;
                mainObject.this_BT = mainObject.Me_toric;
                mainObject.statusFlag = null;
                //  var defaultdzObj = this.set_default_dZ("R", Me_toric, Me_dia, Me_Desn);
                var defaultdzObj = this.set_default_dZ(mainObject);
                mainObject = defaultdzObj;
            }



            // this.Store4_proc();

            if (Math.abs(thisDeltaZ) > 25) {
                mainObject.delta_dZ = thisDeltaZ;
                // var computeDrObj=  this.Compute_dR("R", thisDeltaZ)
                var computeDrObj = this.Compute_dR(mainObject);
                mainObject = computeDrObj;
                //mainObject.Me_FC3 = parseFloat(Math.round(mainObject.dZ2 * 1000).toFixed(1));
                //mainObject.Me_FD3 =parseFloat(Math.round(mainObject.dZ3 * 1000).toFixed(1));
                //mainObject.Me_Fe3 = parseFloat(Math.round(mainObject.dZ4 * 1000).toFixed(1));
                mainObject.Me_FC3 = parseFloat((mainObject.dZ2 * 1000).toFixed(1));
                mainObject.Me_FD3 = parseFloat((mainObject.dZ3 * 1000).toFixed(1));
                mainObject.Me_Fe3 = parseFloat((mainObject.dZ4 * 1000).toFixed(1));
            }

            else {
                mainObject.delta_dZ = null;
                var computeDrObj = this.Compute_dR(mainObject);
                mainObject = computeDrObj;
                //var computeDrObj = this.Compute_dR("R");
                //mainObject.Me_FC3 = Math.round(mainObject.dZ2 * 1000).toFixed(1);
                //mainObject.Me_FD3 = Math.round((parseFloat(mainObject.dZ2) + parseFloat(mainObject.dZ3)) * 1000).toFixed(1);
                //mainObject.Me_Fe3 = Math.round((parseFloat(mainObject.dZ2) + parseFloat(mainObject.dZ3) + parseFloat(mainObject.dZ4)) * 1000).toFixed(1);

                mainObject.Me_FC3 = (mainObject.dZ2 * 1000).toFixed(1);
                mainObject.Me_FD3 = ((parseFloat(mainObject.dZ2) + parseFloat(mainObject.dZ3)) * 1000).toFixed(1);
                mainObject.Me_Fe3 = ((parseFloat(mainObject.dZ2) + parseFloat(mainObject.dZ3) + parseFloat(mainObject.dZ4)) * 1000).toFixed(1);

            }


        }
        return mainObject;
    }


    // this.getSAGpieces = function (this_eye, IsSmap, isReDo, Other_parm, statusFlag) {
    this.getSAGpieces = function (SagObj) {
        // �  this code will call (1) outside subroutine (see separate document)
        //1.)	Sub SagTotal_proc() 
        var i = 0, j = 0, q = 0;
        var this_XSAG = 0, this_YSAG = 0, my_SAG = 0, old_SAG = 0, new_SAG = 0, delta_Sag = 0, running_SAG = 0;
        var z_0, z_1, z_2, z_3, z_4, z_5;
        var BTz_0, BTz_1, BTz_2, BTz_3, BTz_4, BTz_5;


        while (j < 2) {
            while (i < 5) {
                //' set public variables

                if (SagObj.Other_parm == "BCFL")
                    SagObj.bcfl = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_bcfl;
                else
                    SagObj.bcfl = SagObj.Me_bcfl;


                if (i < 1)
                    SagObj.PC1 = 0;
                else {
                    if (SagObj.Other_parm == "PC1")
                        SagObj.PC1 = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_pc1;
                    else
                        SagObj.PC1 = SagObj.Me_pc1;
                }


                if (i < 2)
                    SagObj.PC2 = 0;
                else {
                    if (j < 1) {
                        if (SagObj.Other_parm == "PC2")
                            SagObj.PC2 = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_pc2;
                        else
                            SagObj.PC2 = SagObj.Me_pc2;

                    }


                    else {
                        if (SagObj.Other_parm == "PC2")
                            SagObj.PC2 = (SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_pc2) + ((SagObj.Me_FC4 == null || SagObj.statusFlag == null) ? 0 : SagObj.Me_FC4);
                        else
                            SagObj.PC2 = parseFloat(SagObj.Me_pc2) + ((SagObj.Me_FC4 == null) ? 0 : parseFloat(SagObj.Me_FC4));
                    }
                }


                if (i < 3)
                    SagObj.PC3 = 0;
                else {

                    if (j < 1) {
                        if (SagObj.Other_parm == "PC3")
                            SagObj.PC3 = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_pc3;
                        else
                            SagObj.PC3 = SagObj.Me_pc3;
                    }


                    else {
                        if (SagObj.Other_parm == "PC3")
                            SagObj.PC3 = (SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_pc3) + ((SagObj.Me_FD4 == null || SagObj.statusFlag == null) ? 0 : SagObj.Me_FD4);
                        else
                            SagObj.PC3 = parseFloat(SagObj.Me_pc3) + ((SagObj.Me_FD4 == null) ? 0 : parseFloat(SagObj.Me_FD4));
                    }

                }

                if (i < 4)
                    SagObj.PC4 = 0;
                else {


                    if (j < 1) {

                        if (SagObj.Other_parm == "PC4")
                            SagObj.PC4 = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_pc4;
                        else
                            SagObj.PC4 = SagObj.Me_pc4;
                    }
                    else {
                        if (SagObj.Other_parm == "PC4")
                            SagObj.PC4 = (SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_pc4) + (((SagObj.Me_Fe4 == null) || SagObj.statusFlag == null) ? 0 : SagObj.Me_Fe4)
                        else
                            SagObj.PC4 = parseFloat(SagObj.Me_pc4) + ((SagObj.Me_Fe4 == null) ? 0 : parseFloat(SagObj.Me_Fe4));

                    }

                }

                SagObj.PC5 = 0;


                if (SagObj.Other_parm == "POZ")
                    SagObj.POZ = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_poz;
                else
                    SagObj.POZ = SagObj.Me_poz;


                if (SagObj.Other_parm == "W1")
                    SagObj.W1 = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_w1;
                else
                    SagObj.W1 = SagObj.Me_w1;


                if (SagObj.Other_parm == "W2")
                    SagObj.W2 = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_w2;
                else
                    SagObj.W2 = SagObj.Me_w2;


                if (SagObj.Other_parm == "W3")
                    SagObj.W3 = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_w3;
                else
                    SagObj.W3 = SagObj.Me_w3;


                if (SagObj.Other_parm == "W4")
                    SagObj.W4 = SagObj.statusFlag == "B4" ? SagObj.Orig_BT_val : SagObj.Me_w4;
                else
                    SagObj.W4 = SagObj.Me_w4;

                // call sag totalproc
                var sag = {};
                sag.bcmm = SagObj.Me_bcfl;
                sag.oz = SagObj.Me_poz;
                sag.poze = SagObj.Me_be;
                sag.pc1 = SagObj.PC1;
                sag.pc2 = SagObj.PC2;
                sag.pc3 = SagObj.PC3;
                sag.pc4 = SagObj.PC4;
                sag.e1 = SagObj.PE1;
                sag.e2 = SagObj.PE2;
                sag.e3 = SagObj.PE3;
                sag.e4 = SagObj.PE4;
                sag.w1 = SagObj.W1;
                sag.w2 = SagObj.W2;
                sag.w3 = SagObj.W3;
                sag.w4 = SagObj.W4;

                var sagvalues = this.SubSagTotal_procTpc(sag);
                var XSAG = sagvalues.XSAG == "NaN" ? 0 : parseFloat(sagvalues.XSAG);
                var YSAG = sagvalues.YSAG == "NaN" ? 0 : parseFloat(sagvalues.YSAG);

                my_SAG = YSAG - XSAG;

                //  my_SAG = SagObj.sag;

                if (j < 1) {
                    if (j == 0) {
                        if (i == 0)
                            SagObj.z0 = my_SAG;
                        else if (i == 1)
                            SagObj.z1 = my_SAG;
                        else if (i == 2)
                            SagObj.z2 = my_SAG;
                        else if (i == 3)
                            SagObj.z3 = my_SAG;
                        else if (i == 4)
                            SagObj.z4 = my_SAG;
                        else if (i == 5)
                            SagObj.z5 = my_SAG;
                    }

                }
                else {

                    if (j == 1) {


                        if (i == 0)
                            SagObj.BTz0 = my_SAG;
                        else if (i == 1) {
                            if (SagObj.Other_parm == null || SagObj.dZ1 == 0)
                                SagObj.BTz1 = my_SAG;
                            else
                                SagObj.BTz1 = SagObj.z1 + SagObj.dZ1;
                        }

                        else if (i == 2) {
                            if (SagObj.Other_parm == null || SagObj.dZ2 == 0)
                                SagObj.BTz2 = my_SAG;
                            else
                                SagObj.BTz2 = SagObj.z2 + SagObj.dZ2;
                        }

                        else if (i == 3) {
                            if (SagObj.Other_parm == null || SagObj.dZ3 == 0)
                                SagObj.BTz3 = my_SAG;
                            else
                                SagObj.BTz3 = SagObj.z3 + SagObj.dZ2 + SagObj.dZ3;
                        }



                        else if (i == 4) {
                            if (SagObj.Other_parm == null || SagObj.dZ4 == 0)
                                SagObj.BTz4 = my_SAG;
                            else
                                SagObj.BTz4 = SagObj.z4 + SagObj.dZ2 + SagObj.dZ3 + SagObj.dZ4;

                        }


                        else if (i == 5) {
                            if (SagObj.Other_parm == null)
                                SagObj.BTz5 = my_SAG;
                            else
                                SagObj.BTz5 = SagObj.z5 + SagObj.dZ5;
                        }

                    }
                    // 'MsgBox "Total 'BT adjusted' SAG through zone " & CStr(i) & " is " & Format(CStr(my_SAG), "0.0000")
                }


                i = i + 1;
                q = i;

            }
            //Wend

            if (j == 0) {
                old_SAG = parseFloat(my_SAG);

            }
            i = 0;

            if (SagObj.isReDo == true && (SagObj.statusFlag == "Back Toric")) {
                j = 1;

            }
            j = j + 1;
        }

        new_SAG = parseFloat(my_SAG);
        SagObj.delta_Sag = new_SAG - old_SAG;
        //Wend
        return SagObj;
    }


    //this.get_dZ_microns = function (this_eye, ReDo) {
    this.get_dZ_microns = function (dzMicronsObj) {
        // ' also known as the R-to-Z algorithm

        //�  this code will call (3) outside subroutines (see separate documents)
        //1.)	Sub getSAGpieces()
        //2.)	Sub set_R_dSag()
        //3.)	Sub set_L_dSag()


        var this_dZ0;
        var this_dZ1;
        var this_dZ2;
        var this_dZ3;
        var this_dZ4;

        var this_Zmin0;
        var this_Zmin1;
        var this_Zmin2;
        var this_Zmin3;
        var this_Zmin4;

        var user_response;

        if (dzMicronsObj.isReDo) {
            dzMicronsObj.IsSmap = false;
            dzMicronsObj.isReDo = true;
            dzMicronsObj.Other_parm = null;
            dzMicronsObj.statusFlag = null;

            var getSagObj1 = this.getSAGpieces(dzMicronsObj);
            dzMicronsObj = getSagObj1;

        }

        else {
            dzMicronsObj.IsSmap = false;
            dzMicronsObj.isReDo = false;
            dzMicronsObj.Other_parm = null;
            dzMicronsObj.statusFlag = null;
            var getSagObj1 = this.getSAGpieces(dzMicronsObj);
            dzMicronsObj = getSagObj1;
        }



        this_btz0 = dzMicronsObj.BTz0;
        this_btz1 = dzMicronsObj.BTz1;
        this_btz2 = dzMicronsObj.BTz2;
        this_btz3 = dzMicronsObj.BTz3;
        this_btz4 = dzMicronsObj.BTz4;

        this_dZ0 = parseFloat((dzMicronsObj.BTz0 - dzMicronsObj.z0).toFixed(5));
        this_dZ1 = parseFloat((dzMicronsObj.BTz1 - dzMicronsObj.z1 - this_dZ0).toFixed(5));
        this_dZ2 = parseFloat((dzMicronsObj.BTz2 - dzMicronsObj.z2 - (this_dZ1 + this_dZ0)).toFixed(5));
        this_dZ3 = parseFloat((dzMicronsObj.BTz3 - dzMicronsObj.z3 - (this_dZ2 + this_dZ1 + this_dZ0)).toFixed(5));
        this_dZ4 = parseFloat((dzMicronsObj.BTz4 - dzMicronsObj.z4 - (this_dZ3 + this_dZ2 + this_dZ1 + this_dZ0)).toFixed(5));


        //this_dZ0 = parseFloat(Math.round(dzMicronsObj.BTz0 - dzMicronsObj.z0).toFixed(5));
        //this_dZ1 = parseFloat(Math.round(dzMicronsObj.BTz1 - dzMicronsObj.z1 - this_dZ0).toFixed(5));
        //this_dZ2 = parseFloat(Math.round(dzMicronsObj.BTz2 - dzMicronsObj.z2 - (this_dZ1 + this_dZ0)).toFixed(5));
        //this_dZ3 = parseFloat(Math.round(dzMicronsObj.BTz3 - dzMicronsObj.z3 - (this_dZ2 + this_dZ1 + this_dZ0)).toFixed(5));
        //this_dZ4 = parseFloat(Math.round(dzMicronsObj.BTz4 - dzMicronsObj.z4 - (this_dZ3 + this_dZ2 + this_dZ1 + this_dZ0)).toFixed(5));

        dzMicronsObj.dZ0 = this_dZ0;
        dzMicronsObj.dZ1 = this_dZ1;
        dzMicronsObj.dZ2 = this_dZ2;
        dzMicronsObj.dZ3 = this_dZ3;
        dzMicronsObj.dZ4 = this_dZ4;

        this_Zmin0 = dzMicronsObj.z0 + this_dZ0;
        this_Zmin1 = dzMicronsObj.z1 + this_dZ1;
        this_Zmin2 = dzMicronsObj.z2 + this_dZ2;
        this_Zmin3 = dzMicronsObj.z3 + this_dZ3;
        this_Zmin4 = dzMicronsObj.z4 + this_dZ4;

        if (this_dZ2 != null) {
            if (dzMicronsObj.this_eye != null) {
                //dzMicronsObj.Me_FC3 =Math.round(dzMicronsObj.dZ2 * 1000).toFixed(4);
                //dzMicronsObj.Me_FD3 = Math.round((dzMicronsObj.dZ2 * 1000) + dzMicronsObj.dZ3 == null ? 0 : (dzMicronsObj.dZ3 * 1000)).toFixed(4);
                //dzMicronsObj.Me_Fe3 = Math.round((dzMicronsObj.dZ2 * 1000) + (dzMicronsObj.dZ3 == null ? 0 : (dzMicronsObj.dZ3 * 1000)) + (dzMicronsObj.dZ4 == null ? 0 : (dzMicronsObj.dZ4 * 1000))).toFixed(4);

                dzMicronsObj.Me_FC3 = (dzMicronsObj.dZ2 * 1000).toFixed(4);
                dzMicronsObj.Me_FD3 = (parseFloat(dzMicronsObj.dZ2 * 1000) + parseFloat(dzMicronsObj.dZ3 == null ? 0 : (dzMicronsObj.dZ3 * 1000))).toFixed(4);
                dzMicronsObj.Me_Fe3 = (parseFloat(dzMicronsObj.dZ2 * 1000) + parseFloat(dzMicronsObj.dZ3 == null ? 0 : (dzMicronsObj.dZ3 * 1000)) + parseFloat(dzMicronsObj.dZ4 == null ? 0 : (dzMicronsObj.dZ4 * 1000))).toFixed(4);


                if (dzMicronsObj.Me_toric != null);
                //  ' fall through
                else {
                    var rdsag = this.set_dSag(dzMicronsObj);
                    dzMicronsObj = rdsag;
                }
            }

        }
        if (dzMicronsObj.this_eye != null) {
            if (dzMicronsObj.z5 == null || dzMicronsObj.z5 == 0) {
                if (dzMicronsObj.z4 == null || dzMicronsObj.z4 == 0) {
                    if (dzMicronsObj.z3 == null || dzMicronsObj.z3 == 0) {
                        if (dzMicronsObj.z2 == null || dzMicronsObj.z2 == 0);
                        // ' fall through - Sag not calculated
                        //' leave Sag the way it was
                        else
                            //  dzMicronsObj.Me_Sag = Math.round(dzMicronsObj.z2).toFixed(4);
                            dzMicronsObj.Me_Sag = (dzMicronsObj.z2).toFixed(4);
                    }
                    else
                        //  dzMicronsObj.Me_Sag = Math.round(dzMicronsObj.z3).toFixed(4);
                        dzMicronsObj.Me_Sag = (dzMicronsObj.z3).toFixed(4);
                }
                else
                    // dzMicronsObj.Me_Sag = Math.round(dzMicronsObj.z4).toFixed(4);
                    dzMicronsObj.Me_Sag = (dzMicronsObj.z4).toFixed(4);
            }
            else
                //dzMicronsObj.Me_Sag = Math.round(dzMicronsObj.z5).toFixed(4);
                dzMicronsObj.Me_Sag = (dzMicronsObj.z5).toFixed(4);

        }
        //else if (this_eye == "L")
        //{
        //    if (z5 == null || z5 == 0) {
        //        if (z4 == null || z4 == 0) {
        //            if (z3 == null || z3 == 0) {
        //                if (z2 == null || z2 == 0);
        //                    // ' fall through - Sag not calculated
        //                    // ' leave Sag the way it was
        //                else
        //                    MeL_Sag = Math.round(z2).toFixed(4);
        //            }
        //            else
        //                MeL_Sag = Math.round(z3).toFixed(4);
        //        }
        //        else
        //            MeL_Sag = Math.round(z4).toFixed(4);
        //    }
        //    else
        //        MeL_Sag = Math.round(z5).toFixed(4);
        //}

        return dzMicronsObj;

    }

    this.set_dSag = function (rDsagObj) {
        // �  this code will call (1) outside subroutine (see separate document)
        //1.)	Sub Fix_BT_Remarks()
        var rst;
        //var myDB;
        //var strSQL;
        var temp_toric;
        //  Set myDB = CurrentDb
        // strSQL = "SELECT Desncost.Size FROM Desncost WHERE Desnbase = '" & Me_Desn & "'"
        //  Set rst = myDB.OpenRecordset(strSQL)
        if (rDsagObj.Me_Desn != null) {
            //  ' set displayed value for Back Toric
            // '  Will NOT be the same as delta Sag at zone 2 for larger diameter lenses
            // 'If rst(0) >= 18 Then
            // if (Left(Me_Desn, 5) == "EUR18" || Left(Me_Desn, 5) == "EUR20" || Left(Me_Desn, 5) == "JUP18" || Left(Me_Desn, 5) == "JUP20") {
            if (rDsagObj.Me_Desn.trim() == "Europa-18" || rDsagObj.Me_Desn.trim() == "Europa-20" || rDsagObj.Me_Desn.trim() == "Jupiter-18" || rDsagObj.Me_Desn.trim() == "Jupiter-20") {
                if (rDsagObj.Me_Fe5 == 1 || rDsagObj.Me_FD5 == 1)
                    // rDsagObj.Me_toric = Math.round((rDsagObj.Me_FC3 / 112.5) * 100);
                    rDsagObj.Me_toric = Math.round((rDsagObj.Me_FC3 / 112.5) * 100);
                else {
                    rDsagObj.Me_toric = ((rDsagObj.Me_FC3 / 112.5) * 100).toFixed(4);
                    rDsagObj.Me_toric = ((rDsagObj.Me_toric / 50).toFixed(1)) * 50;
                    //  rDsagObj.Me_toric = Math.round((rDsagObj.Me_FC3 / 112.5) * 100).toFixed(4);
                    //  rDsagObj.Me_toric = Math.round((rDsagObj.Me_toric / 50).toFixed(1)) * 50;
                }

                // End If
            }
            else {
                if (rDsagObj.Me_Fe5 == 1 || rDsagObj.Me_FD5 == 1)

                    rDsagObj.Me_toric = Math.round(parseFloat(rDsagObj.Me_FC3));
                else {
                    rDsagObj.Me_toric = parseFloat((rDsagObj.Me_FC3)).toFixed(4);
                    rDsagObj.Me_toric = ((rDsagObj.Me_toric / 50).toFixed(1)) * 50;
                    //  rDsagObj.Me_toric = Math.round(rDsagObj.Me_FC3).toFixed(4);
                    //  rDsagObj.Me_toric = Math.round((rDsagObj.Me_toric / 50).toFixed(1)) * 50;
                }

            }
            // End If
            // End If
            rDsagObj.temp_toric = rDsagObj.Me_toric;
            rDsagObj.this_old_val = rDsagObj.temp_toric;
            rDsagObj.this_new_val = rDsagObj.Me_toric;
            rDsagObj.this_dZ2 = Math.round(parseFloat(rDsagObj.Me_FC3));
            // var btRemarks= this.Fix_BT_Remarks("R", Me_Desn, temp_toric, Me_toric, Round(Me_FC3, 0));
            var btRemarks = this.Fix_BT_Remarks(rDsagObj);
            rDsagObj = btRemarks;
        }
        else {
            rDsagObj.Me_toric = Math.round(Me_FC3);
        }
        return rDsagObj;
        // MsgBox "An error occurred when trying to calculate the Back Toric value for the RIGHT lens." & _
        // vbCrLf & vbCrLf & "Please contact the database administrator to get this fixed." & _
        // vbCrLf & vbCrLf & "Work Order " & CStr(Me.Order_no) & vbCrLf, vbCritical, "ERROR: Desncost Lookup Failed - RIGHT Lens"

        //  End If

        // If Not rst Is Nothing Then
        //  rst.Close
        // Set rst = Nothing
        // End If

        //End Sub

    }



    //  this.set_default_dZ=function(this_eye, this_BT, this_dia, this_design, deltaZ, Other_parm ,statusFlag)
    this.set_default_dZ = function (defaultObj) {

        // �  this code will call (1) outside subroutine  (see separate document)
        //1.)	Sub getSAGpieces()

        // ' also known as the Z-to-R algorithm for non-sMap lenses AND sMap modification lenses

        // ' Other_parm is TRUE when the function is called from one of the other parameters, other than R_toric [ also known as dZ or delta Sag at Zone 2]
        // ' BT1 through BT5 are Back Toric dSAG values from lookup table
        var this_BT1;
        var this_BT2;
        var this_BT3;
        var this_BT4;
        var this_BT5;
        var this_deltaSAG2;
        var this_deltaSAG3;
        var this_deltaSAG4;
        var this_ratio;

        var rst;
        var myDB;
        var strSQL;

        if (defaultObj.Other_parm == null) {



            // Set myDB = CurrentDb
            // 'this_dia = 18  ' hard code value for testing

            // '      "WHERE (Diameter >= " & this_dia & ") AND (Design = '" & Left(trim(this_design), 5) & "') "

            //    strSQL = "SELECT Diameter, PC1, PC2, PC3, PC4, PC5 FROM BT_defaults " & vbCrLf & _
            //"WHERE Design = '" & Left(trim(this_design), 5) & "' " & vbCrLf & _
            //"ORDER BY Diameter"

            //  'MsgBox strSQL
            // Set rst = myDB.OpenRecordset(strSQL)
            var mydb = this.BT_DefaultsDB;
            var currentDb = [];
            var des = defaultObj.Me_Desn.substr(0, 3);
            var dia = defaultObj.Me_Desn.split('-');
            var design = des + dia[1].trim().substr(0, 2);

            for (var i = 0; i < mydb.length; i++) {

                if (mydb[i].Design.toLowerCase() == design.toLowerCase()) {
                    currentDb.push(mydb[i]);

                    //  return currentDb;
                }
            }

            // if(rst.EOF && rst.BOF);
            if (currentDb == [] || currentDb.length == 0) {
                return defaultObj;

            }
            // MsgBox "There was a problem locating the default values for BT-2, BT-3, etc.", vbCritical, "Contact Database Administrator"

            // GoTo set_default_dZ_Exit
            else {
                if (defaultObj.deltaZ != null) {
                    if (defaultObj.this_BT == 0 && defaultObj.deltaZ == 0) {
                        if (defaultObj.this_eye != null) {
                            this_ratio = defaultObj.Me_toric / 100;
                        }
                    }
                    else {
                        this_ratio = defaultObj.deltaZ / 100;
                    }
                }
                else {
                    this_ratio = defaultObj.this_BT / 100;
                }

                // End If

                this_deltaSAG2 = (currentDb[0].PC2 * this_ratio) / 1000;
                this_deltaSAG3 = (currentDb[0].PC3 * this_ratio) / 1000;
                this_deltaSAG4 = (currentDb[0].PC4 * this_ratio) / 1000;

                if (defaultObj.deltaZ != null) {
                    if (defaultObj.deltaZ != 0) {
                        defaultObj.dZ3 = parseFloat(defaultObj.dZ3) + parseFloat(defaultObj.dZ2);
                        defaultObj.dZ4 = parseFloat(defaultObj.dZ4) + parseFloat(defaultObj.dZ3);
                        defaultObj.dZ2 = parseFloat(defaultObj.dZ2) + parseFloat(this_deltaSAG2);
                        defaultObj.dZ3 = parseFloat(defaultObj.dZ3) + parseFloat(this_deltaSAG3);
                        defaultObj.dZ4 = parseFloat(defaultObj.dZ4) + parseFloat(this_deltaSAG4);
                    }
                    else {
                        defaultObj.dZ2 = this_deltaSAG2;
                        defaultObj.dZ3 = this_deltaSAG3;
                        defaultObj.dZ4 = this_deltaSAG4;
                    }
                }
                // End If
                else {
                    defaultObj.dZ2 = this_deltaSAG2;
                    defaultObj.dZ3 = this_deltaSAG3 - this_deltaSAG2;
                    defaultObj.dZ4 = this_deltaSAG4 - this_deltaSAG3;
                }

                if (defaultObj.deltaZ == null) {
                    if (defaultObj.this_eye != null) {
                        if (defaultObj.Me_FD5 == 1 || defaultObj.Me_Fe5 == 1) {
                            //' sMap
                            // var obj=  this.getSAGpieces(defaultObj.this_eye, False);
                            defaultObj.IsSmap = false;
                            defaultObj.isReDo = false;
                            defaultObj.Other_parm = null;
                            defaultObj.statusFlag = null;
                            defaultObj = this.getSAGpieces(defaultObj);
                            //(this_eye, IsSmap, isReDo, Other_parm, statusFlag)
                        }

                        else {
                            defaultObj.IsSmap = false;
                            defaultObj.isReDo = false;
                            defaultObj.Other_parm = null;
                            // defaultObj.statusFlag = null;
                            // var obj= this.getSAGpieces(defaultObj.this_eye, False,'' ,'',statusFlag)
                            defaultObj = this.getSAGpieces(defaultObj);
                        }

                    }
                }

                else if (defaultObj.deltaZ == 0) {
                    if (defaultObj.this_eye != null) {
                        if (defaultObj.Me_FD5 == 1 || defaultObj.Me_Fe5 == 1) {
                            //' sMap
                            //  defaultObj= this.getSAGpieces(this_eye, False);
                            defaultObj.IsSmap = false;
                            defaultObj.isReDo = false;
                            defaultObj.Other_parm = null;
                            defaultObj.statusFlag = null;
                            defaultObj = this.getSAGpieces(defaultObj);
                        }

                        else {
                            defaultObj.IsSmap = false;
                            defaultObj.isReDo = false;
                            defaultObj.Other_parm = null;
                            // defaultObj.statusFlag = null;
                            defaultObj = this.getSAGpieces(defaultObj);
                            // defaultObj=this.getSAGpieces(this_eye, False, '', '', statusFlag);
                        }
                    }
                }
                this_BT2 = defaultObj.BTz2;
                this_BT3 = defaultObj.BTz3;
                this_BT4 = defaultObj.BTz4;
            }
        }       // End If
        else {
            //  defaultObj=  this.getSAGpieces(this_eye, False,'' , Other_parm, statusFlag)
            defaultObj.IsSmap = false;
            defaultObj.isReDo = false;
            defaultObj = this.getSAGpieces(defaultObj)
        }
        return defaultObj;

    }


    // this.Compute_dR=function(this_eye,delta_dZ){
    this.Compute_dR = function (compObj) {
        //'  this sub uses the PUBLIC variables for SAG (z2, z3, z4) and delta SAG (dZ2, dZ3, dZ4) to compute
        //  '  delta Radius at each of the three Zones (2-4)

        var this_P;
        var this_dR, dR1, dR2, dR3, dR4;

        var Zminor2;
        var Zminor3;
        var Zminor4;

        var Rminor2;
        var Rminor3;
        var Rminor4;

        var A_00;
        var A_01;
        var A_10;
        var A_11;

        var Ainv_00;
        var Ainv_01;
        var Ainv_10;
        var Ainv_11;

        var B_0;
        var B_1;

        var c;
        var d;
        var t;
        var a0, A1;

        var denom;
        var user_response;

        if (compObj.delta_dZ == null || compObj.delta_dZ == 0) {
            Zminor2 = compObj.z2 + compObj.dZ2;
            Zminor3 = compObj.z3 + compObj.dZ2 + compObj.dZ3;
            //'Zminor3 = BTz3 - dZ2
            Zminor4 = compObj.z4 + compObj.dZ2 + compObj.dZ3 + compObj.dZ4;
            //  'Zminor4 = BTz4 - dZ3 - dZ2
        }
        else {
            Zminor2 = compObj.z2 + compObj.dZ2;
            Zminor3 = compObj.z3 + compObj.dZ3;
            Zminor4 = compObj.z4 + compObj.dZ4;
        }

        //  End If

        // '  Zone 2

        if (compObj.PE2 == 1)
            compObj.PE2 = 0.99999;

        else if (compObj.PE2 == -1)
            compObj.PE2 = -0.99999;



        this_P = -(Math.pow(compObj.PE2, 2)) + 1;

        A_00 = compObj.z1;
        A_01 = 1;
        A_10 = Zminor2;
        A_11 = 1;

        //if(this_eye == "R"){ Current_eye = "R";
        //    this.Store4_proc();}

        //else if(this_eye == "L"){
        //    Current_eye = "L"
        //    this.Store4_proc();
        //}

        // End If

        B_0 = -(((compObj.POZ / 2) + parseFloat(compObj.W1)) * ((compObj.POZ / 2) + parseFloat(compObj.W1))) - (this_P * (Math.pow(compObj.z1, 2)));
        B_1 = -(((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2)) * ((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2))) - (this_P * (Math.pow(Zminor2, 2)));

        denom = (A_00 * A_11) - (A_01 * A_10);
        if (denom == 0) {
            // Then Exit Sub
            return compObj;
        }
        Ainv_00 = A_11 / denom;
        Ainv_01 = -A_01 / denom;
        Ainv_10 = -A_10 / denom;
        Ainv_11 = A_00 / denom;

        c = (Ainv_00 * B_0) + (Ainv_01 * B_1);
        d = (Ainv_10 * B_0) + (Ainv_11 * B_1);

        t = Math.pow(c, 2) - 4 * this_P * d;

        if (t > 0) {
            a0 = (-c - Math.sqrt(parseFloat(t))) / (2 * this_P)
            A1 = (-c + Math.sqrt(parseFloat(t))) / (2 * this_P)
            if (a0 <= A1)
                Rminor2 = -(c + (2 * this_P * a0)) / 2;
            else {
                Rminor2 = -(c + (2 * this_P * A1)) / 2;
                // End If


            }
            dR2 = Rminor2 - compObj.PC2;
            compObj.Me_FC4 = parseFloat(parseFloat(dR2).toFixed(4));
        }

        else {
            return compObj;
        }
        //  Exit Sub
        //  End If

        // '  Zone 3

        if (compObj.PE3 == 1)
            compObj.PE3 = 0.99999;
        else if (compObj.PE3 == -1)
            compObj.PE3 = -0.99999;
        //End If

        this_P = -(Math.pow(compObj.PE3, 2)) + 1;

        A_00 = Zminor2;
        A_01 = 1;
        A_10 = Zminor3;
        A_11 = 1;
        //   B_0 = -(Math.pow((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2)), 2) - (this_P * (Math.pow(Zminor2, 2)));
        //  B_1 = -(Math.pow((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3)), 2) - (this_P * (Math.pow(Zminor3, 2)));
        B_0 = -(((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2)) * ((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2))) - (this_P * (Math.pow(Zminor2, 2)));
        B_1 = -(((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3)) * ((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3))) - (this_P * (Math.pow(Zminor3, 2)));
        denom = (A_00 * A_11) - (A_01 * A_10);
        if (denom == 0) {
            return compObj;
        }// Exit Sub

        Ainv_00 = A_11 / denom;
        Ainv_01 = -A_01 / denom;
        Ainv_10 = -A_10 / denom;
        Ainv_11 = A_00 / denom;

        c = (Ainv_00 * B_0) + (Ainv_01 * B_1);
        d = (Ainv_10 * B_0) + (Ainv_11 * B_1);

        t = Math.pow(c, 2) - 4 * this_P * d;

        if (t > 0) {
            a0 = (-c - Math.sqrt(parseFloat(t))) / (2 * this_P);
            A1 = (-c + Math.sqrt(parseFloat(t))) / (2 * this_P);
            if (a0 <= A1)
                Rminor3 = -(c + (2 * this_P * a0)) / 2;
            else
                Rminor3 = -(c + (2 * this_P * A1)) / 2;
            // End If

            dR3 = Rminor3 - compObj.PC3;
            compObj.Me_FD4 = parseFloat(parseFloat(dR3).toFixed(4));
        }

        else {
            return compObj;
        }
        // Exit Sub
        // End If

        //  '  Zone 4


        if (compObj.PE4 == 1)
            compObj.PE4 = 0.99999;
        else if (compObj.PE4 == -1)
            compObj.PE4 = -0.99999;
        //End If

        this_P = -(Math.pow(compObj.PE4, 2)) + 1;

        if (this_P == 0)
            this_P = 0.000001;
        // End If

        A_00 = Zminor3;
        A_01 = 1;
        A_10 = Zminor4;
        A_11 = 1;
        // B_0 = -(Math.pow((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3)), 2) - (this_P * Math.pow((Zminor3), 2));
        // B_1 = -(Math.pow((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3) + parseFloat(compObj.W4)), 2) - (this_P * Math.pow((Zminor4), 2));
        // B_1 = -(Math.pow((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3) + (compObj.W4)), 2) - (this_P * Math.pow((Zminor4), 2));
        B_0 = -(((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3)) * ((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3))) - (this_P * Math.pow((Zminor3), 2));
        B_1 = -(((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3) + parseFloat(compObj.W4)) * ((compObj.POZ / 2) + parseFloat(compObj.W1) + parseFloat(compObj.W2) + parseFloat(compObj.W3) + parseFloat(compObj.W4))) - (this_P * Math.pow((Zminor4), 2));
        denom = (A_00 * A_11) - (A_01 * A_10);
        if (denom == 0) {
            return compObj;
        }// Exit Sub

        Ainv_00 = A_11 / denom;
        Ainv_01 = -A_01 / denom;
        Ainv_10 = -A_10 / denom;
        Ainv_11 = A_00 / denom;

        c = (Ainv_00 * B_0) + (Ainv_01 * B_1);
        d = (Ainv_10 * B_0) + (Ainv_11 * B_1);

        t = Math.pow(c, 2) - 4 * this_P * d;

        if (t > 0) {
            a0 = (-c - Math.sqrt(parseFloat(t))) / (2 * this_P);
            A1 = (-c + Math.sqrt(parseFloat(t))) / (2 * this_P);
            if (a0 <= A1)
                Rminor4 = -(c + (2 * this_P * a0)) / 2;
            else
                Rminor4 = -(c + (2 * this_P * A1)) / 2;
            //  End If

            dR4 = Rminor4 - compObj.PC4;
            compObj.Me_Fe4 = parseFloat(parseFloat(dR4).toFixed(4));
        }
        else {
            return compObj;
        }
        //  Exit Sub
        //  End If

        if (compObj.this_eye != null) {
            compObj.Me_FC4 = parseFloat(parseFloat(dR2).toFixed(4));
            compObj.Me_FD4 = parseFloat(parseFloat(dR3).toFixed(4));
            compObj.Me_Fe4 = parseFloat(parseFloat(dR4).toFixed(4));
        }


        return compObj;
        //   End If
        //  End Sub


    }










    // this.Fix_BT_Remarks=function(this_eye, this_desn, this_old_val, this_new_val,this_dZ2)
    this.Fix_BT_Remarks = function (remarkObj) {


        var tempStr_A;
        var tempStr_B;
        var tempStr_C;
        var searchStr;
        var intPosition_1;
        var intPosition_2;
        var intPosition_2a;
        var intPosition_2b;

        searchStr = (remarkObj.this_old_val).toString();
        if (remarkObj.this_old_val < 10 && remarkObj.this_old_val > 0.25) {
            if (searchStr.substr(-1) == "0")
                searchStr = (searchStr.substr(0, 1));
        }
        else {
            if (searchStr.substr(-1) == "0")
                searchStr = searchStr.substr(0, 2);
        }


        //   End If
        searchStr = searchStr + "D";

        if (remarkObj.this_eye != null) {
            if ((remarkObj.Me_rem != null) && (remarkObj.Me_rem.trim().length) > 0) {
                tempStr_A = (remarkObj.Me_rem.trim());
                intPosition_1 = (tempStr_A).indexOf(searchStr);

                if (intPosition_1 == 0 || intPosition_1 == null) {
                    if ((tempStr_A.length) > (64 - ("  TORIC PC").length)) {
                        //   MsgBox "The RIGHT Remarks field is currently too long to automatically add the comment " & vbCrLf & _
                        //   vbTab & "'TORIC PC'" & vbCrLf & vbCrLf & _
                        //  "You are responsible for manually editing the RIGHT Remarks field with the phrase 'TORIC PC'", _
                        //  vbExclamation, "Automatic Comment Failed"
                    }
                    else {
                        remarkObj.Me_rem = tempStr_A + "  TORIC PC";
                        if (remarkObj.this_old_val == 0);
                        // MsgBox "RIGHT LENS" & vbCrLf & vbCrLf & "The 'new' Back Toric value will be " & _
                        // CStr(Round(this_new_val, 0)) & chr(181), vbInformation, "Converted - RIGHT Back Toric Value"
                        else {

                        }
                        //MsgBox "RIGHT LENS" & vbCrLf & vbCrLf & "The 'old' Back Toric value was " & _
                        //    IIf(Abs(this_old_val) <= 10, CStr(Round(this_old_val, 1)), CStr(Round(this_old_val, 0))) & IIf(Abs(this_old_val) <= 10, "D [Diopters]", chr(181)) & _
                        // vbCrLf & vbCrLf & "The 'new' Back Toric value will be " & CStr(Round(this_new_val, 0)) & chr(181), vbInformation, "Converted - RIGHT Back Toric Value"
                    }

                }
                //   End If
                //   End If
                else if (intPosition_1 == 1) {
                    intPosition_2a = tempStr_A.indexOf("TORIC PC");
                    intPosition_2b = tempStr_A.indexOf("TORIC HAPTIC");

                    if (intPosition_2a > 0)
                        intPosition_2 = intPosition_2a;
                    if (intPosition_2b > 0)
                        intPosition_2 = intPosition_2b;
                    if (intPosition_2 > 1) {
                        if ((tempStr_A).length > intPosition_2 + 10) {
                            // - ve  because the string should start from the right side 
                            tempStr_B = (tempStr_A.substr(-((tempStr_A.length) - intPosition_2) + 1)).trim();
                            remarkObj.Me_rem = " " + tempStr_B;
                        }
                        else
                            remarkObj.Me_rem = "TORIC PC";
                    }
                    //End If
                    //End If

                    intPosition_2a = tempStr_A.indexOf("TORIC PC");
                    intPosition_2b = tempStr_A.indexOf("TORIC HAPTIC");

                    if (intPosition_2a > 0)
                        intPosition_2 = intPosition_2a;
                    if (intPosition_2b > 0)
                        intPosition_2 = intPosition_2b;

                    if (intPosition_2 > 1) {
                        if ((tempStr_A).length > intPosition_2 + 10) {
                            tempStr_B = (tempStr_A.substr(-(((tempStr_A).length - intPosition_2) + 1))).trim();
                            remarkObj.Me_rem = " " + tempStr_B;
                            //  MsgBox "RIGHT LENS" & vbCrLf & vbCrLf & "The 'old' Back Toric value was " & _
                            // IIf(Abs(this_old_val) <= 10, Round(CStr(this_old_val), 1), Round(CStr(this_old_val), 0)) & IIf(Abs(this_old_val) <= 10, "D [Diopters]", chr(181)) & _
                            // vbCrLf & vbCrLf & "The 'new' Back Toric value will be " & CStr(Round(this_new_val, 0)) & chr(181), vbInformation, "Converted - RIGHT Back Toric Value"
                        }
                        else {
                            remarkObj.Me_rem = "TORIC PC";
                            // MsgBox "RIGHT LENS" & vbCrLf & vbCrLf & "The 'old' Back Toric value was " & _
                            //  IIf(Abs(this_old_val) <= 10, Round(CStr(this_old_val), 1), Round(CStr(this_old_val), 0)) & IIf(Abs(this_old_val) <= 10, "D [Diopters]", chr(181)) & _
                            //  vbCrLf & vbCrLf & "The 'new' Back Toric value will be " & CStr(Round(this_new_val, 0)) & chr(181), vbInformation, "Converted - RIGHT Back Toric Value"
                            // End If
                        }
                    }
                    else {
                        // MsgBox "The original RIGHT Remarks field is worded in such a way that the program cannot automatically update the comment." & vbCrLf & _
                        //  vbCrLf & "You are responsible for manually editing the RIGHT Remarks field with the phrase 'TORIC HAPTIC'", _
                        //vbExclamation, "Automatic Comment Failed"
                    }

                    // End If
                }
                else if (intPosition_1 > 1) {
                    // additional -1 as in javac=script the string position starts at 0 in vb starts with 1
                    tempStr_B = (tempStr_A.substr(intPosition_1 - 1 - 1)).trim();
                    tempStr_C = tempStr_A.substr(-(tempStr_A.length - (intPosition_1 + searchStr.length))).trim();
                    remarkObj.Me_rem = tempStr_B + " " + tempStr_C;


                    if (remarkObj.this_old_val == 0);
                    // MsgBox "RIGHT LENS" & vbCrLf & vbCrLf & "The 'new' Back Toric value will be " & CStr(Round(this_new_val, 0)) & chr(181), vbInformation, "Converted - RIGHT Back Toric Value"
                    else {
                        //MsgBox "RIGHT LENS" & vbCrLf & vbCrLf & "The 'old' Back Toric value was " & _
                        // IIf((this_old_val < 10 And this_old_val > 0.25), CStr(Round(this_old_val, 1)), CStr(Round(this_old_val, 0))) & IIf((this_old_val < 10 And this_old_val > 0.25), "D [Diopters]", chr(181)) & _
                        // vbCrLf & vbCrLf & "The 'new' Back Toric value will be " & CStr(Round(this_new_val, 0)) & chr(181), vbInformation, "Converted - RIGHT Back Toric Value"
                    }///  End If
                    //End If
                }
            }
            else {
                remarkObj.Me_rem = "Toric PC";
                if (remarkObj.this_old_val == 0);
                //   MsgBox "RIGHT LENS" & vbCrLf & vbCrLf & "The 'new' Back Toric value will be " & _
                //  CStr(this_new_val) & chr(181), vbInformation, "Converted - RIGHT Back Toric Value"
                else {

                }
                //  MsgBox "RIGHT LENS" & vbCrLf & vbCrLf & "The 'old' Back Toric value was " & _
                // IIf((this_old_val < 10 And this_old_val > 0.25), Round(CStr(this_old_val), 1), Round(CStr(this_old_val), 0)) & IIf((this_old_val < 10 And this_old_val > 0.25), "D [Diopters]", chr(181)) & _
                // vbCrLf & vbCrLf & "The 'new' Back Toric value will be " & CStr(this_new_val) & chr(181), vbInformation, "Converted - RIGHT Back Toric Value"
                // End If
                //End If
            }
        }
        return remarkObj;

    }


    // is ready BT

    // Private Function IsReadyBT(this_eye As String, IsSmap As Boolean, this_desn As String) As Boolean
    this.IsReadyBT = function (btObject) {


        btObject.IsReadyBT = true;

        if (btObject.this_eye != null) {


            if (btObject.IsSmap == true) {
                if (btObject.Me_FC4 == 0 || btObject.Me_FC4 == null) {
                    btObject.IsReadyBT = false;
                }

                if (btObject.Me_FD4 == 0 || btObject.Me_FD4 == null) {
                    btObject.IsReadyBT = false;
                }



                if (Left(btObject.this_desn, 3) == "ELA") {
                    // ' fall through
                }

                else {
                    if (btObject.Me_Fe4 == 0 || btObject.Me.R_Fe4 == null) {
                        btObject.IsReadyBT = false;
                    }
                }



                // End If

                if (btObject.Me_dia == 0 || btObject.Me_dia == null) {
                    btObject.IsReadyBT = false;
                }

                // Exit Function
                // End If

                if (Len(trim(btObject.Me_Desn)) == 0 || btObject.Me_Desn == null) {
                    btObject.IsReadyBT = false;
                }

                // Exit Function
                // End If

                if (btObject.Me_bcfl = 0 || btObject.Me_bcfl == null) {
                    btObject.IsReadyBT = false;
                }



                if (btObject.Me_poz == 0 || btObject.Me_poz == null) {
                    btObject.IsReadyBT = false;
                }

                if (btObject.Me_w1 == 0 || btObject.Me_w1 == null) {
                    btObject.IsReadyBT = false;
                }

                if (btObject.Me_w2 == 0 || btObject.Me_w2 == null) {
                    btObject.IsReadyBT = false;
                }

                if (btObject.Me_w3 == 0 || btObject.Me_w3 == null) {
                    btObject.IsReadyBT = false;
                }

                if (btObject.Me_pc1 == 0 || btObject.Me.R_pc1 == null) {
                    btObject.IsReadyBT = false;
                }

                if (btObject.Me_pc2 == 0 || btObject.Me.R_pc2 == null) {
                    btObject.IsReadyBT = false;
                }

                if (btObject.Me_pc3 == 0 || btObject.Me.R_pc3 == null) {
                    btObject.IsReadyBT = false;
                }

                if (Left(btObject.this_desn, 3) == "ELA") {

                }
                if (btObject.Me_pc4 == 0 || btObject.Me_pc4 == null) {
                    if (btObject.Me.R_Fe4 == null);
                    // ' fall through - this is OK
                    else {
                        btObject.IsReadyBT = false;
                    }
                }
                else {
                    if (btObject.Me_Fe4 == null) {
                        btObject.IsReadyBT = false;
                    }

                }
            }
            else {
                if (btObject.Me_pc4 == 0 || btObject.Me_pc4 == null) {
                    btObject.IsReadyBT = false;
                }

            }
        }
        // Exit Function
        else {
            if (btObject.Me_w4 == 0 || btObject.Me_w4 == null) {
                btObject.IsReadyBT = false;
            }
        }

        //   Exit Function
        ////   End If
        //  End If
        //  End If



    }

    //   Private Sub R_Fe4_AfterUpdate()
    this.SubR_Fe4_AfterUpdate = function (obj) {

        //�  this code will call (2) outside subroutines/functions (see separate documents)
        //1.)	Function IsReadyBT()
        //2.)	Function get_dZ_microns()
        //3.)	Sub R_toric_m_AfterUpdate()

        //if(IsReadyBT("R", True, Me.R_Desn) == true)
        //{
        //    Call get_dZ_microns("R")
        //    Call R_toric_m_AfterUpdate
        //    // End If
        //}

        if (obj.IsReadyBT == true) {
            obj = this.get_dZ_microns(obj);

            obj = this.R_toric_m_AfterUpdate(obj);
            //  Call get_dZ_microns("R")
            //  Call R_toric_m_AfterUpdate
            // End If
        }
        //End Sub
    }
    //this.SubFix_Add_type=function()
    //{
    //    // Private Sub Fix_Add_type(this_eye As String)
    //    //  ' Add-on charge # 1 is a combination of #2 and #6, but previous order may have already specified one of these charges

    //    //  ' This subroutine will make a change to the cost value - $45 vs. $75 - for Add-On charge type #1 (BiToric)...
    //    //  '                                        ( cost value is $20 vs. $35 for Spectrum Intl - distributor with special pricing)
    //    //  '    if the current order is 'Under Warranty' and the prior order has ...
    //    // '       either #2 (Front Toric) or #6 (Scleral Haptic (Back) Toric)

    //    // ' Also, if the 'Under Warranty' flag is released, then this code will change the price back to the original - Full Price

    //    // Dim rst As Recordset
    //    // Dim myDB As Database
    //    // Dim strSQL As String
    //    // Set myDB = CurrentDb

    //    if( this_eye!=null) 
    //    {
    //        // ' if none of the Add-on charges is equal to '1' then fall through...
    //        if (Me.R_add_type1 == 1 || Me.R_add_type2 == 1 || Me.R_add_type3 == 1 || Me.R_add_type4 == 1 )
    //        {
    //            if( Me.R_old_invoice==null)
    //            {
    //                // ' fall through
    //            }
    //            else
    //            {        
    //                // strSQL = "SELECT R_add_type1, R_add_type2, R_add_type3, R_add_type4 " & _
    //                //    "FROM Orders WHERE Orders.Order_no = " & Me.R_old_invoice

    //                // Set rst = myDB.OpenRecordset(strSQL)
    //                if (rst(0) == 1 || rst(1) == 1 || rst(2) == 1 || rst(3) == 1)
    //                {

    //                    //' Display the full price (or full distributor price) no matter whether under warranty or not under warranty
    //                    if (Me.acct_no == 7678)
    //                    {
    //                        if(Me.R_add_type1 == 1)
    //                        {
    //                            if( Me.R_add_type2 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34 )
    //                            {
    //                                Me.R_add_cost1.Value = 35 * 0.7;
    //                            }
    //                            else         
    //                                Me.R_add_cost1.Value = 35
    //                        } // End if
    //                        else if (Me.R_add_type2 == 1 )
    //                        {
    //                            if (Me.R_add_type1 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34)
    //                                Me.R_add_cost2.Value = 35 * 0.7;
    //                            else         
    //                                Me.R_add_cost2.Value = 35;
    //                        }   //End if
    //                        else if (Me.R_add_type3 == 1)
    //                        {                          
    //                            if( Me.R_add_type1 == 34 || Me.R_add_type2 == 34 || Me.R_add_type4 == 34 )
    //                                Me.R_add_cost3.Value = 35 * 0.7;
    //                            else         
    //                                Me.R_add_cost3.Value = 35;
    //                        } // End if
    //                        else if (Me.R_add_type4 == 1)
    //                        {
    //                            if (Me.R_add_type1 == 34 || Me.R_add_type2 == 34 || Me.R_add_type3 == 34 )
    //                                Me.R_add_cost4.Value = 35 * 0.7;
    //                            else         
    //                                Me.R_add_cost4.Value = 35
    //                        }    //End if
    //                    }    //End if
    //                    else
    //                    {         
    //                        if (Me.R_add_type1 == 1)
    //                            Me.R_add_cost1 = 75;
    //                        else if (Me.R_add_type2 == 1 )
    //                            Me.R_add_cost2 = 75;
    //                        else if (Me.R_add_type3 == 1 )
    //                            Me.R_add_cost3 = 75;
    //                        else if (Me.R_add_type4 == 1)
    //                            Me.R_add_cost4 = 75;
    //                    }    //End if
    //                    // End if
    //                }                
    //                    //  ' Add-on charge 2 is for Front Toric
    //                else if (rst(0) = 2 || rst(1) == 2 || rst(2) == 2 || rst(3) == 2)
    //                {

    //                    if( Me.R_repl_under_warr = -1 )
    //                    {
    //                        // ' Display the full price (or full distributor price) for Scleral (Back) Toric portion
    //                        // '     that is not under warranty because it was not previously ordered
    //                        if (Me.acct_no = 7678)
    //                        {
    //                            if (Me.R_add_type1 == 1)
    //                            {
    //                                if (Me.R_add_type2 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34)
    //                                    Me.R_add_cost1.Value = 20 * 0.7;
    //                                else         
    //                                    Me.R_add_cost1.Value = 20;
    //                            } //  End if
    //                            else if (Me.R_add_type2 == 1 )
    //                            {
    //                                if (Me.R_add_type1 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34)
    //                                    Me.R_add_cost2.Value = 20 * 0.7;
    //                                else         
    //                                    Me.R_add_cost2.Value = 20;
    //                            }  //End if
    //                            else if (Me.R_add_type3 == 1 )
    //                            {
    //                                if (Me.R_add_type1 == 34 || Me.R_add_type2 == 34 || Me.R_add_type4 == 34)
    //                                    Me.R_add_cost3.Value = 20 * 0.7;
    //                                else         
    //                                    Me.R_add_cost3.Value == 20;
    //                            }//    End if
    //                            else if (Me.R_add_type4 == 1 )
    //                            {
    //                                if (Me.R_add_type1 == 34 || Me.R_add_type2 == 34 || Me.R_add_type3 == 34)
    //                                    Me.R_add_cost4.Value = 20 * 0.7;
    //                                else         
    //                                    Me.R_add_cost4.Value = 20;
    //                            }    //End if
    //                        }    //End if
    //                        else
    //                        {         
    //                            if (Me.R_add_type1 == 1 )
    //                                Me.R_add_cost1 = 45;
    //                            else  if (Me.R_add_type2 == 1) 
    //                                Me.R_add_cost2 = 45;
    //                            else  if (Me.R_add_type3 == 1 )
    //                                Me.R_add_cost3 = 45;
    //                            else if (Me.R_add_type4 == 1 )
    //                                Me.R_add_cost4 = 45;
    //                        }    //End if
    //                    }    //End if
    //                    else 
    //                    {        
    //                        // ' Display the full price (or full distributor price) for BOTH parts of the Toric Add-on charge
    //                        //  '    because this order is not Under Warranty
    //                        if (Me.acct_no == 7678 )
    //                        {
    //                            if (Me.R_add_type1 == 1 )
    //                            {
    //                                if (Me.R_add_type2 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34)
    //                                    Me.R_add_cost1.Value = 35 * 0.7;
    //                                else         
    //                                    Me.R_add_cost1.Value = 35;
    //                            }   // End if
    //                            else if (Me.R_add_type2 == 1 )
    //                            {
    //                                if (Me.R_add_type1 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34 )
    //                                    Me.R_add_cost2.Value = 35 * 0.7
    //                                else         
    //                                    Me.R_add_cost2.Value = 35
    //                            }   // End if
    //                            else if( Me.R_add_type3 == 1 )
    //                            {
    //                                if (Me.R_add_type1 == 34 || Me.R_add_type2 == 34 || Me.R_add_type4 == 34 )
    //                                    Me.R_add_cost3.Value = 35 * 0.7
    //                                else         
    //                                    Me.R_add_cost3.Value = 35
    //                            }    //End if
    //                            else if (Me.R_add_type4 == 1)
    //                            {
    //                                if( Me.R_add_type1 == 34 || Me.R_add_type2 == 34 || Me.R_add_type3 == 34 )
    //                                    Me.R_add_cost4.Value = 35 * 0.7
    //                                else         
    //                                    Me.R_add_cost4.Value = 35;
    //                            }    //End if
    //                                // End if
    //                            else
    //                            {         
    //                                if(Me.R_add_type1 == 1 )
    //                                    Me.R_add_cost1 = 75
    //                                else if (Me.R_add_type2 = 1)
    //                                    Me.R_add_cost2 = 75
    //                                else  if (Me.R_add_type3 == 1 )
    //                                    Me.R_add_cost3 = 75
    //                                else  if (Me.R_add_type4 == 1 )
    //                                    Me.R_add_cost4 = 75
    //                            }   // End if
    //                        }   // End if
    //                        // End if

    //                    }
    //                }//' Add-on charge 6 is for Scleral Haptic (Back) Toric
    //                else 
    //                {      
    //                    if (rst(0) == 6 || rst(1) == 6 || rst(2) == 6 || rst(3) == 6)
    //                    {

    //                        if (Me.R_repl_under_warr == -1 )
    //                        {
    //                            //' Display the full price for Front Toric portion that is not under warranty because it was not previously ordered
    //                            if (Me.acct_no == 7678 )
    //                            {
    //                                if (Me.R_add_type1 == 1 )
    //                                {
    //                                    if (Me.R_add_type2 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34)
    //                                        Me.R_add_cost1.Value = 20 * 0.7;
    //                                    else         
    //                                        Me.R_add_cost1.Value = 20;
    //                                }//    End if
    //                                else  if (Me.R_add_type2 == 1)
    //                                {
    //                                    if (Me.R_add_type1 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34)
    //                                        Me.R_add_cost2.Value = 20 * 0.7;
    //                                    else         
    //                                        Me.R_add_cost2.Value = 20;
    //                                }//    End if
    //                                else if (Me.R_add_type3 == 1 )
    //                                {
    //                                    if (Me.R_add_type1 == 34 || Me.R_add_type2 == 34 || Me.R_add_type4 == 34)
    //                                        Me.R_add_cost3.Value = 20 * 0.7;
    //                                    else         
    //                                        Me.R_add_cost3.Value = 20;
    //                                }//    End if
    //                                else  if (Me.R_add_type4 == 1)
    //                                {
    //                                    if (Me.R_add_type1 == 34 || Me.R_add_type2 == 34 || Me.R_add_type3 == 34 )
    //                                        Me.R_add_cost4.Value = 20 * 0.7;
    //                                    else         
    //                                        Me.R_add_cost4.Value = 20;
    //                                }   // End if
    //                            }  //  End if
    //                            else 
    //                            {        
    //                                if (Me.R_add_type1 == 1 )
    //                                    Me.R_add_cost1 = 45;
    //                                else  if (Me.R_add_type2 == 1)
    //                                    Me.R_add_cost2 = 45;
    //                                else   if( Me.R_add_type3 == 1 )
    //                                    Me.R_add_cost3 = 45;
    //                                else  if (Me.R_add_type4 == 1)
    //                                    Me.R_add_cost4 = 45;
    //                            }   // End if
    //                        }   // End if
    //                        else 
    //                        {       
    //                            // ' Display the full price for BOTH parts of the Toric Add-on charge because this order is not Under Warranty
    //                            if (Me.acct_no == 7678)
    //                            {
    //                                if (Me.R_add_type1 == 1)
    //                                {
    //                                    if( Me.R_add_type2 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34 )
    //                                        Me.R_add_cost1.Value = 35 * 0.7;
    //                                    else        
    //                                        Me.R_add_cost1.Value = 35;
    //                                }//    End if
    //                                else  if (Me.R_add_type2 == 1 )
    //                                {
    //                                    if (Me.R_add_type1 == 34 || Me.R_add_type3 == 34 || Me.R_add_type4 == 34)
    //                                        Me.R_add_cost2.Value = 35 * 0.7;
    //                                    else        
    //                                        Me.R_add_cost2.Value = 35;
    //                                }//    End if
    //                                else if (Me.R_add_type3 == 1)
    //                                {
    //                                    if (Me.R_add_type1 = 34 || Me.R_add_type2 = 34 || Me.R_add_type4 = 34)
    //                                        Me.R_add_cost3.Value = 35 * 0.7;
    //                                    else        
    //                                        Me.R_add_cost3.Value = 35;
    //                                }//    End if
    //                                else if (Me.R_add_type4 == 1 )
    //                                {
    //                                    if (Me.R_add_type1 == 34 || Me.R_add_type2 == 34 || Me.R_add_type3 == 34)
    //                                        Me.R_add_cost4.Value = 35 * 0.7;
    //                                    else        
    //                                        Me.R_add_cost4.Value = 35;
    //                                }   // End if
    //                            }   // End if
    //                            else 
    //                            {       
    //                                if( Me.R_add_type1 == 1)
    //                                    Me.R_add_cost1 = 75;
    //                                else  if (Me.R_add_type2 == 1)
    //                                    Me.R_add_cost2 = 75;
    //                                else if (Me.R_add_type3 == 1)
    //                                    Me.R_add_cost3 = 75;
    //                                else if (Me.R_add_type4 == 1)
    //                                    Me.R_add_cost4 = 75;
    //                            }    //End if
    //                        }   // End if
    //                    }   // End if
    //                }   // End if
    //            }   // End if
    //        }//End if

    //    }

    //    // if Not rst Is Nothing Then
    //    // rst.Close
    //    // Set rst = Nothing
    //    // End if

    //    //End Sub
    //}



    //Private Sub sMap_Back_Toric_Visible(this_eye As String)
    //    ' This procedure will set / re-set the visible properties for the (4) Back Toric fields for sMap3D orders
    //   '      for whichever 'Eye' (lens) is calling it

    //' FE5 = 1 when this is the original sMap order (otherwise FE5 = 0 or FE5 IsNull)

    //' FD5 = 1 when this is an sMap patient (otherwise FD5 = 0 or FD5 IsNull)

    // ' FD5 and FE5 should never both be '1' at the same time

    // ' This procedure is only called when the Design is either Elara or Europa or Jupiter
    // ' This procedure is only called for patient lens orders (InCode is 'E' or 'N')
    //this.SubsMap_Back_Toric_Visible=function()
    //{
    //    if( this_eye != null)
    //    {
    //        if ((Me.R_Fe5 == 1 || Me.R_FD5 == 1) && (Me.R_toric > 0 || Math.abs(Me.R_FC3) > 1))
    //        {
    //            // ' sMap case with Back Toric already  assigned
    //            Me.lbl_BackToric_R.Caption = "sMap3D Toricity";
    //            Me.lbl_BackToric_R.Visible = true;
    //            Me.line_BToric_R1.Visible = true;
    //            Me.line_BToric_R2.Visible = true;

    //            Me.R_FC4.Visible = true;
    //            Me.R_FD4.Visible = true;
    //            Me.R_Fe4.Visible = true;
    //            Me.R_Btor.Visible = true;
    //        } 
    //        else
    //        {
    //            if ((Me.R_toric > 0 || Math.abs(Me.R_FC3) > 1) && (Left(Me.R_Desn, 3) == "ELA" || Left(Me.R_Desn, 3) == "EUR" || Left(Me.R_Desn, 3) == "JUP"))
    //            {
    //                Me.lbl_BackToric_R.Caption = "Back Toric Axis";
    //                Me.lbl_BackToric_R.Visible = true;
    //                Me.line_BToric_R1.Visible = true;
    //                Me.line_BToric_R2.Visible = true;

    //                Me.R_FC4.Visible = false;
    //                Me.R_FD4.Visible = false;
    //                Me.R_Fe4.Visible = false;
    //                Me.R_Btor.Visible = true;
    //            }
    //            else
    //            {
    //                Me.lbl_BackToric_R.Visible = false;
    //                Me.line_BToric_R1.Visible = false;
    //                Me.line_BToric_R2.Visible = false;

    //                Me.R_FC4.Visible = false;
    //                Me.R_FD4.Visible = false;
    //                Me.R_Fe4.Visible = false;
    //                Me.R_Btor.Visible = false;
    //            }
    //        }//    End if
    //    }//    End if  
    //}


    //******************************** btn_update_radius_Click added by shwetha ********************************

    this.btn_update_radius_Click = function (pLift_Obj) {
        //  debugger;
        //On Error GoTo btn_update_radius_ERR
        //DoCmd.Hourglass True
        //1. Call Refresh_Public_vars(PLift_eye)=======>>>> no need to use this method==<<<<<<<<<<.

        //2. Call getSAGpieces_PL(PLift_eye, False, True, , "BackToric")  
        pLift_Obj = this.getSAGpieces_PL(pLift_Obj);

        //3. Call Compute_dR_PL(PLift_eye)
        pLift_Obj = this.Compute_dR_PL(pLift_Obj);
        //btn_update_radius_EXIT:

        //    DoCmd.Hourglass False
        //Exit Sub
        return pLift_Obj;

    }
    //************************************* end*******************************************************************

    //***************************** getSAGpieces_PL added by shwetha *********************************************
    this.getSAGpieces_PL = function (sagObj) {
        var i = 0, j = 0, q = 0;
        var this_XSAG = 0, this_YSAG = 0, my_SAG = 0, old_SAG = 0, new_SAG = 0, delta_Sag = 0, running_SAG = 0;
        var z_0 = 0, z_1 = 0, z_2 = 0, z_3 = 0, z_4 = 0, Z_5 = 0;
        var BTz_0 = 0, BTz_1 = 0, BTz_2 = 0, BTz_3 = 0, BTz_4 = 0, BTz_5 = 0;
        while (j < 2) {
            while (i < 5) {
                //' set public variables
                sagObj.bcfl = sagObj.PL2_BC;///lens screen bc

                if (i < 1) {
                    sagObj.PC1 = 0;
                }
                else
                    //sagObj.PC1 = Math.Round(parseFloat(sagObj.PL5_PC1), 3);//lens screen 
                    sagObj.PC1 = parseFloat(sagObj.PL5_PC1).toFixed(3);


                if (i < 2) {
                    sagObj.PC2 = 0;
                }
                else
                    sagObj.PC2 = parseFloat(sagObj.PL8_PC2).toFixed(3);//Math.Round(parseFloat(sagObj.PL8_PC2), 3);

                if (i < 3) {
                    sagObj.PC3 = 0;
                }
                else
                    sagObj.PC3 = parseFloat(sagObj.PL11_PC3).toFixed(3);// Math.Round(parseFloat(sagObj.PL11_PC3), 3);

                if (i < 4) {
                    sagObj.PC4 = 0;
                }
                else
                    sagObj.PC4 = parseFloat(sagObj.PL14_PC4).toFixed(3);// Math.Round(parseFloat(sagObj.PL14_PC4), 3);


                sagObj.PC5 = 0;

                sagObj.BCE = sagObj.PL4_BE;
                sagObj.PE1 = sagObj.PL7_PE1;
                sagObj.PE2 = sagObj.PL10_PE2;
                sagObj.PE3 = sagObj.PL13_PE3;
                sagObj.PE4 = sagObj.PL16_PE4;

                sagObj.POZ = sagObj.PL3_POZ;

                sagObj.W1 = sagObj.PL6_W1;
                sagObj.W2 = sagObj.PL9_W2;
                sagObj.W3 = sagObj.PL12_W3;
                sagObj.W4 = sagObj.PL15_W4;

                //Call SagTotal_proc(True)
                // call sag totalproc
                var sag = {};
                sag.bcmm = sagObj.bcfl;
                sag.oz = sagObj.POZ;
                sag.poze = sagObj.BCE;
                sag.pc1 = sagObj.PC1;
                sag.pc2 = sagObj.PC2;
                sag.pc3 = sagObj.PC3;
                sag.pc4 = sagObj.PC4;
                sag.e1 = sagObj.PE1;
                sag.e2 = sagObj.PE2;
                sag.e3 = sagObj.PE3;
                sag.e4 = sagObj.PE4;
                sag.w1 = sagObj.W1;
                sag.w2 = sagObj.W2;
                sag.w3 = sagObj.W3;
                sag.w4 = sagObj.W4;

                var sagvalues = this.SubSagTotal_procTpc(sag);
                var XSAG = sagvalues.XSAG == NaN ? 0 : parseFloat(sagvalues.XSAG);
                var YSAG = (sagvalues.YSAG == NaN || sagvalues.YSAG == "NaN") ? 0 : parseFloat(sagvalues.YSAG);

                my_SAG = YSAG - XSAG;

                if (j < 1) {
                    if (j == 0) {
                        if (i == 0)
                            sagObj.z0 = my_SAG;
                        else if (i == 1)
                            sagObj.z1 = my_SAG;
                        else if (i == 2)
                            sagObj.z2 = my_SAG;
                        else if (i == 3)
                            sagObj.z3 = my_SAG;
                        else if (i == 4)
                            sagObj.z4 = my_SAG;
                        else if (i == 5)
                            sagObj.z5 = my_SAG;
                    }
                    // 'MsgBox "Total 'base' SAG through zone " & CStr(i) & " is " & Format(CStr(my_SAG), "0.0000")
                } else {

                    if (j == 1) {

                        if (i == 0)
                            sagObj.BTz0 = my_SAG;
                        else if (i == 1) {
                            if (sagObj.Other_parm == null || sagObj.dZ1 == 0)
                                sagObj.BTz1 = my_SAG;
                            else
                                sagObj.BTz1 = sagObj.z1 + sagObj.dZ1;
                        }
                        else if (i == 2) {
                            if (sagObj.Other_parm == null || sagObj.dZ2 == 0)
                                sagObj.BTz2 = my_SAG;
                            else
                                sagObj.BTz2 = sagObj.z2 + sagObj.dZ2;
                        }
                        else if (i == 3) {
                            if (sagObj.Other_parm == null || sagObj.dZ3 == 0)
                                sagObj.BTz3 = my_SAG;
                            else
                                sagObj.BTz3 = sagObj.z3 + sagObj.dZ2 + sagObj.dZ3;
                        }
                        else if (i == 4) {
                            if (sagObj.Other_parm == null || sagObj.dZ4 == 0)
                                sagObj.BTz4 = my_SAG;
                            else
                                sagObj.BTz4 = sagObj.z4 + sagObj.dZ2 + sagObj.dZ3 + sagObj.dZ4;
                        }
                        else if (i == 5) {
                            if (sagObj.Other_parm == null)
                                sagObj.BTz5 = my_SAG;
                            else
                                sagObj.BTz5 = sagObj.z5 + sagObj.dZ5;
                        }
                    }
                    // 'MsgBox "Total 'BT adjusted' SAG through zone " & CStr(i) & " is " & Format(CStr(my_SAG), "0.0000")
                }

                i = i + 1;
                q = i;

            }
            //Wend

            if (j == 0) {
                old_SAG = parseFloat(my_SAG);

            }
            i = 0;

            if (sagObj.isReDo == true && (sagObj.statusFlag == "Back Toric")) {
                j = 1;

            }
            j = j + 1;
        }
        new_SAG = parseFloat(my_SAG);
        sagObj.delta_Sag = new_SAG - old_SAG;
        //Wend
        return sagObj;
    }
    //**************************************end ***************************************************************

    //**************************** Compute_dR_PL added by shwetha *********************************************
    this.Compute_dR_PL = function (compObj) {
        //'  this sub uses the PRIVATE variables for SAG (z0, z1, z2, z3, z4) and delta SAG (dZ1, dZ2, dZ3, dZ4) to compute
        //'  delta Radius at each of the four Zones (1-4)

        var this_P = 0;
        var this_dR = 0;

        var Zminor1 = 0;
        var Zminor2 = 0;
        var Zminor3 = 0;
        var Zminor4 = 0;

        var Rminor1 = 0;
        var Rminor2 = 0;
        var Rminor3 = 0;
        var Rminor4 = 0;

        var A_00 = 0;
        var A_01 = 0;
        var A_10 = 0;
        var A_11 = 0;

        var Ainv_00 = 0;
        var Ainv_01 = 0;
        var Ainv_10 = 0;
        var Ainv_11 = 0;

        var B_0 = 0;
        var B_1 = 0;

        var c = 0;
        var d = 0;
        var t = 0;
        var a0 = 0, A1 = 0;

        var denom = 0;
        //user_response As VbMsgBoxResult
        var user_response;

        //dZ1 = IIf(IsNull(Me.dSag_1), 0, -Me.dSag_1 / 1000)        
        compObj.dZ1 = compObj.dSag_1 == null ? 0 : (-compObj.dSag_1 / 1000); // delta sag from plift

        // dZ2 = IIf(IsNull(Me.dSag_2), 0, -Me.dSag_2 / 1000)
        compObj.dZ2 = compObj.dSag_2 == null ? 0 : (-compObj.dSag_2 / 1000);

        // dZ3 = IIf(IsNull(Me.dSag_3), 0, -Me.dSag_3 / 1000)
        compObj.dZ3 = compObj.dSag_3 == null ? 0 : (-compObj.dSag_3 / 1000);

        //dZ4 = IIf(IsNull(Me.dSag_4), 0, -Me.dSag_4 / 1000)
        compObj.dZ4 = compObj.dSag_4 == null ? 0 : (-compObj.dSag_4 / 1000);

        Zminor1 = compObj.z1 + compObj.dZ1;
        Zminor2 = compObj.z2 + compObj.dZ2;
        Zminor3 = compObj.z3 + compObj.dZ3;
        Zminor4 = compObj.z4 + compObj.dZ4;

        //'Zone 1   

        if (compObj.dZ1 == 0) {
            dR1 = 0;
        }
        else {
            if (compObj.PE1 == 1) {
                compObj.PE1 = 0.999;
            }
            else if (compObj.PE1 == -1) {
                compObj.PE1 = -0.999;
            }


            this_P = -(Math.pow(compObj.PE1, 2)) + 1

            A_00 = compObj.z0
            A_01 = 1
            A_10 = Zminor1
            A_11 = 1


            B_0 = -(Math.pow((compObj.POZ / 2), 2)) - (this_P * (Math.pow(compObj.z0, 2)));
            B_1 = -(Math.pow(((compObj.POZ / 2) + compObj.W1), 2)) - (this_P * (Math.pow(Zminor1, 2)));

            denom = (A_00 * A_11) - (A_01 * A_10)

            if (denom == 0) {
                // Then Exit Sub
                return compObj;
            }

            Ainv_00 = A_11 / denom
            Ainv_01 = -A_01 / denom
            Ainv_10 = -A_10 / denom
            Ainv_11 = A_00 / denom

            c = (Ainv_00 * B_0) + (Ainv_01 * B_1)
            d = (Ainv_10 * B_0) + (Ainv_11 * B_1)

            t = Math.pow(c, 2) - 4 * this_P * d;

            if (t > 0) {
                a0 = (-c - Math.sqrt(parseFloat(t))) / (2 * this_P)
                A1 = (-c + Math.sqrt(parseFloat(t))) / (2 * this_P)
                if (a0 <= A1)
                    Rminor1 = parseFloat((-(c + (2 * this_P * a0)) / 2)).toFixed(6); //Math.round((-(c + (2 * this_P * a0)) / 2), 6);
                else {
                    Rminor1 = parseFloat((-(c + (2 * this_P * A1)) / 2)).toFixed(6);// Math.round((-(c + (2 * this_P * A1)) / 2), 6);
                }
                dR1 = Rminor1 - compObj.PC1;
            }
            else {
                return compObj;
            }
        }

        //'Zone 2

        if (compObj.dZ2 == 0) {
            dR2 = 0;
        }
        else {
            if (compObj.PE2 == 1) {
                compObj.PE2 = 0.999
            }
            else if (compObj.PE2 == -1) {
                compObj.PE2 = -0.999;
            }
            this_P = -(Math.pow(compObj.PE2, 2)) + 1;

            A_00 = Zminor1
            A_01 = 1
            A_10 = Zminor2
            A_11 = 1

            B_0 = -(Math.pow(((compObj.POZ / 2) + compObj.W1), 2)) - (this_P * (Math.pow(Zminor1, 2)));

            B_1 = -(Math.pow(((compObj.POZ / 2) + compObj.W1 + compObj.W2), 2)) - (this_P * (Math.pow(Zminor2, 2)));

            denom = (A_00 * A_11) - (A_01 * A_10)

            //If denom = 0 Then Exit Sub
            if (denom == 0) {
                return compObj;
            }

            Ainv_00 = A_11 / denom
            Ainv_01 = -A_01 / denom
            Ainv_10 = -A_10 / denom
            Ainv_11 = A_00 / denom

            c = (Ainv_00 * B_0) + (Ainv_01 * B_1);
            d = (Ainv_10 * B_0) + (Ainv_11 * B_1);

            t = Math.pow(c, 2) - 4 * this_P * d;

            if (t > 0) {
                a0 = (-c - Math.sqrt(parseFloat(t))) / (2 * this_P)
                A1 = (-c + Math.sqrt(parseFloat(t))) / (2 * this_P)
                if (a0 <= A1) {
                    Rminor2 = parseFloat((-(c + (2 * this_P * a0)) / 2)).toFixed(6);// Math.round((-(c + (2 * this_P * a0)) / 2), 6)
                }
                else {
                    Rminor2 = parseFloat((-(c + (2 * this_P * A1)) / 2)).toFixed(6);// Math.round((-(c + (2 * this_P * A1)) / 2), 6)
                }
                dR2 = Rminor2 - compObj.PC2;
            }
            else {
                return compObj;
            }
        }

        //'Zone 3

        if (compObj.dZ3 == 0) {
            dR3 = 0;
        }
        else {
            if (compObj.PE3 == 1) {
                compObj.PE3 = 0.999;
            }
            else if (compObj.PE3 == -1) {
                compObj.PE3 = -0.999;
            }

            this_P = -(Math.pow(compObj.PE3, 2)) + 1;

            A_00 = Zminor2
            A_01 = 1
            A_10 = Zminor3
            A_11 = 1

            B_0 = -(Math.pow(((compObj.POZ / 2) + compObj.W1 + compObj.W2), 2)) - (this_P * (Math.pow(Zminor2, 2)));
            B_1 = -(Math.pow(((compObj.POZ / 2) + compObj.W1 + compObj.W2 + compObj.W3), 2)) - (this_P * (Math.pow(Zminor3, 2)));

            denom = (A_00 * A_11) - (A_01 * A_10)
            // If denom = 0 Then Exit Sub
            if (denom == 0) {
                return compObj;
            }

            Ainv_00 = A_11 / denom
            Ainv_01 = -A_01 / denom
            Ainv_10 = -A_10 / denom
            Ainv_11 = A_00 / denom

            c = (Ainv_00 * B_0) + (Ainv_01 * B_1);
            d = (Ainv_10 * B_0) + (Ainv_11 * B_1);

            t = Math.pow(c, 2) - 4 * this_P * d;

            if (t > 0) {// Then
                a0 = (-c - Math.sqrt(parseFloat(t))) / (2 * this_P);
                A1 = (-c + Math.sqrt(parseFloat(t))) / (2 * this_P);
                if (a0 <= A1) {// Then
                    Rminor3 = parseFloat((-(c + (2 * this_P * a0)) / 2)).toFixed(6); //Math.round((-(c + (2 * this_P * a0)) / 2), 6)
                }
                else {
                    Rminor3 = parseFloat((-(c + (2 * this_P * A1)) / 2)).toFixed(6); //Math.round((-(c + (2 * this_P * A1)) / 2), 6)
                }
                dR3 = Rminor3 - compObj.PC3;
            }
            else {
                return compObj;
            }
        }

        //'Zone 4

        if (compObj.dZ4 == 0) {// Then
            dR4 = 0
        }
        else {
            if (compObj.PE4 == 1) { //Then
                compObj.PE4 = 0.99999;
            }
            else if (compObj.PE4 == -1) { //Then
                compObj.PE4 = -0.99999;
            }

            this_P = -(Math.pow(compObj.PE4, 2)) + 1;

            if (this_P == 0) {//Then
                this_P = 0.000001;
            }

            A_00 = Zminor3;
            A_01 = 1;
            A_10 = Zminor4;
            A_11 = 1;

            B_0 = -(Math.pow(((compObj.POZ / 2) + compObj.W1 + compObj.W2 + compObj.W3), 2)) - (this_P * (Math.pow(Zminor3, 2)));
            B_1 = -(Math.pow(((compObj.POZ / 2) + compObj.W1 + compObj.W2 + compObj.W3 + compObj.W4), 2)) - (this_P * (Math.pow(Zminor4, 2)));

            denom = (A_00 * A_11) - (A_01 * A_10)
            // If denom = 0 Then Exit Sub
            if (denom == 0) {
                return compObj;
            }
            Ainv_00 = A_11 / denom;
            Ainv_01 = -A_01 / denom;
            Ainv_10 = -A_10 / denom;
            Ainv_11 = A_00 / denom;

            c = (Ainv_00 * B_0) + (Ainv_01 * B_1)
            d = (Ainv_10 * B_0) + (Ainv_11 * B_1)

            t = Math.pow(c, 2) - 4 * this_P * d;

            if (t > 0) {// Then
                a0 = (-c - Math.sqrt(parseFloat(t))) / (2 * this_P);
                A1 = (-c + Math.sqrt(parseFloat(t))) / (2 * this_P);


                if (a0 <= A1) {// Then
                    Rminor4 = parseFloat((-(c + (2 * this_P * a0)) / 2)).toFixed(6);//Math.round((-(c + (2 * this_P * a0)) / 2), 6)
                }
                else {
                    Rminor4 = parseFloat((-(c + (2 * this_P * A1)) / 2)).toFixed(6); //Math.round((-(c + (2 * this_P * A1)) / 2), 6)
                }
                dR4 = Rminor4 - compObj.PC4;
            }
            else {
                return compObj;
            }
        }

        compObj.dRad_1 = parseFloat((dR1).toFixed(3));//Math.round(compObj.dR1, 3);
        compObj.dRad_2 = parseFloat((dR2).toFixed(3)); //Math.round(compObj.dR2, 3);
        compObj.dRad_3 = parseFloat((dR3).toFixed(3)); //Math.round(compObj.dR3, 3);
        compObj.dRad_4 = parseFloat((dR4).toFixed(3));// Math.round(compObj.dR4, 3);

        return compObj;
    }
    //****************************************end*****************************************************

    //***************************** get delta tpc values *******************************

    this.getDeltaTpcValues = function (model) {
        var div2 = parseFloat(model.dZ2) / 1000;
        var div3 = parseFloat(model.dZ3) / 1000;
        var div4 = parseFloat(model.dZ4) / 1000;
        model.dZ2 = div2;
        model.dZ3 = parseFloat(div3) - parseFloat(div2);
        model.dZ4 = parseFloat(div4) - parseFloat(div3);

        var defaultdzObj = this.set_default_dZ(model);
        // set original pc values to result
        defaultdzObj.PC1 = defaultdzObj.Me_pc1;
        defaultdzObj.PC2 = defaultdzObj.Me_pc2;
        defaultdzObj.PC3 = defaultdzObj.Me_pc3;
        defaultdzObj.PC4 = defaultdzObj.Me_pc4;
        var computeDrObj = this.Compute_dR(defaultdzObj);
        return computeDrObj;
    }


    //********************* Europa tangent calculations**************************// 
    //added by priyanka
    this.cbo_K_FL = function (Me) {
        //Initialize sub object 
        Me.OLE_1AB_Off = {};
        Me.OLE_1AB_On = {};
        Me.cbo_microns2 = {};
        Me.cbo_mm2 = {};
        Me.txt_hide1 = {};
        Me.lbl_PlusSign = {};
        Me.option_direction_1B = {};
       // Me.txt_Pe1B = {};
       // Me.txt_Pe1B_new = {};
        Me.lbl_PE1B = {};
        Me.cbo_microns_1B = {};
        Me.txt_PC1_Step_1B = {};
        Me.Box_PC1B = {};
        Me.Line407 = {};
        Me.lbl_PE1 = {};
        Me.Form = {};
        Me.txt_z3 = {};
        Me.txt_dR3 = {};
        //end

        if (Me.cbo_K_FL > 0) {
            var DIA = Me.option_Dia;
            var KFL = Me.cbo_K_FL;

            if (Me.option_Dia > 15) {

                Me = this.Clear_orig_values(Me);
                Me = this.Clear_new_values(Me);

                Me.option_Dia = DIA;
                //DIA = DIA / 10;
                Me.cbo_K_FL = KFL;

                Me = this.populate_origValues(Me);

                Me.OLE_1AB_Off.Visible = true;
                Me.OLE_1AB_Off.SetFocus
                Me.OLE_1AB_On.Visible = false;
                Me.UI_MODE = 1;

                Me = this.Hide_1B(Me);
                //End If
            }
        }
        else {
            this.Clear_orig_values;
            this.Clear_new_values;
            // End If
        }

        Me.option_SagRad_mode = 1;  // ' select steps (�) microns as default mode for PC2
        Me.Option_DirectionRad = null;

        //Me.cbo_microns2.Locked = true;
       // Me.cbo_microns2.Visible = true;
        Me.cbo_mm2.Locked = true;
        Me.cbo_mm2.Visible = false;
        Me.txt_hide1.SetFocus

        return Me;
    }
    //added by priyanka
    this.Clear_orig_values = function (Me) {

        Me.option_Dia = null;
        Me.cbo_K_FL = null;
        Me.option_SagRad_mode = 1;
        Me.option_direction = null;
        //Me.option_direction_1B = null;
        Me.Option_DirectionRad = null;
        Me.cbo_microns = null;
        Me.txt_PC1_Step = "";

        //Me.cbo_mm2 = null;
        Me.cbo_mm2.Locked = true;
        Me.cbo_mm2.Visible = false;
        Me.cbo_microns2.Visible = true;
        //Me.cbo_microns2 = null;
        Me.cbo_microns2.Locked = true;

        Me.txt_PC2_Step = "";
        //Me.cbo_microns_1B = null;
        //Me.txt_PC1_Step_1B = "";

        Me.txt_PC1_mm = null;
        Me.txt_PC2_mm = null;
        Me.txt_PC2_mm_new = null;
        Me.txt_dR2 = null;
        //Me.txt_dR3 = null;

        Me.txt_TPC = null;

        Me.txt_Pe1 = null;
        Me.txt_Ke1 = null;
        Me.txt_Pe1_new = null;
        Me.txt_Ke1_new = null;
        //Me.txt_Pe1B_new = null;
        Me.txt_Ke1B_new = null;

        Me.txt_Pe2 = null;
        Me.txt_Ke2 = null;
        Me.txt_Pe2_new = null;
        Me.txt_Ke2_new = null;

        Me.txt_Sag0 = null;
        Me.txt_Sag0_new = null;
        Me.txt_dSag1 = null;
        Me.txt_dSag1_new = null;
        Me.txt_Sag1 = null;
        Me.txt_Sag1_new = null;
        Me.txt_dSag1B = null;
        Me.txt_dSag1B_new = null;
        Me.txt_Sag1B = null;
        Me.txt_Sag1B_new = null;
        Me.txt_dSag2 = null;
        Me.txt_dSag2_new = null;
        Me.txt_Sag2 = null;
        Me.txt_Sag2_new = null;
        Me.lbl_PlusSign.Visible = false;

        Me.txt_z0 = null;
        Me.txt_z1 = null;
        Me.txt_z2 = null;
        //Me.txt_z3 = null;
        Me.txt_dR1 = null;
        Me.txt_dR2 = null;
        //Me.txt_dR3 = null;
        return Me;
    }

    this.SagTotal_proc = function (this_mode, BT_calc, GetDefaults, Me, Diopters) {
        //On Error GoTo SagTotal_proc_Err
        Me.ZON = 0;
        Me.XSAG = 0;
        Me.YSAG = 0;
        Me.EVLU = 0;

        Me.RADX = Me.BCFL;
        Me.DDX = Me.POZ;
        Me.EVLU = Me.BCE;

        //Dim my_form As Form
        //Set my_form = Forms("Tangent_new_new")

        Me.YSAG = this.Sag_proc(Me);
        //Me.my_form.txt_z0 = Me.YSAG;

        Me.txt_z0 = Me.YSAG;

        if (this_mode == "dSag") {
            //' fall through
        }

        else if (BT_calc == true) {
            if (Diopters == true || Diopters == 0) {
                Me.PC1 = Math.round(Me.PC1 - (Me.txt_dR1 != null ? Me.txt_dR1 : 0), 6);
            }
            else {
                PC1 = Diopters;
            }
        }
        else {
            PC1 = Me.txt_PC1_mm_new != null ? Me.txt_PC1_mm_new : Me.txt_PC1_mm;
        }


        if (GetDefaults == true) {
            Me.PC1 = Me.txt_PC1_mm;
        }
        if (Diopters != null && Diopters != 0) {
            Me.PC1 = Diopters;
        }

        if (Me.PC1 > 0) {
            Me.RADX = Me.PC1;
            Me.DDX = Me.POZ + 2 * Me.W1;
            Me.EVLU = Me.txt_Pe1_new != null ? Me.txt_Pe1_new : Me.txt_Pe1;//Me.PE1;
            //'EVLU = 0.126574
            Me.ZON = 1;
            Me.YSAG = Me.YSAG + this.Sag_proc(Me);
            Me.DDX2 = Me.POZ;

            Me.XSAG = Me.XSAG + this.SagPiece_proc(Me);
            Me.tempsagx = Me.YSAG - Me.XSAG;

            if (GetDefaults == true) {
                Me.Std_Z1 = Me.tempsagx;
            }
            //'Else
            //'    my_form.txt_z1 = tempsagx
            //End If
            Me.txt_z1 = Me.tempsagx;
            //End If
        }
        if (BT_calc == true) {
            //'MsgBox "BT_calc = True   (SagTotal_proc)"
            if (Me.UI_MODE == 1) {
                Me.PC2 = (Me.txt_PC2_mm_new != null ? Me.txt_PC2_mm_new : Me.txt_PC2_mm);
            }
            else if (Me.UI_MODE == 3) {
                Me.PC2 = Me.PC1;
                Me.PC3 = (Me.txt_PC2_mm_new != null ? Me.txt_PC2_mm_new : Me.txt_PC2_mm);
            }
            else {
                Me.PC2 = 13;
                Me.PC3 = 0;
            }
        }
        else {
            //return Me;// 'MsgBox "BT_calc = False  (SagTotal_proc)"
            if (Me.UI_MODE == 1) {
                Me.PC2 = Me.txt_PC2_mm_new != null ? Me.txt_PC2_mm_new : Me.txt_PC2_mm;
            }

            else if (Me.UI_MODE == 3) {
                Me.PC2 = Me.PC1;
                Me.PC3 = Me.txt_PC2_mm_new != null ? Me.txt_PC2_mm_new : Me.txt_PC2_mm;
            }

            else {
                Me.PC2 = 13;
                Me.PC3 = 0;

            }
        }
        if (GetDefaults == true) {
            if (Me.UI_MODE == 1) {
                Me.PC2 = Me.txt_PC2_mm;
            }

            else {
                Me.PC2 = Me.PC1;
                Me.PC3 = Me.txt_PC2_mm;
            }

        }


        if (Me.PC2 > 0) {
            Me.RADX = Me.PC2;
            Me.DDX = Me.POZ + 2 * Me.W1 + 2 * Me.W2;
            Me.EVLU = Me.PE2;
            Me.ZON = 2;
            Me.YSAG = Me.YSAG+ this.Sag_proc(Me);
            Me.DDX2 = Me.POZ + 2 * Me.W1

            Me.XSAG = Me.XSAG + this.SagPiece_proc(Me);
            //' z2
            Me.tempsagx = Me.YSAG - Me.XSAG;
            if (GetDefaults == true) {
                Me.Std_Z2 = Me.tempsagx;
            }


            Me.txt_z2 = Me.tempsagx;
        }


        if (Me.PC3 > 0) {
            Me.RADX = Me.PC3;
            Me.DDX = Me.POZ + 2 * Me.W1 + 2 * Me.W2 + 2 * Me.W3;
            Me.EVLU = Me.PE3;
            Me.ZON = 3;
            Me.YSAG = Me.YSAG+ this.Sag_proc(Me);
            Me.DDX2 = Me.POZ + 2 * Me.W1 + 2 * Me.W2;

            Me.XSAG = Me.XSAG + this.SagPiece_proc(Me);
            //' z3
            Me.tempsagx = Me.YSAG - Me.XSAG;
            if (GetDefaults == true) {
                Me.Std_Z3 = Me.tempsagx;
            }

            //End If
            Me.txt_z3 = Me.tempsagx;
            //End If
        }
        return Me;
    }
    //added by shwetha
    this.Clear_new_values = function (Me) {
        Me.txt_Pe1_new = null;
        Me.txt_Ke1_new = null;
        //Me.txt_Pe1B_new = null;
        Me.txt_Ke1B_new = null;

        Me.txt_Pe2_new = null;
        Me.txt_Ke2_new = null;

        Me.txt_Sag0_new = null;
        Me.txt_dSag1_new = null;
        Me.txt_Sag1_new = null;
        Me.txt_Sag1B_new = null;
        Me.txt_dSag1B_new = null;
        Me.txt_dSag2_new = null;
        Me.txt_Sag2_new = null;
        Me.txt_SagTotal_new = null;
        Me.txt_dSag = null;
        Me.lbl_PlusSign.Visible = false;
        return Me;
    }
    //added by shwetha
    this.populate_origValues = function (Me) {
        //On Error GoTo populate_origValues_Err

        let this_ver;
        let this_dia;
        let this_BC;
        let this_style;
        let this_step;

        //let rst;
        let strSQL = [];
        let DIA = Me.option_Dia;
        let KFL = Me.cbo_K_FL;
        let myDB = Me.tangentData;
        // Set myDB = CurrentDb

        //VER = Me.UI_MODE
        // If DIA > 0 Then this_dia = DIA
        // If KFL > 0 Then this_BC = KFL
        //STYL = "Standard"

        if (DIA > 0) {
            this_dia = DIA;
        }
        if (KFL > 0) {
            this_BC = DIA;
        }
        let STYL = "Standard";

        //strSQL = "SELECT Version, Dia, K_FL, Style, Step, " & _
        //"BC, PC1A, PC1B, PC2, poz, W1A, W1B, W2, " & _
        //"Be, Pe1A, Ke1A, Pe1B, Ke1B, Pe2, Ke2, " & _
        //"TotalSag, Sag0, Sag1A, dSag1A, Sag1B, dSag1B, Sag2, dSag2 " & vbCrLf & _
        //" FROM Tangent_data_1A1B" & vbCrLf & _
        //" WHERE ((Dia=" & DIA & ") AND " & _
        //"(K_FL=" & KFL & ") AND " & _
        //"(Style='" & STYL & "'))"
        //'MsgBox strSQL

        if (myDB.length > 0) {
            for (var i = 0; i < myDB.length; i++) {
                if (myDB[i].dia == DIA && myDB[i].kfl == KFL && myDB[i].style == STYL) {
                    strSQL.push(myDB[i]);
                }
            }
        }


        // Set rst = myDB.OpenRecordset(strSQL)

        //If Not(rst.EOF And rst.BOF) Then
        //If IsNull(rst("PC1A")) = False Then
        //Me.txt_PC1_mm = Round(rst("PC1A"), 3)
        //Else
        //Me.txt_PC1_mm = Null
        //End If

        let rst = strSQL[0];

        //if (!(rst.EOF && rst.BOF)) {
        if (rst) {


            if (rst.pC1A != null) {
                Me.txt_PC1_mm = parseFloat((rst.pC1A).toFixed(3));
            }
            else {
                Me.txt_PC1_mm = null;
            }


            //    If IsNull(rst("PC2")) = False Then
            //    Me.txt_PC2_mm = Round(rst("PC2"), 3)
            //    Else
            //    Me.txt_PC2_mm = Null
            //End If

            if (rst.pC2 != null) {
                Me.txt_PC2_mm = parseFloat((rst.pC2).toFixed(3));
            }
            else {
                Me.txt_PC2_mm = null;
            }

            //    If IsNull(rst("Pe1A")) = False Then
            //    Me.txt_Pe1 = Round(rst("Pe1A"), 6)
            //    Else
            //    Me.txt_Pe1 = Null
            //End If

            if (rst.pe1A != null) {
                Me.txt_Pe1 = parseFloat((rst.pe1A).toFixed(6));
            }
            else {
                Me.txt_Pe1 = null;
            }

            //    If IsNull(rst("Ke1A")) = False Then
            //    Me.txt_Ke1 = Round(rst("Ke1A"), 6)
            //    Else
            //    Me.txt_Ke1 = Null
            //End If

            if (rst.ke1A != null) {
                Me.txt_Ke1 = parseFloat((rst.ke1A).toFixed(6));
            }
            else {
                Me.txt_Ke1 = null;
            }

            //    If IsNull(rst("Pe2")) = False Then
            //    Me.txt_Pe2 = Round(rst("Pe2"), 6)
            //    Else
            //    Me.txt_Pe2 = Null
            //End If

            if (rst.pe2 != null) {
                Me.txt_Pe2 = parseFloat((rst.pe2).toFixed(6));
            }
            else {
                Me.txt_Pe2 = null;
            }

            //    If IsNull(rst("Ke2")) = False Then
            //    Me.txt_Ke2 = Round(rst("Ke2"), 6)
            //    Else
            //    Me.txt_Ke2 = Null
            //End If


            if (rst.ke2 != null) {
                Me.txt_Ke2 = parseFloat((rst.ke2).toFixed(6));
            }
            else {
                Me.txt_Ke2 = null;
            }

            //    If IsNull(rst("Sag0")) = False Then
            //    Me.txt_Sag0 = Round(rst("Sag0"), 6)
            //    Me.txt_z0 = Me.txt_Sag0
            //    Else
            //    Me.txt_Sag0 = Null
            //    Me.txt_z0 = Null
            //End If

            if (rst.sag0 != null) {
                Me.txt_Sag0 = parseFloat((rst.sag0).toFixed(6));
                Me.txt_z0 = Me.txt_Sag0;
            }
            else {
                Me.txt_Sag0 = null;
                Me.txt_z0 = null;
            }

            //    If Me.UI_MODE = 1 Then
            //    If IsNull(rst("Sag1B")) = False Then
            //    Me.txt_Sag1 = Round(rst("Sag1B"), 6)
            //    Me.txt_z1 = Me.txt_Sag1
            //    Else
            //    Me.txt_Sag1 = Null
            //    Me.txt_z1 = Null
            //End If

            if (Me.UI_MODE == 1) {
                if (rst.sag1B != null) {
                    Me.txt_Sag1 = parseFloat((rst.sag1B).toFixed(6));
                    Me.txt_z1 = Me.txt_Sag1;
                }
                else {
                    Me.txt_Sag1 = null;
                    Me.txt_z1 = null;
                }

                //If IsNull(rst("Sag0")) = False And IsNull(rst("Sag1B")) = False Then
                //Me.txt_dSag1 = Round((rst("Sag1B") - rst("Sag0")), 6)
                //Else
                //Me.txt_dSag1 = Null
                //End If

                if (rst.sag0 != null && rst.sag1B != null) {
                    Me.txt_dSag1 = parseFloat((rst.sag1B - rst.sag0).toFixed(6));
                }
                else {
                    Me.txt_dSag1 = null;
                }

                Me.txt_Sag1B = Me.txt_Sag1
                Me.txt_dSag1B = 0
            }
            else if (Me.UI_MODE == 3) {

                //If IsNull(rst("Sag1A")) = False Then
                //Me.txt_Sag1 = Round(rst("Sag1A"), 6)
                //Me.txt_z1 = Me.txt_Sag1
                //Else
                //Me.txt_Sag1 = Null
                //Me.txt_z1 = Null
                //    End If

                if (rst.sag1A != null) {
                    Me.txt_Sag1 = parseFloat((rst.sag1A).toFixed(6));
                    Me.txt_z1 = Me.txt_Sag1;
                }
                else {
                    Me.txt_Sag1 = null;
                    Me.txt_z1 = null;
                }

                //If IsNull(rst("dSag1A")) = False Then
                //Me.txt_dSag1 = Round(rst("dSag1A"), 6)
                //Else
                //Me.txt_dSag1 = Null
                //End If

                if (rst.dSag1A != null) {
                    Me.txt_dSag1 = parseFloat((rst.dSag1A).toFixed(6));
                }
                else {
                    Me.txt_dSag1 = null;
                }

                //If IsNull(rst("Sag1B")) = False Then
                //Me.txt_Sag1B = Round(rst("Sag1B"), 6)
                //Me.txt_z2 = Me.txt_Sag1B
                //Else
                //Me.txt_Sag1B = Null
                //Me.txt_z2 = Null
                //End If

                if (rst.sag1B != null) {
                    Me.txt_Sag1B = parseFloat((rst.sag1B).toFixed(6));
                    Me.txt_z2 = Me.txt_Sag1B;
                }
                else {
                    Me.txt_Sag1B = null;
                    Me.txt_z2 = null;
                }

                //If IsNull(rst("dSag1B")) = False Then
                //Me.txt_dSag1B = Round(rst("dSag1B"), 6)
                //Else
                //Me.txt_dSag1B = Null
                //End If

                if (rst.dSag1B != null) {
                    Me.txt_dSag1B = parseFloat((rst.dSag1B).toFixed(6));
                }
                else {
                    Me.txt_dSag1B = null;
                }

                //End If
            }

            //If IsNull(rst("Sag2")) = False Then
            //Me.txt_Sag2 = Round(rst("Sag2"), 6) * 1000
            //If Me.UI_MODE = 3 Then
            //Me.txt_z3 = Round(rst("Sag2"), 6)
            //ElseIf Me.UI_MODE = 1 Then
            //Me.txt_z2 = Round(rst("Sag2"), 6)
            //Me.txt_z3 = 0
            //End If
            //Else
            //Me.txt_Sag2 = Null
            //Me.txt_z2 = Null
            //Me.txt_z3 = Null
            //End If

            if (rst.sag2 != null) {
                Me.txt_Sag2 = parseFloat((rst.sag2).toFixed(6)) * 1000;
                if (Me.UI_MODE == 3) {
                    Me.txt_z3 = parseFloat((rst.sag2).toFixed(6));
                }
                else if (Me.UI_MODE == 1) {
                    Me.txt_z2 = parseFloat((rst.sag2).toFixed(6));
                    Me.txt_z3 = 0;
                }
            }
            else {
                Me.txt_Sag2 = null;
                Me.txt_z2 = null;
                Me.txt_z3 = null;
            }


            //If IsNull(rst("dSag2")) = False Then
            //Me.txt_dSag2 = Round(rst("dSag2"), 6)
            //Else
            //Me.txt_dSag2 = Null
            //End If

            if (rst.dSag2 != null) {
                Me.txt_dSag2 = parseFloat((rst.dSag2).toFixed(6));
            }
            else {
                Me.txt_dSag2 = null;
            }
            // End If
            //}

            //populate_origValues_Exit:
            //rst.Close
            //Set rst = Nothing
            //Exit Sub

            //populate_origValues_Err:
            //MsgBox Err
            //GoTo populate_origValues_Exit
            //End Sub
        }
        return Me;
    }
    //added by shwetha 
    this.Hide_1B = function (Me) {
        Me.option_direction_1B.Visible = false;

       // Me.txt_Pe1B.Visible = false;
        //Me.txt_Pe1B_new.Visible = false;
        Me.lbl_PE1B.Visible = false;

        Me.cbo_microns_1B.Visible = false;
        Me.txt_PC1_Step_1B.Visible = false;
        Me.Box_PC1B.Visible = false;

        Me.Line407.Visible = false;

        Me.lbl_PE1.Caption = "Pe1";

        Me.Form.Caption = " Basic Mode";

        Me.txt_z3.Visible = false;
        Me.txt_dR3.Visible = false;
        return Me;
        //End Sub
    }
    //added by shwetha 
    this.cbo_microns = function (Me) {
        var new_K;
        var this_x0;
        var this_x1;

        if (Me.cbo_microns >= 0) {
            Me.txt_PC1_Step = Me.micronsteps[1].displaySteps;
            if (Me.UI_MODE == 1) {
                //' fall through';
            }
            else if (Me.UI_MODE == 3) {
                Me.cbo_microns_1B = Me.cbo_microns;
                Me.txt_PC1_Step_1B = Me.micronsteps[1].displaySteps;
            }
        }
        else {
            Me.txt_PC1_Step = "";
            this.Clear_new_values();
            //Me.txt_hide2.SetFocus
        }

        var blnContinue = false;
        blnContinue = this.StartingDesn_IsReady(Me);


        if (blnContinue == true) {
            if (Me.option_direction == null) {
                return;
            }
            else if (Me.cbo_microns == null) {
                return;
            }
            else {
                if (Me.UI_MODE == 1) {
                    Me = this.populate_newValues(Me);
                    Me = this.Set_Public_vars(Me);

                    //'set new Ke1 (Zone 1-A)
                    this_x0 = Me.POZ / 2;
                    this_x1 = Me.POZ / 2 + Me.W1;
                    new_K = this.GetNewEccen(Me.txt_PC1_mm, this_x0, Me.txt_Sag0_new, this_x1, Me.txt_Sag1_new);
                    if (Math.abs(new_K) < 10000) {
                        Me.txt_Ke1_new = new_K;
                        if (new_K < 0) {
                            Me.txt_Pe1_new = -Math.sqrt(-new_K);// -Sqr(-new_K);
                        }
                        else {
                            Me.txt_Pe1_new = Math.sqrt(new_K);
                            //End If
                        }
                    }
                    else {
                        //MsgBox "There was an error calculating the new value for eccentricity for zone 1-A", vbCritical, "Eccentricity Failure"
                        //End If
                    }
                   // if (Me.cbo_microns2 != null) {
                        if (Me.cbo_microns2 >= 0 || Me.cbo_mm2 >= 0) {
                            Me = this.SagTotal_proc("dSag", true, true, Me);

                            if (Me.option_SagRad_mode == 1) {// Then
                                if (Me.cbo_microns2 > 0) {
                                    Me = this.cbo_microns2(Me);
                                }
                                else if (Me.option_SagRad_mode == 2) {
                                    if (Me.cbo_mm2 > 0) {
                                        Me = this.cbo_mm2(Me);
                                    }
                                    else {
                                        //' fall through
                                    }
                                }
                            }
                        }
                  //  }
                }
                else if (Me.UI_MODE == 3) {
                    Me.cbo_microns_1B = Me.cbo_microns;
                    Me.txt_PC1_Step_1B = Me.micronsteps[1].displaySteps;

                    Me = this.SetNewSagVals(Me);
                    Me = this.Set_Public_vars(Me);

                    //'set new Ke1 (Zone 1-A)
                    this_x0 = Me.POZ / 2;
                    this_x1 = Me.POZ / 2 + Me.W1;
                    new_K = this.GetNewEccen(Me.txt_PC1_mm, this_x0, Me.txt_Sag0_new, this_x1, Me.txt_Sag1_new);
                    if (Math.abs(new_K) < 10000) {
                        Me.txt_Ke1_new = new_K;
                        if (new_K < 0) {
                            Me.txt_Pe1_new = -Math.sqrt(-new_K);
                        }
                        else {
                            Me.txt_Pe1_new = Math.sqrt(new_K);
                            //End If
                        }
                    }
                    else {
                        //MsgBox "There was an error calculating the new value for eccentricity for zone 1-A", vbCritical, "Eccentricity Failure"
                        //End If
                    }

                    // ' Zone 1-B
                    this_x0 = Me.POZ / 2 + Me.W1;
                    this_x1 = Me.POZ / 2 + Me.W1 + Me.W2;
                    new_K = this.GetNewEccen(Me.txt_PC1_mm, this_x0, Me.txt_Sag1_new, this_x1, Me.txt_Sag1B_new);
                    if (Math.abs(new_K) < 10000) {
                        Me.txt_Ke1B_new = new_K;
                        if (new_K < 0) {
                            Me.txt_Pe1B_new = - Math.sqrt(-new_K);
                        }
                        else {
                            Me.txt_Pe1B_new = Math.sqrt(new_K);
                            //End If
                        }
                    }
                    else {
                        //MsgBox "There was an error calculating the new value for eccentricity for zone 1-B", vbCritical, "Eccentricity Failure"
                        //End If
                    }
                   // if (Me.cbo_microns2 != null) {
                        if (Me.cbo_microns2 >= 0 || Me.cbo_mm2 >= 0) {
                            Me = this.SagTotal_proc("dSag", true, true, Me);

                            if (Me.option_SagRad_mode == 1) {// Then
                                if (Me.cbo_microns2 > 0) {
                                    Me = this.cbo_microns2(Me); //need to write this method
                                }
                                
                            }
                            else if (Me.option_SagRad_mode == 2) {
                                if (Me.cbo_mm2 > 0) {
                                    Me = this.cbo_mm2(Me); //need to write this method
                                }
                                else {
                                    //' fall through
                                }
                            }
                        }
                   // }
                }
            }
        }
        Me = this.Reset_New_SagVals(Me);
        //Me.txt_hide2.SetFocus;
        return Me;
    }
    //added by shwetha
    this.StartingDesn_IsReady = function (Me) {
        var StartingDesn_IsReady = false;
        if (Me.option_Dia >= 15) {

            DIA = Me.option_Dia;
        }
        else {
            return false;
        }

        if (Me.cbo_K_FL >= 36) {
            KFL = Me.cbo_K_FL;
        }
        else {
            return false;
        }
        StartingDesn_IsReady = true;
        return StartingDesn_IsReady;
    }
    //added by shwetha
    this.populate_newValues = function (Me) {
        //On Error GoTo populate_newValues_Err

        Me.txt_Sag0_new = Me.txt_Sag0;
        Me.txt_z0 = Me.txt_Sag0_new;
        var micronsData = null;
        //#region reference for IIF conditions
        //if (Me.option_direction == 1) {
        //    if (Me.cbo_microns == null) {
        //        micronsData = 0;
        //    }
        //    else {
        //        micronsData = -(Me.cbo_microns);
        //    }
        //}
        //else if (Me.option_direction == 2) {
        //    if (Me.cbo_microns == null) {
        //        micronsData = 0;
        //    }
        //    else {
        //        micronsData = Me.cbo_microns;
        //    }
        //}
        //else {
        //    micronsData = 0;
        //}
        //#endregion

        micronsData = (Me.option_direction == 1 ? (Me.cbo_microns == null ? 0 : -(Me.cbo_microns)) : (Me.option_direction == 2 ? (Me.cbo_microns == null ? 0 : Me.cbo_microns) : 0)) / 1000;
        Me.txt_Sag1_new = parseFloat((Me.txt_Sag1 + micronsData).toFixed(6));

        Me.txt_z1 = Me.txt_Sag1_new;
        Me.txt_dSag1_new = parseFloat((Me.txt_Sag1_new - Me.txt_Sag0_new).toFixed(6));

        //' Zone 1 goes out to Sag1B
        if (Me.UI_MODE == 3) {
            var sag1Bdata = (Me.option_direction_1B == 1 ? -Me.cbo_microns_1B : (Me.option_direction_1B == 2 ? Me.cbo_microns_1B : 0)) / 1000;
            // Me.txt_Sag1B_new = Me.txt_Sag1_new + IIf(Me.option_direction_1B = 1, -Me.cbo_microns_1B, IIf(Me.option_direction_1B = 2, Me.cbo_microns_1B, 0)) / 1000
            Me.txt_Sag1B_new = parseFloat((Me.txt_Sag1_new + sag1Bdata).toFixed(6));
            Me.txt_z2 = Me.txt_Sag1B_new;
        }
        else if (Me.UI_MODE == 1) {
            Me.txt_Sag1B_new = Me.txt_Sag1_new;
            Me.txt_z2 = parseFloat((Me.txt_Sag1_new + Me.txt_dSag2).toFixed(6));
        }
        else {
            Me.txt_Sag1B_new = 0;
            Me.txt_z2 = 0;
        }
        Me.txt_dSag1B_new = parseFloat((Me.txt_Sag1B_new - Me.txt_Sag1_new).toFixed(6));


        // ' Zone 2 picks up changes to Sag2
        if (Me.UI_MODE == 3) {
            if (Me.Option_DirectionRad > 0 && (Me.cbo_microns2 > 0 || Me.cbo_mm2 > 0)) {
                //If option_SagRad_mode = 1 Then  //' this means User chooses steps (�) Mode
                if (Me.option_SagRad_mode == 1) {
                    Me.txt_Sag2 = Me.txt_Sag1B + (Me.Option_DirectionRad == 1 ? -Me.cbo_microns2 : (Me.Option_DirectionRad == 2 ? Me.cbo_microns2 : 0)); //+IIf(Me.Option_DirectionRad = 1, -Me.cbo_microns2, IIf(Me.Option_DirectionRad = 2, Me.cbo_microns2, 0))
                    Me.txt_dSag2_new = parseFloat((Me.txt_Sag2_new - Me.txt_Sag1B_new).toFixed(6));
                }
                else if (Me.option_SagRad_mode == 2) {//Then  ' this means User chooses radius (mm) Mode

                }
                else {

                }
                //End If
            }
            else {
                //If Me.cbo_microns > 0 And Me.option_direction > 0 Then
                //Me.txt_Sag2_new = Me.txt_Sag2 + IIf(Me.option_direction = 1, -Me.cbo_microns, IIf(Me.option_direction = 2, Me.cbo_microns, 0)) + _
                //IIf(Me.option_direction_1B = 1, -Me.cbo_microns_1B, IIf(Me.option_direction_1B = 2, Me.cbo_microns_1B, 0))
                //Me.txt_Sag2_new = Me.txt_Sag2_new + IIf(Me.Option_DirectionRad = 1, -Me.cbo_microns2, IIf(Me.Option_DirectionRad = 2, Me.cbo_microns2, 0))
                //Else
                //End If

                if (Me.cbo_microns > 0 && Me.option_direction > 0) {
                    Me.txt_Sag2_new = Me.txt_Sag2 + (Me.option_direction == 1 ? -Me.cbo_microns : (Me.option_direction == 2 ? Me.cbo_microns : 0) +
                        (Me.option_direction_1B == 1 ? -Me.cbo_microns_1B : (Me.option_direction_1B == 2 ? Me.cbo_microns_1B : 0)));
                    //Me.txt_Sag2_new = Me.txt_Sag2_new + IIf(Me.Option_DirectionRad = 1, -Me.cbo_microns2, IIf(Me.Option_DirectionRad = 2, Me.cbo_microns2, 0));
                    Me.txt_Sag2_new = parseFloat((Me.txt_Sag2_new + (Me.Option_DirectionRad == 1 ? -Me.cbo_microns2 : (Me.Option_DirectionRad == 2 ? Me.cbo_microns2 : 0))).toFixed(6));
                }
                else {

                }
                Me.txt_z3 = parseFloat((Me.txt_Sag2_new / 1000).toFixed(6));
            }
        }
        else if (Me.UI_MODE == 1) {

            if (Me.option_SagRad_mode == 1) {
                if (Me.option_direction == 1) {
                    Me.txt_Sag2_new = parseFloat((Me.txt_Sag2 - Me.cbo_microns).toFixed(6));
                }
                else if (Me.option_direction == 2) {
                    Me.txt_Sag2_new = parseFloat((Me.txt_Sag2 + Me.cbo_microns).toFixed(6));
                }
            } else {

            }
            Me.txt_z2 = parseFloat((Me.txt_Sag2_new / 1000).toFixed(6));
        }
        return Me;
    }
    //added by shwetha
    this.Set_Public_vars = function (Me) {
        Me.BCFL = parseFloat((337.5 / Me.cbo_K_FL).toFixed(3));
        Me.PC1 = Me.txt_PC1_mm;
        if (Me.option_Dia == 15.5) {
            Me.POZ = 8;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.1;
                Me.W2 = 1.65;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.05;
                Me.W2 = 1.05;
                Me.W3 = 1.65;
            }
        }
        else if (Me.option_Dia == 16) {
            Me.POZ = 8.5;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.1;
                Me.W2 = 1.65;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.05;
                Me.W2 = 1.05;
                Me.W3 = 1.65;
            }
        }
        else if (Me.option_Dia == 16.5) {
            Me.POZ = 8.5;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.35;
                Me.W2 = 1.65;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.2;
                Me.W2 = 1.15;
                Me.W3 = 1.65;
            }
        }
        else if (Me.option_Dia == 17) {
            Me.POZ = 9;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.35;
                Me.W2 = 1.65;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.2;
                Me.W2 = 1.15;
                Me.W3 = 1.65;
            }
        }
        else if (Me.option_Dia == 17.5) {
            Me.POZ = 9.5;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.35;
                Me.W2 = 1.65;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.2;
                Me.W2 = 1.15;
                Me.W3 = 1.65;
            }
        }
        else if (Me.option_Dia == 18) {
            Me.POZ = 10;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.35;
                Me.W2 = 1.65;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.2;
                Me.W2 = 1.15;
                Me.W3 = 1.65;
            }
        }
        else if (Me.option_Dia == 18.5) {
            Me.POZ = 10;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.35;
                Me.W2 = 1.9;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.2;
                Me.W2 = 1.15;
                Me.W3 = 1.9;
            }
        }
        else if (Me.option_Dia == 19) {
            Me.POZ = 10;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.45;
                Me.W2 = 2.05;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.25;
                Me.W2 = 1.2;
                Me.W3 = 2.05;
            }
        }
        else if (Me.option_Dia == 19.5) {
            Me.POZ = 10;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.45;
                Me.W2 = 2.3;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.25;
                Me.W2 = 1.2;
                Me.W3 = 2.3;
            }
        }
        else if (Me.option_Dia == 20) {
            Me.POZ = 10;
            if (Me.UI_MODE == 1) {
                Me.W1 = 2.5;
                Me.W2 = 2.5;
                Me.W3 = 0;
            }
            else {
                Me.W1 = 1.3;
                Me.W2 = 1.2;
                Me.W3 = 2.5;
            }
        }
        Me.BCE = 0;
        Me.PE1 = Me.txt_Pe1_new != null ? Me.txt_Pe1_new : Me.txt_Pe1;

        if (Me.UI_MODE == 1) {
            Me.PE2 = Me.txt_Pe2_new != null ? Me.txt_Pe2_new : Me.txt_Pe2;
            Me.PE3 = 0;
            Me.PC2 = Me.txt_PC2_mm_new != null ? Me.txt_PC2_mm_new : Me.txt_PC2_mm;
            Me.PC3 = 0;
        }

        else if (Me.UI_MODE == 3) {
            Me.PE2 = Me.txt_Pe1B_new != null ? Me.txt_Pe1B_new : Me.txt_Pe1B;
            Me.PE3 = Me.txt_Pe2_new != null ? Me.txt_Pe2_new : Me.txt_Pe2;
            Me.PC2 = Me.PC1;
            Me.PC3 = Me.txt_PC2_mm_new != null ? Me.txt_PC2_mm_new : Me.txt_PC2_mm;
        }


        return Me;
    }
    //added by shwetha
    this.GetNewEccen = function (R, x0, y0, W_1, y1) {
        var d, a, denom, K;
        x1 = W_1;
        //d = ((R ^ 2) * (y0 ^ 2)) - (2 * (R ^ 2) * y0 * y1) + ((R ^ 2) * (y1 ^ 2)) + ((x0 ^ 2) * (x1 ^ 2));
        d = ((Math.pow(R, 2)) * (Math.pow(y0, 2))) - (2 * (Math.pow(R, 2)) * y0 * y1) + ((Math.pow(R, 2)) * (Math.pow(y1, 2))) + ((Math.pow(x0, 2)) * (Math.pow(x1, 2)));
        //Test
        //d = ((this.precisionRound(R, 2)) * (this.precisionRound(y0, 2))) -
        //    (2 * (this.precisionRound(R, 2)) * y0 * y1) +
        //    ((this.precisionRound(R, 2)) * (this.precisionRound(y1, 2))) +
        //    ((this.precisionRound(x0, 2)) * (this.precisionRound(x1, 2)));
        //alert(d); 
        if (d > 0) {
            a = -(Math.pow(x0, 2)) - (Math.pow(x1, 2)) - (Math.pow(y0, 2)) + (2 * y0 * y1) - (Math.pow(y1, 2));
            b = (Math.pow(d, 0.5));
            denom = (Math.pow(y0, 2)) - (2 * y0 * y1) + (Math.pow(y1, 2));
            K = (a + (2 * b)) / denom;
        }
        else {
            K = 999999;
        }
        return K;
    }
    //added by shwetha 


    this.cbo_microns2 = function (Me) {
        if (Me.cbo_microns2 > 0) {
            //Me.txt_PC2_Step = Trim(Me.cbo_microns2.Column(1));// need to modify
            Me.txt_PC2_Step = Me.micronSteps.displaySteps;
        }
        else {
            Me.txt_PC2_Step = "";
        }
        var deltaZ;
        var temp_z2;
        var deltaR;
        var blnContinue;
        //Me = this.SagTotal_proc("dSag", false, true, Me);
        blnContinue = this.StartingDesn_IsReady(Me);
        if (blnContinue == true) {
            Me.deltaZ = Me.Option_DirectionRad == 1 ? (-Me.cbo_microns2 / 1000) : Me.Option_DirectionRad == 2 ? (Me.cbo_microns2 / 1000) : 0;

            if (Me.Option_DirectionRad > 0) {
                Me.txt_PC2_mm_new = null;
                Me.txt_dR2 = null;
                Me.txt_dR3 = null;
                Me = this.Set_Public_vars(Me);
                
                if (Me.cbo_microns > 0) {
                    Me.temp_z2 = (Me.UI_MODE == 1 ? Me.txt_Sag1 : Me.UI_MODE == 3 ? Me.txt_Sag1B : 0) + Me.txt_dSag2;
                    Me.temp_z2 = Me.temp_z2 + (Me.option_direction == 1 ? (-Me.cbo_microns / 1000) : (Me.option_direction == 2 ? (Me.cbo_microns / 1000) : 0)) + (Me.option_direction_1B == 1 ? (-Me.cbo_microns_1B / 1000) : (Me.option_direction_1B == 2 ? (Me.cbo_microns_1B / 1000) : 0));
                }
                else {
                    Me.temp_z2 = (Me.UI_MODE == 1 ? Me.txt_Sag1 : (Me.UI_MODE == 3 ? Me.txt_Sag1B : 0)) + Me.txt_dSag2;
                }
                if (Me.UI_MODE == 1) {
                    //deltaR = this.Compute_dR2(deltaZ, Me.txt_z1, temp_z2, (Me.txt_Pe2_new != null ? Me.txt_Pe2_new : Me.txt_Pe2 ), Me.txt_PC2_mm); // need to write computedr method
                    Me = this.Compute_dR2(Me, Me.deltaZ, Me.txt_z1, Me.temp_z2, (Me.txt_Pe2_new != null ? Me.txt_Pe2_new : Me.txt_Pe2), Me.txt_PC2_mm); // need to write computedr method
                    Me.deltaR = Me.Compute_dR2;
                }

                else if (Me.UI_MODE == 3) {
                    Me = this.Compute_dR2(Me, Me.deltaZ, (Me.txt_Sag1B_new != null ? Me.txt_Sag1B_new : Me.txt_Sag1B), Me.temp_z2, (Me.txt_Pe2_new != null ? Me.txt_Pe2_new : Me.txt_Pe2), Me.txt_PC2_mm);
                    Me.deltaR = Me.Compute_dR2;
                }
                if (Me.UI_MODE == 1) {
                    Me.txt_dR2 = Me.deltaR;
                    Me.txt_dR3 = 0;
                }
                else {
                    Me.txt_dR2 = 0;
                    Me.txt_dR3 = Me.deltaR;
                }
                Me.txt_PC2_mm_new = parseFloat((Me.txt_PC2_mm + Me.deltaR).toFixed(6)); //Math.round((Me.txt_PC2_mm + Me.deltaR), 6);

                Me = this.SetNewSagVals(Me);

                if (Me.UI_MODE == 1) {
                    Me.txt_Sag2_new = parseFloat(((Me.temp_z2 + (Me.Option_DirectionRad == 1 ? (-Me.cbo_microns2 / 1000) : (Me.Option_DirectionRad == 2 ? (Me.cbo_microns2 / 1000) : 0))) * 1000).toFixed(3));
                    Me.txt_z2 = Me.txt_Sag2_new / 1000;
                }
                else if (Me.UI_MODE == 3) {
                    Me.txt_Sag2_new = parseFloat(((Me.temp_z2 + (Me.Option_DirectionRad == 1 ? (-Me.cbo_microns2 / 1000) : (Me.Option_DirectionRad == 2 ? (Me.cbo_microns2 / 1000) : 0))) * 1000).toFixed(3));
                    Me.txt_z3 = Me.txt_Sag2_new / 1000;
                }
                else {
                    
                    // Me.txt_hide2.SetFocus;
                }
            }
        }
        Me = this.Reset_New_SagVals(Me);
        return Me;
    }

    this.cbo_mm2 = function (Me) {
        Me.cbo_microns2 = null;

        if (Me.cbo_mm2 >= 0) {
            var deltaR = 0;
            Me.deltaR = (Me.Option_DirectionRad == 1 ? Me.cbo_mm2 : (Me.Option_DirectionRad == 2 ? -Me.cbo_mm2 : 0))
           // Me.txt_PC2_Step = Trim(Me.cbo_mm2.Column(1))
            Me.txt_PC2_Step = Me.micronSteps.displaySteps;
            if (Me.UI_MODE == 3) {
                Me.txt_dR2 = 0;
                Me.txt_dR3 = Me.deltaR;
            }
            else {
                Me.txt_dR2 = Me.deltaR;
                Me.txt_dR3 = 0;
            }
            // End If
            //Me.txt_PC2_mm_new = parseFloat((Me.txt_PC2_mm + Me.deltaR).toFixed(6));
            Me.deltaR = parseFloat(Me.deltaR);
            Me.txt_PC2_mm_new = parseFloat((Me.txt_PC2_mm + Me.deltaR).toFixed(6));
            if ((Me.txt_z1 == null) || (Me.txt_z2) == null) {
                // ' fall through
            }
            else {
                if (Me.cbo_K_FL > 0 && Me.option_Dia > 0) {
                    Me = this.Set_Public_vars(Me);
                    Me = this.SagTotal_proc("dSag", true, true,Me);
                }
            }
        }
        else {
            Me.txt_PC2_Step = "";
            //Me.txt_hide2.SetFocus;
        }
        if (Me.cbo_K_FL > 0 && Me.option_Dia > 0) {
            Me = this.Set_Public_vars(Me);
            Me = this.SagTotal_proc("dSag", false, false,Me);
            Me = this.Reset_New_SagVals(Me);
        }
        return Me;
    }

    //added by shwetha
    this.SetNewSagVals = function (Me) {
        Me.txt_Pe2_new = Me.txt_Pe2;
        Me.txt_Ke2_new = Me.txt_Ke2;
        Me.txt_Sag0_new = Me.txt_Sag0;
        Me.txt_z0 = Me.txt_Sag0_new;
        if (Me.UI_MODE == 3) {
            ' Set values for Zone 1A'
            if (Me.option_direction == 1) {
                'flatter for Zone 1-A and steeper for Zone 1-B'
                //Me.txt_Sag1_new = Me.txt_Sag1 - IIf(IsNull(Me.cbo_microns), 0, (Me.cbo_microns / 1000));
                Me.txt_Sag1_new = Me.txt_Sag1 - (Me.cbo_microns == null ? 0 : (Me.cbo_microns / 1000));

            }
            else if (Me.option_direction == 2) {
                'steeper for Zone 1-A and flatter for Zone 1-B'
                //Me.txt_Sag1_new = Me.txt_Sag1 + IIf(IsNull(Me.cbo_microns), 0, (Me.cbo_microns / 1000));
                Me.txt_Sag1_new = Me.txt_Sag1 + (Me.cbo_microns == null ? 0 : (Me.cbo_microns / 1000));
            }
            else {
                Me.txt_Sag1_new = Me.txt_Sag1;
            }
            Me.txt_z1 = Me.txt_Sag1_new;
            Me.txt_dSag1_new = Me.txt_Sag1_new - Me.txt_Sag0_new;

            ' Set values for Zone 1B'
            if (Me.option_direction == 1) {
                'flatter for Zone 1-A and steeper for Zone 1-B'
                //Me.txt_Sag1B_new = Me.txt_Sag1B - IIf(IsNull(Me.cbo_microns), 0, (Me.cbo_microns / 1000)) + IIf(IsNull(Me.cbo_microns_1B), 0, (Me.cbo_microns_1B / 1000))
                Me.txt_Sag1B_new = Me.txt_Sag1B - (Me.cbo_microns == null ? 0 : (Me.cbo_microns / 1000)) + (Me.cbo_microns_1B == null ? 0 : (Me.cbo_microns_1B / 1000));
            }
            else if (Me.option_direction == 2) {
                'steeper for Zone 1-A and flatter for Zone 1-B'
                //Me.txt_Sag1B_new = Me.txt_Sag1B + IIf(IsNull(Me.cbo_microns), 0, (Me.cbo_microns / 1000)) - IIf(IsNull(Me.cbo_microns_1B), 0, (Me.cbo_microns_1B / 1000))
                Me.txt_Sag1B_new = Me.txt_Sag1B + (Me.cbo_microns == null ? 0 : (Me.cbo_microns / 1000)) - (Me.cbo_microns_1B == null ? 0 : (Me.cbo_microns_1B / 1000));

            }
            else {
                Me.txt_Sag1B_new = Me.txt_Sag1B;
                //End If
            }
            Me.txt_dSag1B_new = Me.txt_Sag1B_new - Me.txt_Sag1_new;
            Me.txt_z2 = Me.txt_Sag1B_new;

            Me.txt_dSag1B_new = Me.txt_Sag1B_new - Me.txt_Sag1_new;

            //Me.txt_Sag2_new = Me.txt_Sag2 + IIf(IsNull(Me.cbo_microns), 0, (Me.cbo_microns / 1000)) - IIf(IsNull(Me.cbo_microns_1B), 0, (Me.cbo_microns_1B / 1000));
            Me.txt_Sag2_new = Me.txt_Sag2 + (Me.cbo_microns == null ? 0 : (Me.cbo_microns / 1000)) - (Me.cbo_microns_1B == null ? 0 : (Me.cbo_microns_1B / 1000));

            if (Me.cbo_microns2 > 0) {
                //Me.txt_Sag2_new = Me.txt_Sag2_new + IIf(Me.Option_DirectionRad = 1, IIf(IsNull(Me.cbo_microns2), 0, -Me.cbo_microns2), IIf(Me.Option_DirectionRad = 2, IIf(IsNull(Me.cbo_microns2), 0, Me.cbo_microns2), 0))
                Me.txt_Sag2_new = Me.txt_Sag2_new + (Me.Option_DirectionRad == 1 ? (Me.cbo_microns2 == null ? 0 : -Me.cbo_microns2) : (Me.Option_DirectionRad == 2 ? (Me.cbo_microns2 == null ? 0 : Me.cbo_microns2) : 0));
            }
            Me.txt_z3 = Me.txt_Sag2_new / 1000;
            Me.txt_dSag2_new = (Me.txt_Sag2_new / 1000) - Me.txt_Sag1B_new;
        }
        else if (Me.UI_MODE == 1) {
            if (Me.option_direction == 1) {
                'flatter for Zone 1'
                Me.txt_Sag1_new = Me.txt_Sag1 - (Me.cbo_microns == null ? 0 : (Me.cbo_microns / 1000));
            }
            else if (Me.option_direction == 2) {
                'steeper for Zone 1'
                //Me.txt_Sag1_new = Me.txt_Sag1 + IIf(IsNull(Me.cbo_microns), 0, Me.cbo_microns / 1000)
                Me.txt_Sag1_new = Me.txt_Sag1 + (Me.cbo_microns == null ? 0 : (Me.cbo_microns / 1000));
            }
            else {
                Me.txt_Sag1_new = Me.txt_Sag1;
                //End If
            }
            Me.txt_z1 = Me.txt_Sag1_new;
            Me.txt_dSag1_new = parseFloat((Me.txt_Sag1_new - Me.txt_Sag0_new).toFixed(6));
            Me.txt_Sag1B_new = Me.txt_Sag1_new;


            if (Me.option_SagRad_mode == 1 && (Me.Option_DirectionRad) > 0 && (Me.cbo_microns2 > 0)) {
                Me.txt_Sag2_new = Me.txt_Sag2_new + (Me.Option_DirectionRad == 1 ? -Me.cbo_microns2 : (Me.Option_DirectionRad == 2 ? Me.cbo_microns2 : 0));
            }
            else if (Me.option_SagRad_mode == 2 && Me.Option_DirectionRad > 0 && Me.cbo_mm2 > 0) {
                //
            }
            else {
                //Me.txt_Sag2_new = Me.txt_Sag2 + IIf(Me.option_direction = 1, IIf(IsNull(Me.cbo_microns), 0, -Me.cbo_microns), IIf(Me.option_direction = 2, IIf(IsNull(Me.cbo_microns), 0, Me.cbo_microns), 0))
                Me.txt_Sag2_new = Me.txt_Sag2 + (Me.option_direction == 1 ? (Me.cbo_microns == null ? 0 : -Me.cbo_microns) : (Me.option_direction == 2 ? (Me.cbo_microns == null ? 0 : Me.cbo_microns) : 0));
            }

            Me.txt_dSag1B_new = Me.txt_Sag1B_new - Me.txt_Sag1_new;
            Me.txt_z2 = Me.txt_Sag2_new / 1000;

            Me.txt_dSag2_new = (Me.txt_Sag2_new / 1000) - Me.txt_Sag1_new;
        }
        return Me;
    }

    this.Reset_New_SagVals = function (Me) {
        Me.txt_Sag0_new = Me.txt_z0;
        Me.txt_Sag1_new = Me.txt_z1;
        Me.txt_dSag1_new = parseFloat((Me.txt_z1 - Me.txt_z0).toFixed(6));
        Me.txt_Sag2_new = (Me.UI_MODE == 1 ? Me.txt_z2 : Me.txt_z3) * 1000;
        Me.txt_dSag2_new = (Me.txt_Sag2_new / 1000) - (Me.UI_MODE == 1 ? Me.txt_Sag1_new : Me.txt_z2);

        Me.txt_dSag = Math.round(Me.txt_Sag2_new, 0) - Math.round(Me.txt_Sag2, 0);

        if (Me.txt_dSag > 0) {
            if (Me.txt_dSag >= 1000) {
                Me.lbl_PlusSign.Left = 1440 * 9.333;
            }

            else {
                Me.lbl_PlusSign.Left = 1440 * 9.5;
                // End If

            }
            //Me.lbl_PlusSign.Visible = true;
        }
        else {
            //Me.lbl_PlusSign.Visible = false;
        }
        return Me;
    }

    this.Compute_dR2 = function (Me, deltaZ, thisZ1, thisZ2, thisPE2, this_PC2) {
        var this_P;
        var this_dR;

        var Zminor1;
        var Zminor2;

        var Rminor1;
        var Rminor2;

        var A_00;
        var A_01;
        var A_10;
        var A_11;

        var Ainv_00;
        var Ainv_01;
        var Ainv_10;
        var Ainv_11;

        var B_0;
        var B_1;

        var c;
        var d;
        var t;
        var a0;
        var A1;
        var denom;
        var user_response;

        Me.z1 = thisZ1;
        //Me.Std_Z1 = thisZ1; // added by shwetha to test
        Me.Z2 = thisZ2;
        //Me.Std_Z2 = thisZ2; // added by shwetha to test
        if (Me.UI_MODE == 1) {
            Me.dZ1 = Me.z1 - Me.Std_Z1;
        }
        else {
            Me.dZ1 = Me.z1 - Me.Std_Z2;
        }

        if (Me.UI_MODE == 1) {
            Me.Zminor2 = Me.Std_Z2 + deltaZ + Me.dZ1;
        }
        else {
            Me.Zminor2 = Me.Std_Z3 + deltaZ + Me.dZ1;
        }
        //    '  Zone 2

        if (Me.PE2 == 1) {
            Me.PE2 = 0.9999;
        }
        else if (Me.PE2 == -1) {
            Me.PE2 = -0.9999;
        }

        if (Me.UI_MODE == 1) {
            Me.this_P = -(Math.pow(Me.PE2, 2)) + 1;
        }
        else {
            Me.this_P = -(Math.pow(Me.PE3, 2)) + 1;
        }

        Me.A_00 = Me.z1;
        Me.A_01 = 1;
        Me.A_10 = Me.Zminor2;
        Me.A_11 = 1;

        if (Me.UI_MODE == 1) {
            //Me.B_0 = -(((Me.POZ / 2) + Me.W1) * ((Me.POZ / 2) + Me.W1)) - (Me.this_P * ((Me.z1) ^ 2));
            Me.B_0 = -(((Me.POZ / 2) + Me.W1) * ((Me.POZ / 2) + Me.W1)) - (Me.this_P * (Math.pow(Me.z1, 2)));
            Me.B_1 = -(((Me.POZ / 2) + Me.W1 + Me.W2) * ((Me.POZ / 2) + Me.W1 + Me.W2)) - (Me.this_P * (Math.pow(Me.Zminor2, 2)));
        }
        else {
            Me.B_0 = -(((Me.POZ / 2) + Me.W1 + Me.W2) * ((Me.POZ / 2) + Me.W1 + Me.W2)) - (Me.this_P * (Math.pow(Me.z1, 2)));
            Me.B_1 = -(((Me.POZ / 2) + Me.W1 + Me.W2 + Me.W3) * ((Me.POZ / 2) + Me.W1 + Me.W2 + Me.W3)) - (Me.this_P * (Math.pow(Me.Zminor2, 2)));
        }
        Me.denom = (Me.A_00 * Me.A_11) - (Me.A_01 * Me.A_10);
        if (Me.denom == 0) {
            return Me;
        }
        Me.Ainv_00 = Me.A_11 / Me.denom;
        Me.Ainv_01 = - Me.A_01 / Me.denom;
        Me.Ainv_10 = - Me.A_10 / Me.denom;
        Me.Ainv_11 = Me.A_00 / Me.denom;

        Me.c = (Me.Ainv_00 * Me.B_0) + (Me.Ainv_01 * Me.B_1);
        Me.d = (Me.Ainv_10 * Me.B_0) + (Me.Ainv_11 * Me.B_1);

        Me.t = /*(Me.c ^ 2)*/ (Math.pow(Me.c, 2)) - 4 * Me.this_P * Me.d;

        if (Me.t > 0) {
            //Me.a0 = (-c - Sqr(Me.t)) / (2 * Me.this_P); 
            Me.a0 = (-Me.c - Math.sqrt(Me.t)) / (2 * Me.this_P);
            Me.A1 = (-Me.c + Math.sqrt(Me.t)) / (2 * Me.this_P);
            if (Me.a0 <= Me.A1) {
                Me.Rminor2 = -(Me.c + (2 * Me.this_P * Me.a0)) / 2;
            }
            else {
                Me.Rminor2 = -(Me.c + (2 * Me.this_P * Me.A1)) / 2;
            }
            Me.dR2 = Me.Rminor2 - this_PC2;
        }
        //' 13 is the default PC2 for all versions
        else {
           // return Me;
        }
        // Me.Compute_dR2 = Math.round(Me.dR2, 6);
        Me.Compute_dR2 = Me.dR2? parseFloat((Me.dR2).toFixed(6)):0; //Math.round(Me.dR2*1000000/1000000);
        return Me;
    }


});



